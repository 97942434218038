import React, { Component } from 'react';
import { Col, Row, ListGroup, Container } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import _, { first, round } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChessKing, faGem, faChessBishop, faChessKnight, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { Pie, Radar, Doughnut, Bar } from 'react-chartjs-2';

interface State {
  isUserStats: boolean;
  topUsersStats: any;
}
interface Props {
  rootStore?: any;
}

@inject('rootStore')
@observer
class RankingPage extends Component<Props, State> {
  private rootStore: any;
  private authStore: any;
  private chatStore: any;
  private Lead: any;
  private ChartBar: any;
  public topUsers: any = [];
  constructor(props: any) {
    super(props);
    this.state = {
      isUserStats: false,
      topUsersStats: [],
    };
    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
  }

  componentDidMount = () => {
    this.setState({
      isUserStats: this.chatStore.isUsersStats,
    });
  };

  renderTopIcons = (data: any) => {
    if (data[0] > 3) {
      return <FontAwesomeIcon icon={faTrophy} style={{ color: '#FAD91A' }} />;
    }
    if (data[0] <= 3 && data[0] > 6) {
      return <FontAwesomeIcon icon={faTrophy} style={{ color: '#E1E1DF' }} />;
    }
    if (data[0] <= 6) {
      return <FontAwesomeIcon icon={faTrophy} style={{ color: '#E79319' }} />;
    }
  };

  userThreeSplit = () => {
    const threeSplit = [];
    if (this.topUsers !== []) {
      const users = this.topUsers;
      threeSplit.push(users[0], users[1], users[2]);
    }
  };

  renderRankLabels = (data: any) => {
    if (data === 'leadership') {
      return 'Leadership';
    }
    if (data === 'influence') {
      return 'Influential';
    }
    if (data === 'social') {
      return 'Social';
    }
    if (data === 'collaboration') {
      return 'Collaboration';
    }
    if (data === 'communication') {
      return 'Communication';
    }
    if (data === 'activeness') {
      return 'Active';
    }
    if (data === 'approachability') {
      return 'Approachable';
    }
  };

  renderTopUserCategories = (data: any) => {
    const allArray: any = [];
    _.each(data, (value, i) => {
      if (data[0].leadershipRank) {
        allArray.push(
          <div className="" style={{ height: '100%', width: '100%' }}>
            <Col className="border-bottom">
              <Row className="" style={{ color: '#F9FCFC', width: '100%', height: '50px' }}>
                <Col style={{ marginLeft: -40, fontSize: '25px' }}>
                  {value.leadershipRank}
                  &nbsp;
                  <img
                    className="rounded-circle"
                    src={value.userInfo.UserThumbnailURL}
                    style={{ height: '45px', width: '45px', margin: 'auto' }}
                  />
                </Col>
                <Col className="text-align-center">{value.userInfo.FirstName + ' ' + value.userInfo.LastName}</Col>
                <Col>{value.userInfo.SchoolName}</Col>
                <Col>{value.userInfo.MajorName}</Col>
                <Col style={{ marginRight: -80 }}>
                  {value.leadershipScore}
                  {this.renderTopIcons(data)}
                </Col>
              </Row>
            </Col>
          </div>,
        );
      }
      if (data[0].communicationRank) {
        allArray.push(
          <div className="" style={{ height: '100%', width: '100%' }}>
            <Col className="border-bottom">
              <Row className="" style={{ color: '#F9FCFC', width: '100%', height: '50px' }}>
                <Col style={{ marginLeft: -40, fontSize: '25px' }}>
                  {value.communicationRank}
                  &nbsp;
                  <img
                    className="rounded-circle"
                    src={value.userInfo.UserThumbnailURL}
                    style={{ height: '45px', width: '45px', margin: 'auto' }}
                  />
                </Col>
                <Col className="text-align-center">{value.userInfo.FirstName + ' ' + value.userInfo.LastName}</Col>
                <Col>{value.userInfo.SchoolName}</Col>
                <Col>{value.userInfo.MajorName}</Col>
                <Col style={{ marginRight: -80 }}>
                  {value.communicationScore}
                  {this.renderTopIcons(data)}
                </Col>
              </Row>
            </Col>
          </div>,
        );
      }
      if (data[0].collaborationRank) {
        allArray.push(
          <div className="" style={{ height: '100%', width: '100%' }}>
            <Col className="border-bottom">
              <Row className="" style={{ color: '#F9FCFC', width: '100%', height: '50px' }}>
                <Col style={{ marginLeft: -40, fontSize: '25px' }}>
                  {value.collaborationRank}
                  &nbsp;
                  <img
                    className="rounded-circle"
                    src={value.userInfo.UserThumbnailURL}
                    style={{ height: '45px', width: '45px', margin: 'auto' }}
                  />
                </Col>
                <Col className="text-align-center">{value.userInfo.FirstName + ' ' + value.userInfo.LastName}</Col>
                <Col>{value.userInfo.SchoolName}</Col>
                <Col>{value.userInfo.MajorName}</Col>
                <Col style={{ marginRight: -80 }}>
                  {value.collaborationScore}
                  {this.renderTopIcons(data)}
                </Col>
              </Row>
            </Col>
          </div>,
        );
      }
      if (data[0].influenceRank) {
        allArray.push(
          <div className="" style={{ height: '100%', width: '100%' }}>
            <Col className="border-bottom">
              <Row className="" style={{ color: '#F9FCFC', width: '100%', height: '50px' }}>
                <Col style={{ marginLeft: -40, fontSize: '25px' }}>
                  {value.influenceRank}
                  &nbsp;
                  <img
                    className="rounded-circle"
                    src={value.userInfo.UserThumbnailURL}
                    style={{ height: '45px', width: '45px', margin: 'auto' }}
                  />
                </Col>
                <Col className="text-align-center">{value.userInfo.FirstName + ' ' + value.userInfo.LastName}</Col>
                <Col>{value.userInfo.SchoolName}</Col>
                <Col>{value.userInfo.MajorName}</Col>
                <Col style={{ marginRight: -80 }}>
                  {value.influenceScore}
                  {this.renderTopIcons(data)}
                </Col>
              </Row>
            </Col>
          </div>,
        );
      }
      if (data[0].socialRank) {
        allArray.push(
          <div className="" style={{ height: '100%', width: '100%' }}>
            <Col className="border-bottom">
              <Row className="" style={{ color: '#F9FCFC', width: '100%', height: '50px' }}>
                <Col style={{ marginLeft: -40, fontSize: '25px' }}>
                  {value.socialRank}
                  &nbsp;
                  <img
                    className="rounded-circle"
                    src={value.userInfo.UserThumbnailURL}
                    style={{ height: '45px', width: '45px', margin: 'auto' }}
                  />
                </Col>
                <Col className="text-align-center">{value.userInfo.FirstName + ' ' + value.userInfo.LastName}</Col>
                <Col>{value.userInfo.SchoolName}</Col>
                <Col>{value.userInfo.MajorName}</Col>
                <Col style={{ marginRight: -80 }}>
                  {value.socialScore}
                  {this.renderTopIcons(data)}
                </Col>
              </Row>
            </Col>
          </div>,
        );
      }
      if (data[0].approachRank) {
        allArray.push(
          <div className="" style={{ height: '100%', width: '100%' }}>
            <Col className="border-bottom">
              <Row className="" style={{ color: '#F9FCFC', width: '100%', height: '50px' }}>
                <Col style={{ marginLeft: -40, fontSize: '25px' }}>
                  {value.approachRank}
                  &nbsp;
                  <img
                    className="rounded-circle"
                    src={value.userInfo.UserThumbnailURL}
                    style={{ height: '45px', width: '45px', margin: 'auto' }}
                  />
                </Col>
                <Col className="text-align-center">{value.userInfo.FirstName + ' ' + value.userInfo.LastName}</Col>
                <Col>{value.userInfo.SchoolName}</Col>
                <Col>{value.userInfo.MajorName}</Col>
                <Col style={{ marginRight: -80 }}>
                  {value.approachScore}
                  {this.renderTopIcons(data)}
                </Col>
              </Row>
            </Col>
          </div>,
        );
      }
      if (data[0].activeRank) {
        allArray.push(
          <div className="" style={{ height: '100%', width: '100%' }}>
            <Col className="border-bottom">
              <Row className="" style={{ color: '#F9FCFC', width: '100%', height: '50px', borderColor: '#515458' }}>
                <Col style={{ marginLeft: -40, fontSize: '25px' }}>
                  {value.activeRank}
                  &nbsp;
                  <img
                    className="rounded-circle"
                    src={value.userInfo.UserThumbnailURL}
                    style={{ height: '45px', width: '45px', margin: 'auto' }}
                  />
                </Col>
                <Col className="text-align-center">{value.userInfo.FirstName + ' ' + value.userInfo.LastName}</Col>
                <Col>{value.userInfo.SchoolName}</Col>
                <Col>{value.userInfo.MajorName}</Col>
                <Col style={{ marginRight: -80 }}>
                  {value.activeScore}
                  {this.renderTopIcons(data)}
                </Col>
              </Row>
            </Col>
          </div>,
        );
      }
    });
    return allArray;
  };

  renderTopThreeEachRank = (data: any) => {
    let firstPlace: any = [];
    let secondPlace: any = [];
    let thirdPlace: any = [];
    let Score1: any = [];
    let Score2: any = [];
    let Score3: any = [];
    {
      Object.entries(data).map(([key, value]: [any, any]) => {
        if (key === '0') {
          {
            Object.entries(value).map(([quay, val]: [any, any]) => {
              firstPlace = data[0].userInfo;
              if (quay.endsWith('Score')) {
                Score1 = [quay, val];
              }
            });
          }
        }
        if (key === '1') {
          {
            Object.entries(value).map(([quay, val]: [any, any]) => {
              secondPlace = data[1].userInfo;
              if (quay.endsWith('Score')) {
                Score2 = [quay, val];
              }
            });
          }
        }
        if (key === '2') {
          {
            Object.entries(value).map(([quay, val]: [any, any]) => {
              thirdPlace = data[2].userInfo;
              if (quay.endsWith('Score')) {
                Score3 = [quay, val];
              }
            });
          }
        }
      });
    }
    return (
      <div className="justify-content-around py-2" style={{ marginTop: 0, textAlign: 'center', padding: 0 }}>
        <div className="row col-12 justify-content-around">
          <div className="col-4" style={{ padding: '30px', height: '100%', width: '100%' }}>
            <div className="card">
              <img height="250" src={secondPlace.UserThumbnailURL} className="card-img-top" />
              <div className="card-body">
                <h5 className="card-title" style={{ fontSize: '1.2em' }}>
                  2nd
                </h5>
                <p className="card-text" style={{ fontSize: '1em' }}>
                  {secondPlace.FirstName + ' ' + secondPlace.LastName}
                </p>
                <p className="card-text">
                  {secondPlace.SchoolName}
                  <br />
                  {secondPlace.MajorName}
                </p>
                <br />
                <p>{Score2[1]}</p>
                {/* <a href="" className="btn btn-primary">Go To Userprofile</a> */}
              </div>
            </div>
          </div>
          <div className="col-4" style={{ padding: '12px', height: '100%', width: '100%' }}>
            <div className="card">
              <img height="250" src={firstPlace.UserThumbnailURL} className="card-img-top" />
              <div className="card-body">
                <h5 className="card-title" style={{ fontSize: '1.2em' }}>
                  1st
                </h5>
                <p className="card-text" style={{ fontSize: '1em' }}>
                  {firstPlace.FirstName + ' ' + firstPlace.LastName}
                </p>
                <p className="card-text">
                  {firstPlace.SchoolName}
                  <br />
                  {firstPlace.MajorName}
                </p>
                <br />
                <p>{Score1[1]}</p>
                {/* <a className="btn btn-primary">Go To Userprofile</a> */}
              </div>
            </div>
          </div>
          <div className="col-4" style={{ padding: '30px', height: '100%', width: '100%' }}>
            <div className="card">
              <img height="250" src={thirdPlace.UserThumbnailURL} className="card-img-top" />
              <div className="card-body">
                <h5 className="card-title" style={{ fontSize: '1.2em' }}>
                  3rd
                </h5>
                <p className="card-text" style={{ fontSize: '1em' }}>
                  {thirdPlace.FirstName + ' ' + thirdPlace.LastName}
                </p>
                <p className="card-text">
                  {thirdPlace.SchoolName}
                  <br />
                  {thirdPlace.MajorName}
                </p>
                <br />
                <p>{Score3[1]}</p>
                {/* <a href="/userProfile/:uid" className="btn btn-primary">Go To Userprofile</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  chatStatsValues = (value: any) => {
    const allArray: any = [];
    if (value !== null) {
      _.each(value, (data, i) => {
        if (value[0].leadershipRank) {
          allArray.push(data.leadershipScore);
        }
        if (value[0].communicationRank) {
          allArray.push(data.communicationScore);
        }
        if (value[0].collaborationRank) {
          allArray.push(data.collaborationScore);
        }
        if (value[0].influenceRank) {
          allArray.push(data.influenceScore);
        }
        if (value[0].socialRank) {
          allArray.push(data.socialScore);
        }
        if (value[0].approachRank) {
          allArray.push(data.approachScore);
        }
        if (value[0].activeRank) {
          allArray.push(data.activeScore);
        }
      });
    }
    return allArray;
  };

  userNameLabels = (value: any) => {
    let valueArray: any = [];
    if (value !== null) {
      valueArray = [];
      _.each(value, (data, i) => {
        if (data) {
          const FirstName = data.userInfo.FirstName;
          const LastName = data.userInfo.LastName;
          valueArray.push(FirstName + ' ' + LastName);
        }
      });
    }
    return valueArray;
  };

  getColor = (value: any) => {
    const colorArray: any = [];
    const userColor = this.userNameLabels(value);
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';
    _.each(userColor, (value) => {
      for (let i = 0; i < 6; i++) {
        color = color + letters[Math.floor(Math.random() * 16)];
      }
      colorArray.push(color);
      color = '#';
    });
    return colorArray;
  };

  getDemographyLabels = (value: any) => {
    if (value[0].activeScore) {
      return 'Activeness';
    }
    if (value[0].approachScore) {
      return 'Approachable';
    }
    if (value[0].collaborationScore) {
      return 'Collaboration';
    }
    if (value[0].communicationScore) {
      return 'Communication';
    }
    if (value[0].influenceScore) {
      return 'Influential';
    }
    if (value[0].socialScore) {
      return 'Social';
    }
    if (value[0].leadershipScore) {
      return 'Leadership';
    }
  };

  renderBarChart = (value: any) => {
    const userNames: any = this.userNameLabels(value);
    const userValues: any = this.chatStatsValues(value);
    const backgroundColor: any = this.getColor(value);
    const label: any = this.getDemographyLabels(value);
    if (label) {
      this.ChartBar = {
        labels: userNames,
        datasets: [
          {
            data: userValues,
            backgroundColor: backgroundColor,
            hoverBorderColor: '#000',
            borderAlign: 'inner',
          },
        ],
        options: {
          responsive: true,
          aspectRatio: 2.0,
        },
      };
    }

    return (
      <Bar
        data={this.ChartBar}
        options={{
          legend: false,
          title: {
            display: true,
            text: label,
            fontSize: 25,
          },
        }}
      />
    );
  };

  topThreeRender = (data: any) => {
    let firstPlace: any = [];
    let secondPlace: any = [];
    let thirdPlace: any = [];
    let Score1: any = [];
    let Score2: any = [];
    let Score3: any = [];
    if (data.total[0]) {
      firstPlace = data.total[0].userInfo;
      Score1 = data.total[0].totalscore;
    }
    if (data.total[1]) {
      secondPlace = data.total[1].userInfo;
      Score2 = data.total[1].totalscore;
    }
    if (data.total[2]) {
      thirdPlace = data.total[2].userInfo;
      Score3 = data.total[2].totalscore;
    }

    return (
      <div className="justify-content-around py-4" style={{ marginTop: 0, textAlign: 'center' }}>
        <p
          className="base-title"
          style={{ fontSize: '4em', fontFamily: 'Gill-Sans', color: '#cccccc', textShadow: '2px' }}
        >
          Top 3 Students
          <FontAwesomeIcon icon={faTrophy} style={{ color: '#FAD91A' }} />
        </p>
        <div className="reg-screen row col-12 justify-content-around">
          <div className="col-4" style={{ padding: '100px 12px 12px 12px' }}>
            <div className="card">
              <img src={secondPlace.UserThumbnailURL} className="card-img-top" />
              <div className="card-body">
                <h5 className="card-title" style={{ fontSize: '2em' }}>
                  2nd
                </h5>
                <p className="card-text" style={{ fontSize: '1.3em' }}>
                  {secondPlace.FirstName + ' ' + secondPlace.LastName}
                </p>
                <p className="card-text">
                  {secondPlace.SchoolName}
                  <br />
                  {secondPlace.MajorName}
                  <br />
                  {Score2}
                </p>
                {/* <a href="" className="btn btn-primary">Go To Userprofile</a> */}
              </div>
            </div>
          </div>
          <div className="col-4" style={{ padding: '12px' }}>
            <div className="card">
              <img src={firstPlace.UserThumbnailURL} className="card-img-top" />
              <div className="card-body">
                <h5 className="card-title" style={{ fontSize: '2em' }}>
                  1st
                </h5>
                <p className="card-text" style={{ fontSize: '1.3em' }}>
                  {firstPlace.FirstName + ' ' + firstPlace.LastName}
                </p>
                <p className="card-text">
                  {firstPlace.SchoolName}
                  <br />
                  {firstPlace.MajorName}
                  <br />
                  {Score1}{' '}
                </p>
                {/* <a className="btn btn-primary">Go To Userprofile</a> */}
              </div>
            </div>
          </div>
          <div className="col-4" style={{ padding: '100px 12px 12px 12px' }}>
            <div className="card">
              <img src={thirdPlace.UserThumbnailURL} className="card-img-top" />
              <div className="card-body">
                <h5 className="card-title" style={{ fontSize: '2em' }}>
                  3rd
                </h5>
                <p className="card-text" style={{ fontSize: '1.3em' }}>
                  {thirdPlace.FirstName + ' ' + thirdPlace.LastName}
                </p>
                <p className="card-text">
                  {thirdPlace.SchoolName}
                  <br />
                  {thirdPlace.MajorName}
                  <br />
                  {Score3}
                </p>
                {/* <a href="/userProfile/:uid" className="btn btn-primary">Go To Userprofile</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderRankChart = (data: any) => {
    const rankList: any = [];
    const totalRank = data.total;
    _.each(totalRank, (value, i) => {
      rankList.push(
        <div className="">
          <Col className="border-bottom">
            <Row className="" style={{ color: '#F5FBFB', width: '800px', height: '50px', borderColor: '#515458' }}>
              <Col className="text-align-center">{value.userInfo.FirstName + ' ' + value.userInfo.LastName}</Col>
              <Col>{value.userInfo.SchoolName}</Col>
              <Col>{value.userInfo.MajorName}</Col>
              <Col style={{ marginRight: -70 }}>
                {value.totalscore}
                {this.renderTopIcons(data)}
              </Col>
            </Row>
          </Col>
        </div>,
      );
    });

    return rankList;
  };

  render() {
    const usersStats = this.chatStore.top15Users;
    return (
      <div
        className="container-fluid"
        style={{ overflowY: 'scroll', height: '100%', width: '100%', position: 'fixed' }}
      >
        {/* <Col>
          {this.topThreeRender(usersStats)}
          </Col>
          <div className="text-align-center" style={{height:'100%', backgroundColor:'#EDF2F9'}}>
          <Col className="col-12 col-md-12">
              <Row className="col-12 font-weight-bold" style={{fontFamily:'Gill-Sans'}}>
                  <h1>Top Students</h1>
              </Row>
              <Row className="font-weight-bold border-bottom">
                  <Col>Name</Col>
                  <Col>School</Col>
                  <Col>Major</Col>
                  <Col>Points</Col>
              </Row>
              <Row className="table-striped col-12">
                  {this.renderRankChart(usersStats)}
              </Row>
              </Col>
            </div> */}
        <div style={{}}>
          <p
            style={{
              fontSize: '6em',
              margin: 'auto',
              textAlign: 'center',
              fontWeight: 'bold',
              color: '#F9FCFC',
              fontFamily: 'Gilly-Sans',
            }}
          >
            Soft Skill/Trait Ranking
          </p>
          {Object.entries(usersStats).map(([key, value]: [any, any]) => {
            if (key && key != 'total') {
              return (
                <div className="justify-content-center" style={{ textAlign: 'center' }}>
                  <p style={{ fontSize: '3.5em', fontWeight: 'bold', color: '#F9FCFC' }}>
                    {this.renderRankLabels(key)}
                  </p>
                  <div>{this.renderTopThreeEachRank(value)}</div>
                  <Row
                    className="font-weight-bold border-bottom"
                    style={{ color: '#F9FCFC', fontWeight: 'bold', fontSize: '1.5em' }}
                  >
                    <Col>Rank</Col>
                    <Col>Name</Col>
                    <Col>School</Col>
                    <Col>Major</Col>
                    <Col>Points</Col>
                  </Row>
                  <Row className="col-12" style={{ textAlign: 'center' }}>
                    {this.renderTopUserCategories(value)}
                  </Row>
                </div>
              );
            }
          })}
        </div>
        {/* <div>
                  <Col>
              <Row>
                  <Col className="col-12 col-md-12">
                      <Row className="text-align-center">
                          <p style={{fontSize:'50px', fontFamily:'Gilly-Sans', fontWeight:'bold', margin:'auto', color:'#cccccc', textDecoration:'underline'}}> Top Student's Soft Skills Breakdown</p>
                          
                      </Row>
                  {Object.entries(usersStats).map(([key, value]:[any,any]) => {
                      if(key && key != 'total'){
                      return <Row className="col-12 col-lg-12">{this.renderBarChart(value)}</Row>
                      }
                  })
                  }
                  
                  </Col>
              </Row>
          </Col>
          </div> */}
      </div>
    );
  }
}
export default RankingPage;
