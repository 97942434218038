import React from 'react';
import { Col, Row, Image, FormControl } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';

interface Props {
  followerList?: any;
}

const ChatFollowerList: React.FC<Props> = ({ followerList }: Props) => {
  return (
    <div className="col-12">
      <Col>
        <FormControl
          style={{ backgroundColor: '#181A1B', borderColor: '#3C4144', color: '#b5afa6' }}
          placeholder="Search..."
          aria-label="ChatMemberList Search Bar"
        />
        {followerList.map((follower: any) => {
          const { uid, firstName, lastName, userThumbnailURL, schoolName } = follower;
          return (
            <div style={{ borderBottom: '1px solid #5D7F9D50' }} key={uid}>
              <Row className="py-3 col-12 d-flex align-items-center justify-content-between">
                <Col xs="2" className="pr-0 ">
                  <Image src={userThumbnailURL} className="mr-1 bs-1" width="60" height="60" roundedCircle />
                </Col>
                <Col xs="9" className="d-flex justify-content-center flex-column">
                  <p style={{ fontWeight: 'bold', marginBottom: '.25rem' }}>
                    {firstName} {lastName}
                  </p>
                  <p style={{ marginBottom: '.25rem' }}>{schoolName}</p>
                </Col>
              </Row>
            </div>
          );
        })}
      </Col>
    </div>
  );
};

export default inject(({ rootStore }) => ({ followerList: rootStore.sidebarStore.followerList }))(
  observer(ChatFollowerList),
);
