// eslint-disable-next-line no-use-before-define
import React, { Component, Suspense, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Image, Col, Row, Tab, Nav, Button, ListGroup, Collapse, Container, ListGroupItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import {
  faEdit,
  faCopyright,
  faCircle,
  faPlusCircle,
  faDotCircle,
  faGem,
  faChevronCircleLeft,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import AccountEdit from './AccountEdit';
import SchoolPage from './SchoolPage';
import ChatRoomList from '../chatList/ChatRoomList';
import { PRODUCTION_BASELOGO_URL, GROUPTCHAT_THUMBNAIL_LOGO_URL, WEBSOCKET_COMMAND_TYPE } from '../../utils/Constants';
import DMForm from './DMForm';
import InvitationCodesList from '../auth/InvitationCode';

interface Props {
  rootStore?: any;
  schoolList?: any;
  majorList?: any;
  openChatPage?: any;
  match: any;
}
interface State {
  user: any;
  username: string;
  email: string;
  showModal: boolean;
  showModalSchool: boolean;
  showModalinvite: boolean;
  stateMajor: any;
  stateSchool: any;
  isData: boolean;
  schoolThumbnailURL: string;
}

const primaryColor = '#5D7F9D';

@inject('rootStore')
@observer
class UserProfile extends Component<Props, State> {
  private rootStore: any;

  private authStore: any;

  private chatStore: any;

  schoolList: any;

  majorList: any;

  private channels: any = {};

  user: any = [];

  thumbnailURL = '';

  constructor(props: any) {
    super(props);
    this.state = {
      user: this.user,
      username: `${this.user.firstName} ${this.user.lastName}`,
      email: this.user.email ? this.user.email : '',
      showModal: false,
      showModalSchool: false,
      showModalinvite: false,
      stateMajor: this.user.majorName,
      stateSchool: this.user.schoolName,
      isData: false,
      schoolThumbnailURL: this.user.schoolThumbnailURL,
    };
    const { rootStore } = this.props;
    this.rootStore = rootStore;
    const { chatStore } = this.rootStore;
    this.chatStore = chatStore;
    const { authStore } = this.rootStore;
    this.authStore = authStore;
    this.schoolList = this.authStore.getSchoolList();
    this.majorList = this.authStore.getMajorList();
  }

  componentDidMount() {
    this.setState({
      isData: true,
      user: this.user,
      username: `${this.user.firstName} ${this.user.lastName}`,
      email: this.user.email,
    });
  }

  showMajor = () => {
    const { stateMajor } = this.state;
    if (stateMajor) {
      return stateMajor;
    }
    return this.user.majorName;
  };

  showSchool = () => {
    const { stateSchool } = this.state;
    if (stateSchool) {
      return stateSchool;
    }
    return this.user.schoolName;
  };

  loadUserProfileData = () => {
    if (this.authStore.webSocket && this.authStore.webSocket.readyState === 1) {
      const { isData } = this.state;
      if (isData) {
        this.authStore.getTotalUsers();
        if (this.user.uid === this.authStore.selfUser.uid) {
          this.authStore.getInvitationCodes(this.authStore.selfUser.uid);
          this.chatStore.getUserModalData(this.authStore.selfUser.uid);
        }
        if (this.user.uid !== this.authStore.selfUser.uid) {
          this.chatStore.getUserProfileData(this.chatStore.userModalData.uid);
        }
        this.setState({
          isData: false,
        });
      }
    }
  };

  showPositionPercentage(position: number) {
    const totalUsers = this.authStore.totalUsers;
    if (totalUsers < 1) {
      return '';
    }

    const percent = Math.round((position / totalUsers) * 100);
    if (percent === 0) {
      return '(TOP 1%)';
    }
    if (percent <= 35) {
      return `(TOP ${percent}%)`;
    }
    if (percent <= 50) {
      return '(Above Average)';
    }
  }

  private handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.chatStore.handleRenderProfile();
    }
  };

  private renderPersonalityRanking = () => {
    const {
      uid,
      firstName,
      lastName,
      userThumbnailURL,
      schoolName,
      schoolThumbnailURL,
      majorName,
      score,
      ranking,
    } = this.user;
    const userSocialRanking = this.chatStore.userInfo.ranking;
    console.log('userSocialRanking', userSocialRanking);
    if (!_.isEmpty(userSocialRanking)) {
      return (
        <div className="col-12 bgGray-2 mb-3 base-card">
          <div className="username colorGray-1 text-center">
            <h1>Soft Skill Ranking</h1>
            <h6 className="pb-3">Ranking updated weekly</h6>
            <Row>
              <Col>
                <div className="d-flex justify-content-between row">
                  <div className="col-md-2 col-sm-4 col-6 mb-4 text-center base-diamond">
                    <h6>Leadership</h6>
                    <FontAwesomeIcon icon={faGem} style={{ fontSize: '25', color: '#5A94F2' }} />
                    <span className="d-flex flex-column">
                      <p className="mb-0">{userSocialRanking.leadership}</p>
                      <h6>{this.showPositionPercentage(userSocialRanking.leadership)}</h6>
                    </span>
                  </div>
                  <div className="col-md-2 col-sm-4 col-6 mb-4 text-center base-diamond">
                    <h6>Influential</h6>
                    <FontAwesomeIcon icon={faGem} style={{ fontSize: '25', color: '#5A94F2' }} />
                    <span className="d-flex flex-column">
                      <p className="mb-0">{userSocialRanking.influential}</p>
                      <h6>{this.showPositionPercentage(userSocialRanking.influential)}</h6>
                    </span>
                  </div>
                  <div className="col-md-2 col-sm-4 col-6 mb-4 text-center base-diamond">
                    <h6>Social</h6>
                    <FontAwesomeIcon icon={faGem} style={{ fontSize: '25', color: '#5A94F2' }} />
                    <span className="d-flex flex-column">
                      <p className="mb-0">{userSocialRanking.social}</p>
                      <h6>{this.showPositionPercentage(userSocialRanking.social)}</h6>
                    </span>
                  </div>
                  <div className="col-md-2 col-sm-6 col-6 mb-4 text-center base-diamond">
                    <h6>Communication</h6>
                    <FontAwesomeIcon icon={faGem} style={{ fontSize: '25', color: '#5A94F2' }} />
                    <span className="d-flex flex-column">
                      <p className="mb-0">{userSocialRanking.communication}</p>
                      <h6>{this.showPositionPercentage(userSocialRanking.communication)}</h6>
                    </span>
                  </div>
                  <div className="col-md-2 col-sm-6 col-6 mb-4 text-center base-diamond">
                    <h6>Collaboration</h6>
                    <FontAwesomeIcon icon={faGem} style={{ fontSize: '25', color: '#5A94F2' }} />
                    <span className="d-flex flex-column">
                      <p className="mb-0">{userSocialRanking.collaboration}</p>
                      <h6>{this.showPositionPercentage(userSocialRanking.collaboration)}</h6>
                    </span>
                  </div>
                  <div className="col-md-2 col-sm-4 col-6 mb-4 text-center base-diamond">
                    <h6>Positivity</h6>
                    <FontAwesomeIcon icon={faGem} style={{ fontSize: '25', color: '#5A94F2' }} />
                    <span className="d-flex flex-column">
                      <p className="mb-0">{userSocialRanking.positivity}</p>
                      <h6>{this.showPositionPercentage(userSocialRanking.positivity)}</h6>
                    </span>
                  </div>
                  <div className="col-md-2 col-sm-4 col-6 mb-4 text-center base-diamond">
                    <h6>Responsivness</h6>
                    <FontAwesomeIcon icon={faGem} style={{ fontSize: '25', color: '#5A94F2' }} />
                    <span className="d-flex flex-column">
                      <p className="mb-0">{userSocialRanking.responsiveness}</p>
                      <h6>{this.showPositionPercentage(userSocialRanking.responsiveness)}</h6>
                    </span>
                  </div>
                  <div className="col-md-2 col-sm-4 col-6 mb-4 text-center base-diamond">
                    <h6>Confidence</h6>
                    <FontAwesomeIcon icon={faGem} style={{ fontSize: '25', color: '#5A94F2' }} />
                    <span className="d-flex flex-column">
                      <p className="mb-0">{userSocialRanking.confidence}</p>
                      <h6>{this.showPositionPercentage(userSocialRanking.confidence)}</h6>
                    </span>
                  </div>
                  <div className="col-md-2 col-sm-4 col-6 mb-4 text-center base-diamond">
                    <h6>Approachability</h6>
                    <FontAwesomeIcon icon={faGem} style={{ fontSize: '25', color: '#5A94F2' }} />
                    <span className="d-flex flex-column">
                      <p className="mb-0">{userSocialRanking.approachability}</p>
                      <h6>{this.showPositionPercentage(userSocialRanking.approachability)}</h6>
                    </span>
                  </div>
                  <div className="col-md-2 col-sm-4 col-6 mb-4 text-center base-diamond">
                    <h6>Expressiveness</h6>
                    <FontAwesomeIcon icon={faGem} style={{ fontSize: '25', color: '#5A94F2' }} />
                    <span className="d-flex flex-column">
                      <p className="mb-0">{userSocialRanking.expressive}</p>
                      <h6>{this.showPositionPercentage(userSocialRanking.expressive)}</h6>
                    </span>
                  </div>
                  <div className="col-md-2 col-sm-4 col-6 mb-4 text-center base-diamond">
                    <h6>Likability</h6>
                    <FontAwesomeIcon icon={faGem} style={{ fontSize: '25', color: '#5A94F2' }} />
                    <span className="d-flex flex-column">
                      <p className="mb-0">{userSocialRanking.likability}</p>
                      <h6>{this.showPositionPercentage(userSocialRanking.likability)}</h6>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <h6 className="pb-3">({this.authStore.totalUsers} STUDENTS)</h6>
          </div>
        </div>
      );
    }
    return null;
  };

  private renderUserInfo = () => {
    const { uid, firstName, lastName, userThumbnailURL, schoolName, majorName } = this.user;
    const { schoolThumbnailURL } = this.state;
    if (!schoolThumbnailURL) {
      this.thumbnailURL = this.chatStore.userInfo.schoolThumbnailURL
        ? this.chatStore.userInfo.schoolThumbnailURL
        : PRODUCTION_BASELOGO_URL;
    }
    return (
      <div className="bgGray-2 base-card">
        {window.matchMedia('(max-width: 768px)').matches ? (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div onClick={() => this.chatStore.handleRenderProfile()} onKeyDown={() => this.handleOnKeyDown.bind(this)}>
            <FontAwesomeIcon icon={faChevronCircleLeft} style={{ fontSize: '30', color: 'white', padding: '4' }} />
          </div>
        ) : null}
        <div style={{ color: primaryColor }} className="text-center pt-3">
          <Image
            className="mr-2 bs-1 profileImg"
            width="240"
            height="240"
            src={userThumbnailURL || PRODUCTION_BASELOGO_URL}
            roundedCircle
            style={{
              border: '1px #d7d7d7 solid',
              width: '20vw',
              height: '20vw',
              maxWidth: '240px',
              maxHeight: '240px',
            }}
          />
          <h1 className="username colorGray-1">{`${firstName} ${lastName}`}</h1>
          <div className="d-flex justify-content-center align-items-center">
            <Image
              className="mr-2 bs-1"
              width="30"
              height="30"
              style={{ width: '4%', height: '4%', maxWidth: '30px' }}
              src={schoolThumbnailURL || this.thumbnailURL}
              roundedCircle
            />
            <h2 className="schoolMajor colorGray-1 mb-0">{this.showSchool()}</h2>
          </div>
          <h2 className="schoolMajor colorGray-1 mt-1 mb-0">{this.showMajor()}</h2>
        </div>
        <div style={{ color: primaryColor }} className="col-sx-8 col-md-5 mx-auto mb-5 d-flex">
          <Button variant="link" className="col base-btn" onClick={() => this.setState({ showModalSchool: true })}>
            <FontAwesomeIcon
              icon={faGraduationCap}
              className="button-icon raised bg-white"
              style={{
                padding: '0.7rem 0.5rem',
                color: '#2E302D',
                width: '9vw',
                height: '9vw',
                maxWidth: '50px',
                maxHeight: '50px',
              }}
            />
          </Button>
          {this.rootStore.getUserUID() === uid ? (
            <>
              <Button variant="link" className="col base-btn" onClick={() => this.setState({ showModal: true })}>
                <FontAwesomeIcon
                  icon={faEdit}
                  className="button-icon raised bg-white"
                  style={{
                    padding: '0.7rem 0.5rem',
                    color: '#2E302D',
                    width: '9vw',
                    height: '9vw',
                    maxWidth: '50px',
                    maxHeight: '50px',
                  }}
                />
              </Button>
              <Button variant="link" className="col base-btn" onClick={() => this.setState({ showModalinvite: true })}>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="button-icon raised bg-white"
                  style={{
                    padding: '0.7rem 0.5rem',
                    color: '#2E302D',
                    width: '9vw',
                    height: '9vw',
                    maxWidth: '50px',
                    maxHeight: '50px',
                  }}
                />
              </Button>
            </>
          ) : (
            <Button variant="link" className="col base-btn" onClick={this.openDMModal}>
              <FontAwesomeIcon
                icon={faComments}
                className="button-icon raised pad-sm bg-white"
                style={{ padding: '0.7rem 0.5rem', color: '#2E302D' }}
              />
            </Button>
          )}
        </div>
      </div>
    );
  };

  openDMModal = () => {
    this.chatStore.createPersonalChat(this.user.uid);
    this.chatStore.setShowDMForm(true);
    this.chatStore.setDMFromUserProfile(true);
  };

  render() {
    this.chatStore.isModalTrue = false;
    this.loadUserProfileData();
    this.user = this.chatStore.userModalData;
    const { showModal } = this.state;
    const { showModalinvite } = this.state;
    if (this.user.uid !== this.authStore.selfUser.uid) {
      return (
        <Row style={{ padding: 0 }} className="bgGray-1">
          <Col className="p-10">
            {this.renderUserInfo()}
            {this.renderPersonalityRanking()}
          </Col>
          <DMForm fromUserProfile />
          {/* <SchoolPage
                        user={this.props.user}
                        schoolList={this.schoolList}
                        rootStore={this.props.rootStore}
                        majorID={this.state.majorID}
                        schoolID={this.state.schoolID}
                        showModal={this.state.showModalSchool}
                        cancelModal={() => this.setState({ showModalSchool: !this.state.showModalSchool })}
                        checkDetail={() => this.setState({ showModalSchool: !this.state.showModalSchool })}
                    /> */}
          {/* <Col className="col-12 justify-content-right">
          {this.renderChatContainer(myChatList)}
          </Col> */}
        </Row>
      );
    }

    return (
      <Suspense
        fallback={
          <div>
            <span>Loading..</span>
          </div>
        }
      >
        <Row style={{ padding: 0 }} className="bgGray-1">
          <Col className="p-10">
            {this.renderUserInfo()}
            {this.renderPersonalityRanking()}
            <AccountEdit
              showModal={showModal}
              onSave={(schoolThumbnailURL: string, schoolName: string, majorName: string) => {
                this.setState({
                  // eslint-disable-next-line react/no-access-state-in-setstate
                  ...this.state,
                  showModal: false,
                  stateSchool: schoolName,
                  stateMajor: majorName,
                  schoolThumbnailURL,
                });
              }}
              onCancel={() => {
                this.setState({
                  // eslint-disable-next-line react/no-access-state-in-setstate
                  ...this.state,
                  showModal: false,
                });
              }}
            />
            {showModalinvite && (
              <InvitationCodesList
                invitationCode={this.authStore.invitationCode}
                onCreateInvitationCode={() => {
                  this.authStore.createInvitationCode(this.authStore.selfUser.uid);
                }}
                onUpdateInvitationCodes={() => {
                  this.authStore.getInvitationCodes(this.authStore.selfUser.uid);
                }}
                invitationCodesList={this.authStore.invitationCodes}
                showModal={showModalinvite}
                onClose={() => this.setState({ showModalinvite: false })}
              />
            )}
            {/* <SchoolPage
                    user={this.state.user}
                    schoolList={this.schoolList}
                    rootStore={this.props.rootStore}
                    majorID={this.state.majorID}
                    schoolID={this.state.schoolID}
                    showModal={this.state.showModalSchool}
                    cancelModal={() => this.setState({ showModalSchool: !this.state.showModalSchool })}
                    checkDetail={() => this.setState({ showModalSchool: !this.state.showModalSchool })}
                /> */}
          </Col>
        </Row>
      </Suspense>
    );
  }
}

export default UserProfile;
