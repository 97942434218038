// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import {
  Container,
  Row,
  Button,
  FormControl,
  InputGroup,
  Card,
  ListGroup,
  Image,
  ListGroupItem,
  Modal,
  Jumbotron,
} from 'react-bootstrap';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { faAngleLeft, faEraser, faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PRODUCTION_BASELOGO_URL, WEBSOCKET_COMMAND_TYPE } from '../../utils/Constants';

interface State {
  roomTitle: string;
  isRoomTitleValid: boolean;
  isEditingDesc: boolean;
  roomDesc: string;
  openSchoolBanner: boolean;
  src: any;
  newImage: String;
}
interface Props {
  schoolList: any;
  roomData: any;
  openChatSettings: boolean;
  roomID: any;
}
interface InjectedProps extends Props {
  rootStore: any;
}

const groupTitleRegex = /^([A-Za-z0-9()_'"!\-: ]+)$/;

@inject('rootStore')
@observer
class ChatSettings extends React.Component<Props, State> {
  private rootStore: any;

  private chatStore: any;

  private authStore: any;

  roomID: string;

  constructor(props: any) {
    super(props);
    this.rootStore = this.injected.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
    this.roomID = this.chatStore.currRoomID;
    const { roomData } = this.props;
    this.state = {
      openSchoolBanner: false,
      src: '',
      roomTitle: roomData.topic,
      isRoomTitleValid: true,
      isEditingDesc: false,
      roomDesc: roomData.description || '',
      newImage: '',
    };
  }

  componentDidUpdate(prevProps: any) {
    const { roomID, roomData } = this.props;
    if (roomID !== this.roomID) {
      this.roomID = this.chatStore.currRoomID;
      this.setState({
        roomTitle: roomData.topic,
        roomDesc: this.chatStore.getCurrentChatRoom().description || '',
      });
    }
  }

  private get injected() {
    return this.props as InjectedProps;
  }

  private renderOptions(options: any) {
    const optionsMap = Object.keys(options).map((key: any) => (
      <option key={key} data-key={key} value={options[key].name}>
        {options[key].name}
      </option>
    ));
    return optionsMap;
  }

  private updateInput = (e: any) => {
    const { value } = e.target;
    const errStr = this.validateGroupTitle(value);
    this.setState({
      roomTitle: value,
      isRoomTitleValid: errStr.length === 0,
    });
  };

  private validateGroupTitle = (title: string): string => {
    if (title.length < 2 || title.length > 45) {
      return 'Characters between 2 and 45 is allowed.';
    }
    const regexCondition = !groupTitleRegex.test(title);
    if (regexCondition) {
      return 'Symbols cannot be used in group names.';
    }

    return '';
  };

  private renderChatRoomTitle = (roomInfo: any) => {
    const { isRoomTitleValid, roomTitle } = this.state;
    return (
      <Card.Body>
        <Card.Title>Title</Card.Title>
        <InputGroup>
          <FormControl
            isInvalid={!isRoomTitleValid}
            placeholder="Add Title"
            onChange={this.updateInput}
            value={roomTitle}
          />
          {!isRoomTitleValid && (
            <FormControl.Feedback type="invalid">{this.validateGroupTitle(roomTitle)}</FormControl.Feedback>
          )}
        </InputGroup>
      </Card.Body>
    );
  };

  private finishEditingTopic = () => {
    const roomID = this.chatStore.currRoomID;
    const prevTopic = this.chatStore.getCurrentChatRoom().topic;
    const { roomTitle } = this.state;
    if (prevTopic === roomTitle) return;

    const topicLength = roomTitle.length;
    if (topicLength < 3 || topicLength > 30) {
      alert('The chat room topic should be between 3-30 characters');
      return;
    }

    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.UPDATE_CHATINFO,
      data: {
        roomID,
        roomInfo: {
          topic: roomTitle,
          prev: prevTopic,
        },
        action: 'CHANGE_TOPIC',
      },
    });
  };

  private handleUpdate = () => {
    this.finishEditingTopic();
    this.finishEditingDescription();
  };

  private finishEditingDescription = () => {
    const roomID = this.chatStore.currRoomID;
    const prevDescription = this.chatStore.myChatList.get(roomID).description;
    const { roomDesc } = this.state;
    const descLength = roomDesc.length;
    if (roomDesc === prevDescription || (prevDescription === undefined && roomDesc === '')) {
      this.setState({ isEditingDesc: false });
      return;
    }
    if (descLength < 10 || descLength > 500) {
      alert('The group description should be between 10-500 characters');
      return;
    }

    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.UPDATE_CHATINFO,
      data: {
        roomID,
        roomInfo: {
          description: roomDesc,
          prev: prevDescription,
        },
        action: 'CHANGE_DESCRIPTION',
      },
    });

    this.setState({ isEditingDesc: false });
  };

  private cancelEditingDescription = () => {
    const roomID = this.chatStore.currRoomID;
    const prevDescription = this.chatStore.getCurrentChatRoom(roomID).description;
    this.chatStore.resLoading = false;

    this.setState({
      isEditingDesc: false,
      roomDesc: '',
    });
  };

  private renderChatroomDescription = () => {
    const { roomDesc } = this.state;
    if (this.chatStore.resLoading) {
      return (
        <Card.Body>
          <Card.Title>Description</Card.Title>
          <InputGroup>
            <Button onClick={() => window.location.reload()} variant="primary">
              Refresh
            </Button>
          </InputGroup>
        </Card.Body>
      );
    }
    return (
      <Card.Body>
        <Card.Title>Description</Card.Title>
        <InputGroup>
          <FormControl
            onChange={(e: any) => this.setState({ roomDesc: e.target.value })}
            value={roomDesc}
            placeholder="Add Description"
            as="textarea"
            rows={3}
          />
          <button className="chatSetting_right" onClick={() => this.cancelEditingDescription()}>
            <FontAwesomeIcon icon={faEraser} />
          </button>
        </InputGroup>
      </Card.Body>
    );
  };

  private SchoolBannerPage = () => {
    const { openChatSettings } = this.props;
    if (openChatSettings) {
      return null;
    }
    return (
      <div>
        <a className="closebtn" onClick={() => this.setState({ openSchoolBanner: false })}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </a>
        <Button>Change Logo Banner</Button>
      </div>
    );
  };

  private handleImagePicker = (event: any) => {
    const file = event.target.files[0];
    const types = ['image/png', 'image/jpeg'];
    try {
      if (types.every((type) => file.type !== type)) {
        alert('Please choose an image in the supported format (jpeg and png)!');
      } else {
        this.chatStore.chatRoomThumbnailURL = '';
        this.chatStore.changeChatImage(this.roomID, file);
        this.render();
      }
    } catch (error) {
      console.error(error);
    }
  };

  private renderImageUpload = () => {
    const { roomData } = this.props;
    if (roomData.roomType === 'groupChat') {
      return (
        <>
          <label htmlFor="upload-button" className="button base-btn">
            <FontAwesomeIcon icon={faCamera} className="camera-edit-2" style={{ color: '#2E302D' }} />
          </label>
          <input
            type="file"
            id="upload-button"
            onChange={this.handleImagePicker.bind(this)}
            style={{ display: 'none' }}
          />
        </>
      );
    }
  };

  render() {
    let schoolImg = PRODUCTION_BASELOGO_URL;
    let schoolName = '';
    let description = '';
    let chatImage = '';
    const roomID = this.chatStore.currRoomID;
    const roomInfo = this.chatStore.myChatList.get(roomID);
    const { schoolList, roomData } = this.props;
    const { isRoomTitleValid } = this.state;

    if (typeof this.chatStore.getCurrentChatRoom() !== 'undefined') {
      chatImage = roomData.thumbnailURL;
      if (this.chatStore.chatRoomThumbnailURL === '') {
        chatImage = roomData.thumbnailURL;
      } else if (roomData.thumbnailURL !== this.chatStore.chatRoomThumbnailURL) {
        chatImage = this.chatStore.chatRoomThumbnailURL;
      }

      schoolImg =
        schoolList[roomData.parent] && schoolList[roomData.parent].thumbnailURL
          ? schoolList[roomData.parent].thumbnailURL
          : PRODUCTION_BASELOGO_URL;

      if (typeof roomData.description !== 'undefined') {
        description = roomData.description;
      }
      if (typeof schoolList[roomData.parent] !== 'undefined') {
        schoolName = schoolList[roomData.parent].name;
      } else {
        schoolName = 'Select a School Base';
      }
    }

    return (
      <Container>
        <Row>
          <h1
            style={{
              fontWeight: 'bold',
              fontSizeAdjust: 5,
              fontFamily: 'Arial, Helvetica, sans-serif',
              color: '#5D7F9D',
              fontSize: 25,
              margin: 'auto',
            }}
          >
            Chat Settings
          </h1>
        </Row>
        <Row>
          <Card className="col-12">
            <div className="mx-auto mt-3 thumbnail-edit">
              <Card.Img
                variant="top"
                className="mx-auto mt-2"
                style={{ width: 160, height: 160, borderRadius: 65 }}
                src={chatImage || PRODUCTION_BASELOGO_URL}
              />
              {this.renderImageUpload()}
            </div>
            <br />
            {this.SchoolBannerPage()}
            {this.renderChatRoomTitle(roomInfo)}
            {this.renderChatroomDescription()}
            <ListGroup className="list-group-flush">
              {/* <ListGroupItem>
                <Card.Title>Base School</Card.Title>
                <InputGroup className="d-flex flex-row justify-content-around align-items-center">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      <Image
                        className="image"
                        src={schoolImg || PRODUCTION_BASELOGO_URL}
                        roundedCircle
                        width="30"
                        height="30"
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl className="col-9" as="select" aria-label="School Base" aria-describedby="basic-addon1">
                    <option value={'Select a School Base'}>{schoolName}</option>
                    {this.renderOptions(this.props.schoolList)}
                  </FormControl>
                </InputGroup>
              </ListGroupItem> */}
              <ListGroupItem className="d-flex flex-row justify-content-between align-items-center">
                {/* <Button variant="secondary">Remove School Base</Button> */}
                <Button onClick={this.handleUpdate} variant="primary" disabled={!isRoomTitleValid}>
                  Update
                </Button>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Row>
      </Container>
    );
  }
}

export default ChatSettings;
