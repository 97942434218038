// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Jumbotron, Image, Form, Row, Col, ProgressBar } from 'react-bootstrap';
import _, { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import { PRODUCTION_BASELOGO_URL, WEBSOCKET_COMMAND_TYPE, OTHER_SCHOOL_ID } from '../../../utils/Constants';
import './SignUpPage.scss';
import genderData from '../../../stores/data/genderData.json';
import countryData from '../../../stores/data/countryData.json';
import hobbiesData from '../../../stores/data/hobbiesData.json';

import MySelect from '../../select/Select';

interface State {
  stageCouter: any;
  file: any;
  imageURL: any;
  schoolID: any;
  majorID: any;
  firstName: any;
  lastName: any;
  gender: any;
  nationality: any;
  hobbies: any;
  manualSchool: string;
}

interface Props {
  authStore: any;
  rootStore: any;
  history: any;
}

@inject('rootStore')
@observer
export default class SignUpPage extends Component<Props, State> {
  rootStore: any;

  authStore: any;

  schoolData: any;

  majorData: any;

  constructor(props: Props) {
    super(props);
    const { rootStore } = this.props;
    this.rootStore = rootStore;
    this.authStore = this.rootStore.authStore;
    this.state = {
      stageCouter: 1,
      file: null,
      imageURL: '',
      schoolID: '',
      majorID: '',
      firstName: '',
      lastName: '',
      gender: '',
      nationality: '',
      hobbies: '',
      manualSchool: '',
    };
  }

  // renderGenderOpt = () => {
  //   return genderData.map(({ gender, text }: any, i: number) => <option value={gender} label={text} />);
  // };

  // renderCountryOpt = () => {
  //   return countryData.map(({ code, name }: any) => <option value={code} label={`${name} (${code})`} />);
  // };

  // renderHobbiesOpt = () => {
  //   return hobbiesData.map(({ hobbies, text }: any, i: number) => <option value={hobbies} label={text} />);
  // }

  renderHobbiesOpt = (): React.ReactNode => {
    const { hobbies } = this.state;
    const hobbiesArr: any = [];
    const hobbiesJson = hobbiesData;
    _.each(hobbiesJson, (value) => {
      hobbiesArr.push(<option value={value.hobbies}>{value.text}</option>);
    });
    return (
      <Form>
        <Form.Group>
          <Form.Control
            value={hobbies}
            onChange={(e: any) => {
              this.setState({ hobbies: e.target.value });
            }}
            as="select"
          >
            <option>Select..</option>
            {hobbiesArr}
          </Form.Control>
        </Form.Group>
      </Form>
    );
  };

  renderCountryOpt = (): React.ReactNode => {
    const { nationality } = this.state;
    const countryArr: any = [];
    const country = countryData;
    _.each(country, (value) => {
      countryArr.push(
        <option key={value.code} value={value.code}>
          {value.name}
        </option>,
      );
    });
    return (
      <Form>
        <Form.Group>
          <Form.Control
            value={nationality}
            onChange={(e: any) => {
              this.setState({ nationality: e.target.value });
            }}
            as="select"
          >
            <option>Select..</option>
            {countryArr}
          </Form.Control>
        </Form.Group>
      </Form>
    );
  };

  renderGenderOpt = (): React.ReactNode => {
    const { gender } = this.state;
    const genderArr: any = [];
    const genders = genderData;
    _.each(genders, (value) => {
      genderArr.push(
        <option key={value.gender} value={value.gender}>
          {value.text}
        </option>,
      );
    });
    return (
      <Form>
        <Form.Group>
          <Form.Control
            value={gender}
            onChange={(e: any) => {
              this.setState({ gender: e.target.value });
            }}
            as="select"
          >
            <option>Select..</option>
            {genderArr}
          </Form.Control>
        </Form.Group>
      </Form>
    );
  };

  private onChangeManualSchool = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ manualSchool: e.target.value });
  };

  private handleSchoolInputChange = (newValue: any) => {
    const inputValue = newValue.replace(/\W/g, '');
    if (!!inputValue.trim()) {
      this.searchSchoolDelayed(inputValue);
    }
  };

  private handleSchoolSelect = (e: any) => {
    const schoolID = e.value;
    this.setState({ schoolID });
    const { manualSchool } = this.state;
    if (schoolID !== OTHER_SCHOOL_ID && manualSchool !== '') {
      this.setState({ manualSchool: '' });
    }
  };

  private searchSchoolDelayed = debounce((e) => {
    this.searchSchoolByKeyword(e);
  }, 400);

  private searchSchoolByKeyword = (keyword: string): any => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_SCHOOLS_BY_KEYWORD,
      data: { keyword },
    });
  };

  private renderSchoolOpt = () => {
    return (
      <>
        <MySelect
          placeholder="Search School.."
          onInputValueChange={this.handleSchoolInputChange}
          options={toJS(this.authStore.schoolListByKeyword)}
          onOptionSelect={this.handleSchoolSelect}
          clearSuggestions={this.authStore.resetSchoolListByKeyword}
          inputIdentifier="schoolInput"
        />
      </>
    );
  };

  private searchMajorByKeyword = (keyword: string): void => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_MAJORS_BY_KEYWORD,
      data: { keyword },
    });
  };

  private handleMajorInputChange = (newValue: any) => {
    const inputValue = newValue.replace(/\W/g, '');
    if (!!inputValue.trim()) {
      this.searchMajorDelayed(inputValue);
    }
  };

  private handleMajorSelect = (e: any): void => {
    this.setState({
      majorID: e.value,
    });
  };

  private searchMajorDelayed = debounce((e) => {
    this.searchMajorByKeyword(e);
  }, 400);

  renderMajorOpt = (): React.ReactNode => {
    return (
      <MySelect
        placeholder="Search Major.."
        onInputValueChange={this.handleMajorInputChange}
        options={toJS(this.authStore.majorListByKeyword)}
        onOptionSelect={this.handleMajorSelect}
        clearSuggestions={this.authStore.resetMajorListByKeyword}
        inputIdentifier="majorInput"
      />
    );
  };

  onChangeGender = (value: any) => {
    this.setState({
      gender: value,
    });
  };

  renderFirstStep = (): React.ReactNode => {
    const { firstName, lastName, schoolID, manualSchool } = this.state;
    return (
      <Form className="mx-auto" id="formHeader">
        <Form.Group>
          <Form.Row>
            <Col>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                placeholder="First Name"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e: any) => this.setState({ firstName: e.target.value })}
                value={firstName}
              />
            </Col>
            <Col>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                placeholder="Last Name"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e: any) => this.setState({ lastName: e.target.value })}
                value={lastName}
              />
            </Col>
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>Gender</Form.Label>
          {this.renderGenderOpt()}
        </Form.Group>
        <Form.Group>
          <Form.Label>Nationality</Form.Label>
          {this.renderCountryOpt()}
        </Form.Group>
        <Form.Group>
          <Form.Label className="mb-1">School</Form.Label>
          {this.renderSchoolOpt()}
        </Form.Group>
        {schoolID === OTHER_SCHOOL_ID && (
          <Form.Group>
            <Form.Row>
              <Col>
                <Form.Label>School (manual)</Form.Label>
                <Form.Control
                  placeholder={manualSchool || 'School (manual)'}
                  aria-label="ManualSchool"
                  aria-describedby="basic-addon1"
                  onChange={this.onChangeManualSchool}
                  value={manualSchool}
                />
              </Col>
            </Form.Row>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label className="mb-1">Major</Form.Label>
          {this.renderMajorOpt()}
        </Form.Group>
      </Form>
    );
  };

  handleImagePicker = (e: any): void => {
    const file = e.target.files[0];
    if (!file) {
      this.setState({ file: null, imageURL: '' });
      return;
    }

    const types = ['image/png', 'image/jpeg'];
    if (types.every((type) => file.type !== type)) {
      // eslint-disable-next-line no-alert
      alert('Please choose an image in the supported format (jpeg and png)!');
    } else {
      this.setState({ file, imageURL: URL.createObjectURL(file) });
    }
  };

  renderLastStep = (): React.ReactNode => {
    const { imageURL } = this.state;
    return (
      <Row id="formHeader" className="sign-up-page__last-step d-flex flex-column mx-auto">
        <Col className="d-flex align-items-center justify-content-center">Upload Profile Picture</Col>
        <Col className="text-center">
          <Image className="sign-up-page__last-step__pic" src={imageURL || PRODUCTION_BASELOGO_URL} roundedCircle />
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          <label className="btn btn-primary">
            Upload
            <input type="file" name="image" onChange={this.handleImagePicker} />
          </label>
        </Col>
      </Row>
    );
  };

  termsOFService = (): React.ReactNode => {
    const { stageCouter } = this.state;
    if (stageCouter !== 2) {
      return null;
    }
    return (
      <div className="align-items-center justify-content-center">
        <Col className="align-items-center justify-content-center">
          <p style={{ fontSize: '12px' }}>
            BASE is an open chat app built for students by students.
            <br />
            We use algorithms to measure and showcase our soft skills/positive traits based on our interactions.
            <br />
            <br />
            By clicking “Submit” button, you agree to our{' '}
            <Link target="_blank" to="https://base.town/tos.html">
              terms of service
            </Link>
            .
          </p>
        </Col>
      </div>
    );
  };

  stageIndicator = (): React.ReactNode => {
    const { stageCouter } = this.state;
    let percentage;

    switch (stageCouter) {
      case 1:
        percentage = 50;
        break;
      case 2:
        percentage = 100;
        break;
    }
    return (
      <Row className="mb-4">
        <Col>
          <ProgressBar now={percentage} />
        </Col>
      </Row>
    );
  };

  renderProcessBtn = (): React.ReactNode => {
    const { stageCouter } = this.state;
    const isLast = stageCouter === 2;
    return (
      <Row className="col-12 sign-up-page__process-btn-container" style={{ marginBottom: '20px' }}>
        <Col>
          {isLast && (
            <Button
              className="mr-2 align-items-left"
              variant="primary"
              style={{ float: 'left' }}
              onClick={() => {
                this.setState((prevState) => ({ stageCouter: prevState.stageCouter - 1 }));
              }}
            >
              Previous
            </Button>
          )}
          <Button
            className="align-items-right"
            variant="primary"
            style={{ float: 'right' }}
            onClick={() => this.onClikcProcessBtn()}
          >
            {isLast ? 'Submit' : 'Next'}
          </Button>
        </Col>
      </Row>
    );
  };

  onClikcProcessBtn = async (): Promise<void> => {
    const { stageCouter, firstName, lastName, gender, nationality, schoolID, majorID, manualSchool, file } = this.state;
    const { history } = this.props;
    const trimedFirstName = firstName.trim();
    const trimedLastName = lastName.trim();
    switch (stageCouter) {
      case 1:
        if (!(trimedFirstName && trimedLastName && gender && nationality && schoolID && majorID)) {
          // eslint-disable-next-line no-alert
          alert('Please fill out all the items');
        } else if (schoolID === OTHER_SCHOOL_ID && manualSchool === '') {
          // eslint-disable-next-line no-alert
          alert('Please fill out all the items');
        } else if (schoolID === OTHER_SCHOOL_ID && (manualSchool.length > 30 || manualSchool.length < 2)) {
          // eslint-disable-next-line no-alert
          alert('Manula school name must contains between 2 and 30 letters including a space in between');
        } else if (
          /^([a-zA-Z]?(\s?[a-zA-Z])){2,30}$/.test(trimedFirstName) &&
          /^([a-zA-Z]?(\s?[a-zA-Z])){2,30}$/.test(trimedLastName)
        ) {
          this.setState({ stageCouter: stageCouter + 1 });
        } else {
          // eslint-disable-next-line no-alert
          alert('First Name and Last Name must contain between 2 and 30 English letters including a space in between');
        }
        break;
      case 2:
        if (!file) {
          // eslint-disable-next-line no-alert
          alert('Profile picture is required');
          return;
        }

        await this.authStore.createUser({
          firstName: trimedFirstName,
          lastName: trimedLastName,
          gender,
          nationality,
          schoolID,
          majorID,
          file,
          manualSchool,
        });

        history.push('/welcome');
        break;
      default:
        break;
    }
  };

  render(): React.ReactNode {
    const { stageCouter } = this.state;
    return (
      <div
        id="formHeader"
        className="sign-up-page d-flex align-items-center col-12 container-fluid bgGray-1 overflow-auto"
      >
        <Jumbotron className="col-12 col-md-8 col-lg-6 col-xl-4 m-auto p-4 rounded">
          <div className="text-center">
            <Image className="col-2 mb-2" src={PRODUCTION_BASELOGO_URL} roundedCircle />
            <h4 className="sign-up-page__title">SIGN-UP</h4>
          </div>
          <div id="sign-up-container" className="col-12 mx-auto p-0">
            {stageCouter === 1 && this.renderFirstStep()}
            {stageCouter === 2 && this.renderLastStep()}
            {this.termsOFService()}
            <div className="col-md-12 align-items-right d-flex p-4">{this.renderProcessBtn()}</div>
          </div>
        </Jumbotron>
      </div>
    );
  }
}
