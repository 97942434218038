import firebase from 'firebase';
import { RELEASE_MODE, CURRENT_RELEASE_MODE } from '../utils/Constants';

// Initialize Firebase
// depends on release mode
// else statement is about production
if (CURRENT_RELEASE_MODE === RELEASE_MODE.STAGING) {
  const config = {
    apiKey: 'AIzaSyCjWyrIzayUmEBcMcQyPvcXjNDkQONfJA8',
    authDomain: 'base-6d44c.firebaseapp.com',
    databaseURL: 'https://base-6d44c.firebaseio.com',
    projectId: 'base-6d44c',
    storageBucket: 'base-6d44c.appspot.com',
    messagingSenderId: '258311179923',
  };
  firebase.initializeApp(config);
} else {
  const config = {
    apiKey: 'AIzaSyDHnDObdPUAOFJfRpgC1tOG59NhheQYUmk',
    authDomain: 'netwoko-staging.firebaseapp.com',
    databaseURL: 'https://netwoko-staging.firebaseio.com',
    projectId: 'netwoko-staging',
    storageBucket: 'netwoko-staging.appspot.com',
    messagingSenderId: '619575378844',
  };
  firebase.initializeApp(config);
}
export default firebase;
