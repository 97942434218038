import React, { useState, useEffect } from 'react';
import { Button, Image, Row } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import firebase from '../../../firebase/firebase';
import { PRODUCTION_BASELOGO_URL } from '../../../utils/Constants';

interface Props {
  load?: () => void;
}

const EmailVerification: React.FC<Props> = ({ load }: Props) => {
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (!ready) {
      setTimeout(() => {
        setReady(true);
      }, 300000);
    }
  }, [ready]);

  const reSendVerification = () => {
    if (ready) {
      setReady(false);
      firebase.auth().onAuthStateChanged(async (user: any | null) => {
        try {
          if (user) {
            user.sendEmailVerification();
          }
        } catch (error) {
          console.error(error);
        }
      });
    }
  };

  const verifyUser = () => {
    firebase.auth().onAuthStateChanged(async (user: any | null) => {
      try {
        if (user) {
          await user.reload();
          if (user.emailVerified) {
            load!();
          } else {
            alert('Your email is not verified, please click the re-send button');
          }
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <div className="text-white h-100 p-0 d-flex align-items-center justify-content-center col-12 container-fluid bgGray-1">
      <div className="col-11 col-md-6 py-4 d-flex flex-column align-items-center justify-content-center shadow-lg bgGray-3 rounded">
        <div className="col-12">
          <div className="text-center mb-4">
            <Image className="col-2 p-0 mb-3" src={PRODUCTION_BASELOGO_URL} roundedCircle />
            <h3 className="mb-3">We have sent a verification link to your email address</h3>
            <h5>You can request a verification link again 5 minutes after your last send request</h5>
          </div>
          <Row className="d-flex flex-row-reverse">
            <Button onClick={verifyUser} className="border border-secondary" style={{ backgroundColor: '#5D7E9D' }}>
              DONE
            </Button>
            <Button
              onClick={reSendVerification}
              className="border border-secondary mr-3"
              style={{ backgroundColor: '#5D7E9D' }}
              disabled={!ready}
            >
              RE-SEND
            </Button>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default inject(({ rootStore }) => ({ load: rootStore.authStore.load }))(observer(EmailVerification));
