import { CompositeDecorator } from 'draft-js';
import { MentionTag, HashtagSpan, LinkTag } from './TagComponents';

export default new CompositeDecorator([
  {
    strategy: hashtagStrategy,
    component: HashtagSpan,
  },
  {
    strategy: findLinkEntities,
    component: LinkTag,
  },
  {
    strategy: findMentionEntities,
    component: MentionTag,
  },
]);

const HASHTAG_REGEX = /#[\w\u0590-\u05ff]+/g;

function hashtagStrategy(contentBlock: any, callback: any, contentState: any) {
  findWithRegex(HASHTAG_REGEX, contentBlock, callback);
}

function findWithRegex(regex: any, contentBlock: any, callback: any) {
  const text = contentBlock.getText();
  let matchArr;
  let start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

function findLinkEntities(contentBlock: any, callback: any, contentState: any) {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

function findMentionEntities(contentBlock: any, callback: any, contentState: any) {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'MENTION';
  }, callback);
}
