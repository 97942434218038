import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment';
import { toJS } from 'mobx';
import { Card, Button, Modal } from 'react-bootstrap';
import Sidebar from './Sidebar';

import './css/MessageDetailPage.css';
import './css/ChatDetailPage.css';

interface State {
  sideNavOpen: boolean;
  isModalOpen: boolean;
}

interface Props {
  rootStore?: any;
}

@inject('rootStore')
@observer
class MessageDetailPage extends Component<Props, State> {
  state: State;
  rootStore: any;
  chatStore: any;
  authStore: any;
  sidebarStore: any;

  constructor(props: any) {
    super(props);
    this.state = {
      sideNavOpen: false,
      isModalOpen: false,
    };
    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
    this.sidebarStore = this.rootStore.sidebarStore;
  }

  modalClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleReport = () => {
    this.chatStore.hideReportedUserMessage();
    this.modalClose();
    this.sidebarStore.closeMessageDetail();
  };

  createReaderList = () => {
    let readerList: any = [];

    _.forEach(this.sidebarStore.readerList, (reader: any) => {
      const { firstName, lastName, time } = reader;
      readerList.push({
        userName: firstName + ' ' + lastName,
        time: time,
      });
    });

    readerList = _.orderBy(readerList, 'time', 'asc');

    return _.forEach(readerList, (reader: any) => {
      reader.time = this.formatTime(reader.time);
    });
  };

  formatTime = (time: any) => {
    return moment(time).local().format('MMMM DD, YYYY [at] h:mm a');
  };

  renderMessage = (message: any) => {
    let messageTextCount;

    // use lodash's isEmpty to check the observable's value
    if (!_.isEmpty(message)) {
      messageTextCount = message.text;
      if (messageTextCount.length > 100) {
        messageTextCount = messageTextCount.substring(0, 100);
        messageTextCount += '...';
      }
    }

    return message.type === 'Photo' && message.attachments && message.attachments.length > 0 ? (
      <Card.Img src={message.attachments[0].originalURL} style={{ height: 100 }} />
    ) : (
      messageTextCount
    );
  };

  SideNav = (props: any) => {
    const { message } = props;
    return (
      <Sidebar open={props.open} onCloseClick={props.onCloseClick} title="Message Detail" boundary>
        <div className="msgDetailSideNavContents msgData" style={{ color: '#3e4345', wordWrap: 'break-word' }}>
          {this.renderMessage(message)}
        </div>
        <div className="msgDetailSideNavContents text-left">
          <h5 style={{ color: '#D1CDC7' }}>
            Sent <FontAwesomeIcon icon={faCheck} className={'text-primary'} />
          </h5>
          <div className="reportBox">
            <p style={{ color: '#D1CDC7' }}>{this.formatTime(message.createdAt)}</p>
            <Button className="reportBtn" onClick={() => this.setState({ isModalOpen: true })}>
              Report
            </Button>
          </div>
          <hr style={{ borderColor: '#D1CDC7' }} />
          {_.isEmpty(props.readerList) || (
            <>
              <h5 style={{ color: '#D1CDC7' }}>
                Read <FontAwesomeIcon icon={faCheck} className={'text-primary'} />
              </h5>
              {this.createReaderList().map((reader: any) => {
                return (
                  <p style={{ color: '#D1CDC7' }}>
                    <strong>{reader.userName}</strong>
                    <br />
                    {reader.time}
                  </p>
                );
              })}
            </>
          )}
        </div>
      </Sidebar>
    );
  };

  ConfirmModal = (props: any) => {
    return (
      <Modal {...props} centered>
        <Modal.Body className="text-center p-5 text-white" style={{ background: '#2E302D' }}>
          <h5>Are you sure to report this message?</h5>
        </Modal.Body>
        <Modal.Footer className="text-white" style={{ background: '#2E302D' }}>
          <Button variant="secondary" onClick={this.modalClose}>
            Cancel
          </Button>
          <Button onClick={this.handleReport}>Confirm and Report</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    return (
      <>
        <this.SideNav
          open={this.sidebarStore.isMessageDetailOpen}
          onCloseClick={this.sidebarStore.closeMessageDetail}
          message={this.chatStore.selectedMessage}
          readerList={this.sidebarStore.readerList}
        />
        {this.state.isModalOpen && <this.ConfirmModal show={this.state.isModalOpen} onHide={this.modalClose} />}
      </>
    );
  }
}

export default MessageDetailPage;
