// eslint-disable-next-line no-use-before-define
import React from 'react';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Select.css';

interface Props {
  onOptionSelect: any;
  onInputValueChange: any;
  options: any;
  placeholder: string;
  clearSuggestions: Function;
  inputIdentifier: string;
}

const Select = ({
  onOptionSelect,
  onInputValueChange,
  options,
  placeholder,
  clearSuggestions,
  inputIdentifier,
}: Props) => {
  const inputRef = React.useRef(null);
  const [inputValue, setInputValue] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState({
    value: '',
    label: '',
  });
  const [isDropdownShown, setIsDropDownShown] = React.useState(false);

  const onFocus = React.useCallback(() => {
    setIsDropDownShown(true);
  }, []);

  const onOptionClick = React.useCallback(
    (option) => {
      setSelectedOption(option);
      setIsDropDownShown(false);
      setInputValue('');
      if (onOptionSelect) {
        onOptionSelect(option);
      }
    },
    [onOptionSelect],
  );

  React.useEffect(() => {
    window.addEventListener('mousedown', (e) => {
      const target = e.target as HTMLTextAreaElement;
      if (target !== null && target.id !== 'dropdown-item' && target.id !== inputIdentifier) {
        // when a user click anything but items on dropdown or input
        setInputValue('');
        setIsDropDownShown(false);
        clearSuggestions([]);
      }
    });
  }, []);

  return (
    <div id="wrapper" onFocus={onFocus}>
      <div id="container">
        <div id="input-wrapper">
          {/* eslint-disable-next-line jsx-a11y/autocomplete-valid */}
          <input
            ref={inputRef}
            onChange={(e) => {
              setInputValue(e.target.value);
              if (onInputValueChange) {
                onInputValueChange(e.target.value);
              }
            }}
            value={inputValue}
            className="search-input"
            id={inputIdentifier}
            autoCapitalize="none"
            autoComplete="none"
            autoCorrect="off"
            spellCheck={false}
            tabIndex={0}
          />
          {inputValue.length === 0 && (
            <div id="input-value">{selectedOption.value !== '' ? selectedOption.label : placeholder}</div>
          )}
        </div>
        <div className="dropdown-button-container">
          <div id="indicator-separator" />
          <button
            type="button"
            className="dropdown-button"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </button>
        </div>
      </div>
      <div id="dropdown-container" style={{ display: isDropdownShown ? 'block' : 'none' }}>
        {options.length === 0 ? (
          <div id="nooptions-message">No Options</div>
        ) : (
          <div style={{ width: '100%' }}>
            {options.map((item: any) => (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                onClick={() => {
                  onOptionClick(item);
                  clearSuggestions([]);
                }}
                key={item.value}
                id="dropdown-item"
              >
                {item.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;
