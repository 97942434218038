import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Image, Modal, Button, InputGroup, FormControl } from 'react-bootstrap';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import DMForm from './DMForm';

interface Props {
  rootStore?: any;
}
interface State {
  mouseOnUserInfo: any;
}

const primaryColor = '#5D7F9D';

@inject('rootStore')
@observer
class UserModal extends Component<Props, State> {
  state: State;
  chatStore: any;
  authStore: any;
  constructor(props: any) {
    super(props);
    this.state = {
      mouseOnUserInfo: false,
    };
    this.chatStore = this.props.rootStore.chatStore;
    this.authStore = this.props.rootStore.authStore;
  }
  handleHide = () => {
    this.chatStore.closeUserModal();
    this.chatStore.resetDMData();
    this.chatStore.setShowDMForm(false);
  };
  clickDMBtn = () => {
    this.chatStore.createPersonalChat(this.chatStore.userModalData.uid);
    this.chatStore.setShowDMForm(true);
  };

  render() {
    if (this.chatStore.showUserModal) {
      const { uid, firstName, lastName, userThumbnailURL, schoolName, majorName } = this.chatStore.userModalData;
      const selfUserID = this.authStore.selfUser.uid;
      return (
        <Modal show={this.chatStore.showUserModal} onHide={() => this.handleHide()} size="sm" centered>
          <Modal.Header closeButton style={{ borderBottom: '0px' }}></Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>
            <Image
              style={{
                height: 200,
                width: 200,
                marginBottom: 10,
                textDecoration: 'underline',
                border: this.state.mouseOnUserInfo ? '2px solid black' : '2px solid white',
              }}
              onMouseEnter={() => {
                this.setState({ mouseOnUserInfo: true });
              }}
              onMouseLeave={() => {
                this.setState({ mouseOnUserInfo: false });
              }}
              onClick={() => {
                this.handleHide(), this.chatStore.handleRenderProfile();
              }}
              src={userThumbnailURL}
              roundedCircle
            />
            <h3>
              {firstName} {lastName}
            </h3>
            <h6>{schoolName}</h6>
            <h6>{majorName}</h6>
          </Modal.Body>
          {selfUserID !== uid && (
            <Modal.Footer>
              {this.chatStore.showDMForm ? (
                <DMForm />
              ) : (
                <Button
                  style={{ margin: 'auto', backgroundColor: primaryColor, border: '0px' }}
                  onClick={() => {
                    this.chatStore.isOpenProfile = true;
                    this.chatStore.handleRenderProfile();
                    this.clickDMBtn();
                  }}
                >
                  <FontAwesomeIcon icon={faComments} style={{ fontSize: '30' }} />
                </Button>
              )}
            </Modal.Footer>
          )}
        </Modal>
      );
    } else {
      return null;
    }
  }
}
export default UserModal;
