import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';
import Account from './components/user/Account';
import LandingPage from './components/landing/LandingPage';
import RankingPage from './components/ranking/RankingPage';
import UserList from './components/user/UserList';
import AuthHomePage from './components/auth/AuthHomePage';
import SignUpPage from './components/auth/SignUpPage';
import SignUpSuccessPage from './components/auth/SignUpSuccessPage';
import EmailVerification from './components/auth/EmailVerification';
import LoadingPage from './components/auth/LoadingPage';
import PasswordReset from './components/auth/PasswordReset';
import EmailForm from './components/auth/EmailForm';
import PhoneNumberForm from './components/auth/PhoneNumberForm';
import RankingChart from './components/chat/RankingChart';
import UserProfile from './components/user/UserProfile';

export const history = createHistory();
ReactGA.initialize('UA-117552566-3');

history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});
window.onbeforeunload = function () {
  <Redirect to={'/home'} />;
};
const BaseRouter: React.FC = () => {
  return (
    <Router history={history}>
      <div style={{ backgroundColor: '#252421', padding: 0, height: '100vh', overflow: 'hidden' }}>
        <Switch>
          <Route exact path="/" component={LoadingPage} />
          <Route path="/home" component={LandingPage} />
          <Route path="/profile/:uid" component={UserProfile} />
          <Route path="/userlist" component={UserList} />
          <Route path="/ranking" component={RankingPage} />
          <Route path="/authentication" component={AuthHomePage} />
          <Route path="/auth-email" component={EmailForm} />
          <Route path="/auth-phone" component={PhoneNumberForm} />
          <Route path="/password-reset" component={PasswordReset} />
          <Route path="/signup" component={SignUpPage} />
          <Route path="/welcome" component={SignUpSuccessPage} />
          <Route path="/email-verification" component={EmailVerification} />
        </Switch>
      </div>
    </Router>
  );
};

export default BaseRouter;
