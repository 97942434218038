/* @flow */
import { observable, action, $mobx, toJS } from 'mobx';
import PubSub from 'pubsub-js';
import _, { at } from 'lodash';
import ImageCompressor from 'image-compressor.js';
import RootStore from './RootStore';
import ChatStore from './ChatStore';
import SidebarStore from './SidebarStore';
import firebase from '../firebase/firebase';
import { history } from '../BaseRouter';
import {
  WEBSOCKET_COMMAND_TYPE,
  STAGING_WEBSOCKET_URL,
  PRODUCTION_BASELOGO_URL,
  CURRENT_RELEASE_MODE,
  PRODUCTION_WEBSOCKET_URL,
  PRODUCTION_API_URL,
  STAGING_API_URL,
  RELEASE_MODE,
  STAGING_BASELOGO_URL,
} from '../utils/Constants';

import User from '../utils/User';
import { getInvitationLink } from '../utils/invitation_code';

const storage = firebase.storage();

export enum AuthStatus {
  NotAuthenticated,
  SignUpInProgress,
  SignUpCompleted,
}

interface SelfUser {
  firstName?: string;
  lastName?: string;
  schoolID?: string;
  email?: string;
  majorID?: string;
  thumbnailURL: string;
  phoneNumber?: string;
  currentTitle?: string;
  uid: any;
  fcmToken: any;
  isTutorialFinished?: boolean;
  stats?: object;
  baseAdmin?: boolean;
}

export default class AuthStore {
  public chatStore: ChatStore | undefined | any;

  public unsubscribe: (() => void) | undefined;

  private webSocket: WebSocket | undefined | null;

  public initialDataChecker: Map<string, boolean> = new Map();

  public confirmResult: any = null;

  private rootStore: RootStore;

  private firebaseUser: null | undefined | any;

  public isWebSocketConnected = false;

  public applicationVerifier: any;

  private ImageCompressor = new ImageCompressor();

  private packetQueue: object[] = [];

  private numReconnectAttempt = 0;

  private shouldRestoreWebSocketConnetion = true;

  paramUID = '';

  @observable public authStatus: AuthStatus = -1;

  @observable public schoolID = '';

  @observable public majorID = '';

  @observable public cachedUserList: any = {}; // TODO :: Speficy exact type

  @observable public selfUser: SelfUser | null | undefined | any = {};

  @observable public isCodeValid = false;

  @observable private schoolList: any = {};

  @observable public schoolStats: any = {};

  @observable private majorList: any = {};

  @observable public majorListByKeyword: any = [];

  @observable public schoolListByKeyword: any = [];

  @observable public selfUserThumbnailURL: any = '';

  @observable public rankingArray: any = [];

  @observable public numTimerRefreshed = 0;

  @observable public searchedUsers: User | null | undefined | any = {};

  @observable public signUpWithInvitation: any = {};

  @observable isEmailSignUp = false;

  @observable totalUsers: number = 0;

  @observable public invitationCodes: any = [];

  @observable public invitationCode: any = {};

  constructor(rootStore: RootStore) {
    this.chatStore = rootStore.chatStore;
    this.rootStore = rootStore;
  }

  public load = () => {
    this.unsubscribe = firebase.auth().onAuthStateChanged(async (user: any | null) => {
      try {
        // SignIn
        if (user) {
          this.firebaseUser = user;
          if (!!user.email && !user.emailVerified) {
            user.sendEmailVerification();
            history.replace('/email-verification');
          } else {
            this.registerSubscribers();
            this.initWebSocket();
          }
        } else {
          this.authStatus = AuthStatus.NotAuthenticated;
          history.replace('/authentication'); // TODO to be deleted when customized routes are implemented
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  getTotalUsers = () => {
    this.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_TOTAL_USERS,
    });
  };

  @action
  resGetTotalUsers = (_: string, data: any) => {
    this.totalUsers = data.totalUsers;
  };

  getInvitationCodes = (userID: string) => {
    this.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_INVITATION_CODES,
      data: {
        userID,
      },
    });
  };

  @action
  resGetInvitationCodes = (_: string, data: any) => {
    this.invitationCodes = data.invitationCodes || [];
  };

  createInvitationCode = (userID: string, roomID?: string) => {
    this.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.CREATE_INVITATION_CODE,
      data: {
        userID,
        roomID,
      },
    });
  };

  @action
  resCreateInvitationCode = (_: string, data: any) => {
    const { invitationCode, error } = data;
    const errorMessage = error ? { message: 'You reached the limit of invitation code creations' } : null;

    this.invitationCode = {
      inviteCode: invitationCode,
      error: errorMessage,
    };

    if (!this.invitationCode.error) {
      const copyClipboard = async () => {
        const invitaionLink = await getInvitationLink(this.invitationCode.inviteCode);
        const textToCopy = `${invitaionLink}\nYour invitation code: ${this.invitationCode.inviteCode}`;
        try {
          await navigator.clipboard.writeText(textToCopy);
          alert('Invitation link is copied!');
        } catch (err) {
          alert('Failed to copy!');
        }
      };
      copyClipboard();
    }
  };

  private registerSubscribers = () => {
    // Register websocket litensers

    // User
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.VERIFY_USER, this.resAuthenticated);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.CREATE_USER, this.resCreateUser);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.V2_GET_USERINFO_WITH_CHAT_AND_STAT, this.resUserInfo);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.LOAD_EXPLORE_CHATLIST, this.chatStore.resLoadExploreChatList);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.LOAD_PARTICIPATING_CHATLIST, this.chatStore.resLoadMyChatList);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.LOAD_FOLLOWING_CHATLIST, this.chatStore.resLoadFollowingChatList);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.V2_LOAD_STUDENT_LIST_SEARCH, this.resV2StudentListSearch);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.V2_LOAD_USER_LIST_W_FILTER, this.resV2StudentListSearch);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.PROMOTE_MEMBER, this.chatStore.resPromoteMember);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.DEMOTE_MEMBER, this.chatStore.resDemoteMember);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_TOTAL_USERS, this.resGetTotalUsers);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_INVITATION_CODES, this.resGetInvitationCodes);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.CREATE_INVITATION_CODE, this.resCreateInvitationCode);

    // School
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.LOAD_SCHOOLLIST, this.resLoadSchoolList);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_SCHOOLS_BY_KEYWORD, this.loadSchoolListWithKeyword);
    // Major
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.LOAD_MAJORLIST, this.resLoadMajorList);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_MAJORS_BY_KEYWORD, this.loadMajorListWithKeyword);

    // Chat
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.SEND_MESSAGE, this.chatStore.resSendMessage);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.V2_UPDATE_READMSG, this.chatStore.resUpdateReadMessage);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_MEDIA, this.chatStore.resGetChatMedia);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_DOCMAP, this.chatStore.resGetChatDoc);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.LOAD_CHATMESSAGES, this.chatStore.resLoadChatMessages);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.STAR_MESSAGE, this.chatStore.resStarMessage);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_STARREDMSG, this.chatStore.resGetStarredMsg);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.REPORT_MESSAGE, this.chatStore.resReportMessage);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.FOLLOW_CHAT, this.chatStore.resFollowChat);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.UNFOLLOW_CHAT, this.chatStore.resUnfollowChat);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.CREATE_CHANNEL, this.chatStore.resCreateChannel);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.V3_CREATE_GROUP_CHAT, this.chatStore.resCreateGroupChat);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_ACTIVITYLOG, this.chatStore.resGetActivityLog);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.SEND_TEXT_DM, this.chatStore.resSendMessage);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_MOBILE_MESSGE_GROUPCHAT, this.chatStore.resSendMessage);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_MESSAGE_BY_ID, this.chatStore.resGetMessageById);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.LEAVE_CHATROOM, this.chatStore.resExitChatRoom);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.V2_PREPARE_PERSONAL_CHATROOM, this.chatStore.resCreatePersonalChat);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.UPDATE_CHATINFO, this.chatStore.resUpdateChat);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.INVITE_TOGROUP, this.chatStore.resInvitedToGroup);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.INVITE_TOGROUP, this.chatStore.resAddMemberToGroup);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.V2_GET_USERINFO, this.chatStore.resGetUserProfileData);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_USER_BASICINFO, this.chatStore.resGetUserModalData);
    PubSub.subscribe(
      WEBSOCKET_COMMAND_TYPE.GET_CHAT_PARTICIPANTLIST_MENTION,
      this.chatStore.resGetParticipantsForMention,
    );
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_CHAT_PARTICIPANTLIST, this.rootStore.sidebarStore.resOpenParticipants);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_CHAT_FOLLOWERLIST, this.rootStore.sidebarStore.resOpenFollowers);
    PubSub.subscribe(
      WEBSOCKET_COMMAND_TYPE.GET_MESSAGE_READER_LIST,
      this.rootStore.sidebarStore.resGetMessageReaderList,
    );
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.V3_LOAD_CHATLIST, this.chatStore.resGetMoreChatList);
    // PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_ROOM_IDS_BY_TOPIC, this.chatStore.resGetChatTopic);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.SEARCH_CHAT_LIST, this.chatStore.resGetChatTopic);
    PubSub.subscribe(
      WEBSOCKET_COMMAND_TYPE.GET_ACTIVE_GROUP_USER_RANKING,
      this.rootStore.sidebarStore.resInChatUserRanking,
    );
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.UPDATE_CHANNEL_NAME, this.chatStore.resUpdateChannelName);

    // Stats
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_CHAT_STATS, this.rootStore.sidebarStore.resGetChatStats);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_SCHOOL_STATS, this.resLoadSchoolStats);
    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.GET_TOP_USERS_INFO, this.chatStore.topUsersInfo);

    PubSub.subscribe(WEBSOCKET_COMMAND_TYPE.UPDATE_USERINFO, this.resUpdateUserInfo);
  };

  @action
  public clearSelectionOfUserList = () => {
    _.each(this.cachedUserList, (item) => {
      item.isSelected = false;
    });
  };

  public initWebSocket = async () => {
    if (!this.firebaseUser) {
      return;
    }
    const ip = await this.getWebsocketURL();
    const idToken = await this.getUserIDToken();
    // this.webSocket = new WebSocket(ip);
    this.webSocket = new WebSocket(ip, ['idToken', idToken, 'web']);

    this.webSocket.onopen = async () => {
      // const idToken = await this.getUserIDToken();
      // this.sendMessageToServer({ command: WEBSOCKET_COMMAND_TYPE.VERIFY_USER, data: { idToken, origin: 'web' } });
      this.numReconnectAttempt = 0;
      this.chatStore.maxAttemptReached = false;
      this.chatStore.maxTimerRefreshed = false;
      if (this.paramUID) {
        this.chatStore.getUserProfileData(this.paramUID);
        this.paramUID = '';
      }
    };
    this.webSocket.onmessage = (e) => {
      try {
        const data = JSON.parse(e.data);

        if ('Notification' in window && Notification.permission === 'granted') {
          if (typeof this.chatStore.isMemberOrFollower(data.roomID, this.rootStore.getUserUID()) !== 'undefined') {
            if (this.chatStore.isMemberOrFollower(data.roomID, this.rootStore.getUserUID()).status === 'member') {
              this.renderNotifications(data.message);
            }
          }
        }
        if (data.error) {
          throw data.error;
        }
        PubSub.publish(data.command, data);
      } catch (err) {
        console.info('An internal error below has occured.');
        console.info('************************************');
        console.error(err);
        console.info('************************************');
        alert('An internal error has occured.');
      }
    };

    this.webSocket.onerror = (e: any) => {
      // an error occurred
      console.error(e.message);
    };

    this.webSocket.onclose = (e: any) => {
      this.isWebSocketConnected = false;
      this.chatStore.connecting = false;
      this.chatStore.isInternetConnected = false;
      this.webSocket = null;

      if (this.shouldRestoreWebSocketConnetion) {
        setTimeout(() => this.initWebSocket(), 5000);
      }
    };

    // this.webSocket.onclose = (e) => {
    //     this.isWebSocketConnected = false;
    //     this.chatStore.connecting = true;

    //     this.numReconnectAttempt++;

    //     if (!this.chatStore.maxTimerRefreshed && !this.chatStore.maxAttemptReached) {
    //         const randomTime = Math.floor(Math.random() * 1500);
    //         setTimeout(() => this.initWebSocket(), randomTime)
    //     } else {
    //         this.chatStore.maxAttemptReached = true;
    //         this.chatStore.maxTimerRefreshed = true;
    //         return
    //     }
    // };
  };

  public renderNotifications(message: any) {
    if (message && !message.system && this.getUserData().uid !== message.senderID) {
      if (message.type === 'Photo') {
        return new Notification(message.sender.name, {
          body: message.text,
          icon: message.attachments[0].originalURL || STAGING_BASELOGO_URL,
        });
      }
      return new Notification(message.sender.name, {
        body: message.text,
        icon: message.sender.avatar || STAGING_BASELOGO_URL,
      });
    }
    return null;
  }

  @action
  public resGetRanking = (command: string, data: any) => {
    this.rankingArray = data.rankingUserList;
  };

  @action
  public resUserInfo = (command: string, data: any) => {
    // this.setInitialLoadChecker(command);
  };

  public setShouldRestoreWebSocketConnection = (val: boolean) => {
    this.shouldRestoreWebSocketConnetion = val;
  };

  public phoneNumberVerification = async (countryCode: any, phoneNumber: any) => {
    this.applicationVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
    });

    firebase
      .auth()
      .signInWithPhoneNumber(countryCode + phoneNumber, this.applicationVerifier)
      .then(
        (confirmResult) => {
          if (confirmResult.verificationId === null) {
            return;
          }
          this.confirmResult = confirmResult;
        },
        (error) => {
          console.info('An internal error below has occured.');
          console.info('************************************');
          console.error(error.message);
          console.info('************************************');
          alert('An internal error has occured.');
          // onFail();
        },
      );
  };

  public verifyCode = (verifyNumber: string) => {
    return this.confirmResult
      .confirm(verifyNumber)
      .then(
        action((result: any) => {
          // User signed in successfully
          this.isCodeValid = true;
        }),
      )
      .catch((error: any) => {
        alert('Code invalid. Please try again');
      });
  };

  public resendVerificationCode = async (countryCode: any, phoneNumber: any) => {
    firebase
      .auth()
      .signInWithPhoneNumber(countryCode + phoneNumber, this.applicationVerifier)
      .then(
        (confirmResult) => {
          if (confirmResult.verificationId === null) {
            return;
          }
          this.confirmResult = confirmResult;
        },
        (error) => {
          console.info('An internal error below has occured.');
          console.info('************************************');
          console.error(error);
          console.info('************************************');
          alert('An internal error has occured.');
          // onFail();
        },
      );
  };

  public createUser = async (inputData: any) => {
    const { firstName, lastName, gender, nationality, schoolID, majorID, file, manualSchool } = inputData;
    const imgExtension = 'jpg';
    const uploadedAt = new Date().getTime();
    const fileName = `profilepic_${uploadedAt}.${imgExtension}`;
    const fileRef = storage.ref(`users/${this.rootStore.getUserUID()}/${fileName}`);
    const compressionSmall = {
      quality: 0.7,
      maxWidth: 400,
      maxHeight: 400,
      mimeType: 'image/jpeg',
    };
    try {
      this.ImageCompressor.compress(file, compressionSmall).then((response: any) => {
        fileRef.put(response).then((image: any) => {
          image.ref.getDownloadURL().then(
            action((url: string) => {
              const invitedData = {
                roomID: this.signUpWithInvitation.roomID,
                invitedBy: this.signUpWithInvitation.invitedBy,
              };
              // Send signup request
              this.sendMessageToServer({
                command: WEBSOCKET_COMMAND_TYPE.CREATE_USER,
                data: {
                  userData: {
                    firstName,
                    lastName,
                    gender,
                    nationality,
                    schoolID,
                    majorID,
                    manualSchool,
                    thumbnailURL: url,
                    phoneNumber: this.firebaseUser.phoneNumber,
                    email: this.firebaseUser.email,
                    currentTitle: 'newbie',
                    platform: 'web',
                  },
                  invitedData,
                },
              });

              this.selfUserThumbnailURL = url;
            }),
          );
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  public setInitialLoadChecker = (command: string) => {
    this.initialDataChecker.set(command, true);
  };

  @action
  public resLoadMajorList = (command: string, data: any) => {
    _.each(data.majorList, (item, majorID) => {
      this.majorList[majorID] = item;
      this.majorList[majorID].key = item.name;
      this.majorList[majorID].label = item.name;
      this.majorList[majorID].uri = item.thumbnailURL || PRODUCTION_BASELOGO_URL;
    });

    this.setInitialLoadChecker(command);
    // TODO :: sort
    // arr.sort((a, b) => (Object.values(a)[0].name.toLowerCase() > Object.values(b)[0].name.toLowerCase()) ? 1 : ((Object.values(b)[0].name.toLowerCase() > Object.values(a)[0].name.toLowerCase()) ? -1 : 0));
  };

  @action
  public resLoadSchoolList = (command: string, data: any) => {
    _.each(data.schoolList, (item, schoolID) => {
      this.schoolList[schoolID] = item;
      this.schoolList[schoolID].key = item.name;
      this.schoolList[schoolID].label = item.name;
      this.schoolList[schoolID].uri = item.thumbnailURL || PRODUCTION_BASELOGO_URL;
    });
    this.setInitialLoadChecker(command);

    // TODO :: sort
    // arr.sort((a, b) => (Object.values(a)[0].name.toLowerCase() > Object.values(b)[0].name.toLowerCase()) ? 1 : ((Object.values(b)[0].name.toLowerCase() > Object.values(a)[0].name.toLowerCase()) ? -1 : 0));
  };

  @action
  public resLoadSchoolStats = (_: string, data: any) => {
    this.schoolStats = data.schoolStats;
  };

  @action
  private resAuthenticated = (_: any, data: any) => {
    if (!data.isAuthenticated) {
      alert('Authentication Failed. Please restart.');
      return;
    }

    this.isWebSocketConnected = true;
    // this.Chatstore.isInternetConnected = true;
    this.chatStore.isInternetConnected = true;
    this.chatStore.connecting = false;

    this.selfUser = data.userInfo;
    if (this.selfUser) {
      this.selfUser.uid = data.userID;
      this.authStatus = AuthStatus.SignUpCompleted;
      switch (history.location.pathname) {
        case '/':
          history.push('/home');
          break;
        // TODO to be deleted when customized routes are implemented
        case '/authentication':
        case '/signup':
          history.replace('/home');
          break;
      }
    } else {
      this.authStatus = AuthStatus.SignUpInProgress;
      history.push('/signup'); // TODO to be deleted when customized routes are implemented
    }

    this.loadInitialData(false);
    // Redirect to MainPage

    // same logic as to that of mobile
    this.sendQueuedPackets();
  };

  public sendMessageToServer = (data: any) => {
    if (!this.webSocket || this.webSocket.readyState !== 1) {
      // this.addPacketToQueue(data);
      this.closeWebSocket();
      // this.initWebSocket();
      return;
    }
    if (!navigator.onLine) {
      this.addPacketToQueue(data);
      this.closeWebSocket();
    }
    this.webSocket.send(JSON.stringify(data));
  };

  public loadInitialData = (isSignUp: boolean) => {
    try {
      // Required preload
      // Show as they loading
      this.sendMessageToServer({
        command: WEBSOCKET_COMMAND_TYPE.LOAD_PARTICIPATING_CHATLIST,
        data: { offset: 0 },
      });
      this.sendMessageToServer({
        command: WEBSOCKET_COMMAND_TYPE.LOAD_SCHOOLLIST,
      });
      this.sendMessageToServer({
        command: WEBSOCKET_COMMAND_TYPE.LOAD_MAJORLIST,
      });
      this.sendMessageToServer({
        command: WEBSOCKET_COMMAND_TYPE.GET_SCHOOL_STATS,
      });
      this.sendMessageToServer({
        command: WEBSOCKET_COMMAND_TYPE.GET_TOP_USERS_INFO,
      });
      // avoid to be executed when signup
      if (this.selfUser !== null) {
        this.chatStore.isModalTrue = false;
        console.log('executed "get user info"');
        this.sendMessageToServer({
          command: WEBSOCKET_COMMAND_TYPE.V2_GET_USERINFO,
          data: { targetID: this.selfUser.uid },
        });
      }
      console.log('passed "get user info"');
      // avoid to be executed when signup
      if (this.selfUser !== null) {
        console.log('executed "get user basic info"');
        this.sendMessageToServer({
          command: WEBSOCKET_COMMAND_TYPE.GET_USER_BASICINFO,
          data: { targetID: this.selfUser.uid },
        });
      }
      console.log('passed "get user basic info"');
    } catch (err) {
      console.error(err);
    }
  };

  public signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.sendMessageToServer({ command: WEBSOCKET_COMMAND_TYPE.UPDATE_USERINFO, data: { fcmToken: null } });
        this.clear();
        this.rootStore.chatStore.clear();
      });
  };

  @action
  private clear = () => {
    this.firebaseUser = null;
    this.selfUser = null;

    PubSub.clearAllSubscriptions();
    this.resetInitialLoadChecker();
    this.closeWebSocket();
  };

  private resetInitialLoadChecker = () => {
    // this.initialDataChecker.set(WEBSOCKET_COMMAND_TYPE.LOAD_CHATLIST, false);
    this.initialDataChecker.set(WEBSOCKET_COMMAND_TYPE.LOAD_PARTICIPATING_CHATLIST, false);
    // this.initialDataChecker.set(WEBSOCKET_COMMAND_TYPE.LOAD_EXPLORELIST, false);
    this.initialDataChecker.set(WEBSOCKET_COMMAND_TYPE.LOAD_SCHOOLLIST, false);
    this.initialDataChecker.set(WEBSOCKET_COMMAND_TYPE.LOAD_MAJORLIST, false);
    this.initialDataChecker.set(WEBSOCKET_COMMAND_TYPE.LOAD_POSTCATLIST, false);
    this.initialDataChecker.set(WEBSOCKET_COMMAND_TYPE.LOAD_USERLIST, false);
  };

  public closeWebSocket = () => {
    if (this.webSocket) {
      this.webSocket.close();
      this.isWebSocketConnected = false;
      this.chatStore.connecting = false;
      this.chatStore.isInternetConnected = false;
    }
  };

  @action
  private resCreateUser = (_: string, data: any) => {
    // Just Signed Up
    if (this.rootStore.getUserUID() === data.userInfo.uid) {
      this.selfUser = data.userInfo;
      history.replace('/welcome');
    } else {
      this.cachedUserList[data.userInfo.uid] = data.userInfo;
    }
  };

  public getMajorList = () => {
    return this.majorList;
  };

  public getSchoolList = () => {
    return this.schoolList;
  };

  public getUserData = () => {
    return this.selfUser;
  };

  public getFirebaseUser = () => {
    return this.firebaseUser;
  };

  public getSchoolName = (schoolID: string) => {
    if (typeof this.schoolList[schoolID] !== 'undefined') {
      if (schoolID.startsWith('U') || !schoolID) {
        return 'Others';
      }
      return this.schoolList[schoolID].name;
    }
    return null;
  };

  public getMajorName = (majorID: string) => {
    if (typeof this.majorList[majorID] !== 'undefined') {
      if (majorID.startsWith('U') || !majorID) {
        return 'Others';
      }
      return this.majorList[majorID].name;
    }
    return null;
  };

  public getWebsocketURL = async () => {
    if (this.rootStore.isProduction()) {
      return PRODUCTION_WEBSOCKET_URL;
    }
    return STAGING_WEBSOCKET_URL;
  };

  public getApiURL = (): string => {
    if (this.rootStore.isProduction()) {
      return PRODUCTION_API_URL;
    }
    return STAGING_API_URL;
  };

  private getPublicIp = async () => {
    try {
      const response = await fetch('https://api.ipify.org');
      const ip = response.text();
      return ip;
    } catch (e) {
      throw 'Unable to get IP address.';
    }
  };

  public getUserIDToken = async () => {
    const firebaseUser = this.getFirebaseUser();
    if (!firebaseUser) {
      return '';
    }
    return await firebaseUser.getIdToken();
  };

  public getUserInfoByUID = (uid: any) => {
    return {
      firstName: 'dummy',
      lastName: 'uncle',
      majorID: 'major::hC8loa3Icw3HcNSShWmz',
      schoolID: 'school::PiJJnm0mTtBfJs6ffYUf',
      stats: { communication: 0, influential: 0, leadership: 0, social: 0, collab: 0 },
      thumbnailURL:
        'https://firebasestorage.googleapis.com/v0/b/base-6d44c.appspot.com/o/users%2Fuser%3A%3AmXplnIhkFubuoJat668VPKNC2M02%2Fprofilepic_1585285179594.jpg?alt=media&token=0652dce0-b597-4801-87f2-b26a042891ce',
      uid: 'user::mXplnIhkFubuoJat668VPKNC2M02',
    };
  };

  @action
  public changeAvatar = async (mediaObj: any) => {
    try {
      this.selfUserThumbnailURL = '';
      const imgExtension = 'jpg';
      const uploadedAt = new Date().getTime();
      const fileName = `profilepic_${uploadedAt}.${imgExtension}`;
      const fileRef = storage.ref(`users/${this.rootStore.getUserUID()}/${fileName}`);

      const compressionSmall = {
        quality: 0.7,
        maxWidth: 400,
        maxHeight: 400,
        mimeType: 'image/jpeg',
      };

      this.ImageCompressor.compress(mediaObj, compressionSmall).then((response: any) => {
        fileRef.put(response).then((image: any) => {
          image.ref.getDownloadURL().then(
            action((url: string) => {
              this.sendMessageToServer({
                command: WEBSOCKET_COMMAND_TYPE.UPDATE_USERINFO,
                data: { thumbnailURL: url },
              });
              this.selfUserThumbnailURL = url;
            }),
          );
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Identical logic to that of mobile
  private addPacketToQueue = (data: object) => {
    this.packetQueue.push(data);
  };

  // Identical logic to that of mobile
  private sendQueuedPackets = () => {
    while (this.packetQueue.length > 0) {
      this.sendMessageToServer(this.packetQueue.shift());
    }
  };

  // Search Users
  public resV2StudentListSearch = (command: any, data: any) => {
    this.searchedUsers = data;
  };

  public getSearchedUsersList = () => {
    return this.searchedUsers;
  };

  public setSearchedUsersList = (searchedUsers: Array<any>) => {
    this.searchedUsers = searchedUsers;
  };

  @action
  public loadMajorListWithKeyword = (command: string, data: any) => {
    this.majorListByKeyword = []; // clear previous list
    _.forEach(data.majorList, (major, i) => {
      this.majorListByKeyword[i] = {};
      this.majorListByKeyword[i].value = major.majorID;
      this.majorListByKeyword[i].label = major.majorName;
    });

    // sort in alphabetical order
    this.majorListByKeyword = toJS(this.majorListByKeyword)
      .slice()
      .sort((a: any, b: any) => {
        return a.label.localeCompare(b.label);
      });
  };

  public resetMajorListByKeyword = (initArray: Array<any>) => {
    this.majorListByKeyword = initArray;
  };

  @action
  public loadSchoolListWithKeyword = (command: string, data: any) => {
    this.schoolListByKeyword = [];
    _.forEach(data.schoolList, (school, i) => {
      this.schoolListByKeyword[i] = {};
      this.schoolListByKeyword[i].value = school.schoolID;
      this.schoolListByKeyword[i].label = school.schoolName;
    });

    // sort in alphabetical order
    this.schoolListByKeyword = toJS(this.schoolListByKeyword)
      .slice()
      .sort((a: any, b: any) => {
        return a.label.localeCompare(b.label);
      });
  };

  public resetSchoolListByKeyword = (initArray: Array<any>) => {
    this.schoolListByKeyword = initArray;
  };

  @action
  setIsEmailSignUp = (isEmailSignUp: boolean) => {
    this.isEmailSignUp = isEmailSignUp;
  };

  @action
  public resUpdateUserInfo = (_: any, data: any) => {
    this.selfUser = data.userInfo;
  };
}
