/* @flow */
import { observable, action, toJS, computed } from 'mobx';
import _ from 'lodash';
import { element } from 'prop-types';
import { async } from 'q';
import { resolve } from 'dns';
import { on } from 'cluster';
import ImageCompressor from 'image-compressor.js';
import firebase from '../firebase/firebase';
import User from '../utils/User';
import {
  ChatListType,
  MEMBER_STATUS,
  MEMBER_LEVEL,
  WEBSOCKET_COMMAND_TYPE,
  ERROR_LOG,
  ROOMTYPE,
  CURRENT_RELEASE_MODE,
} from '../utils/Constants';
import RootStore from './RootStore';
import { history } from '../BaseRouter';
import RankingChart from '../components/chat/RankingChart';
import LandingPage from '../components/landing/LandingPage';

const storage = firebase.storage();

export default class ChatStore {
  private WWW_URL_PATTERN = /^www\./i;

  private HTTP_URL_PATTERN = /^http/i;

  private HTTPS_URL_PATTERN = /^https/i;

  private BASE64_PATTERN = /^data:/i;

  private messagePerPage = 15;

  private ImageCompressor = new ImageCompressor();

  @observable loadedAllExploreChatRooms = false;

  @observable loadedAllFollowingChatRooms = false;

  @observable loadedAllChatRooms = false;

  @observable loadedAllChatRoomsSearched = false;

  @observable isMyChatListSeachedLoading = false;

  @observable isMyChatListLoading = false;

  @observable isExploreChatListLoading = false;

  @observable isFollowingChatListLoading = false;

  @observable currRoomID = '';

  @observable currChannelID: string | undefined = '';

  @observable currChatListType: ChatListType = ChatListType.Participating;

  @observable chatMessages: any = {};

  @observable chatMembers: any = new Map(); // manage members of chats separately to avoid unnecesary processing

  @observable myChatList: any = new Map();

  @observable followingChatList: any = new Map();

  @observable exploreChatList: any = new Map();

  @observable openChannelID = '';

  @observable public starredMessageList: any = {};

  @observable public arrOfSharedLinks: any[] = [];

  @observable public arrOfMediaSent: any[] = [];

  @observable public arrOfPost: any[] = [];

  @observable public firstFourMedia: any[] = [];

  @observable public elementOfPostArr: any[] = [];

  @observable public elementOfMediaArr: any[] = [];

  @observable public elementOfDocArr: any[] = [];

  @observable public replyMessageList: any[] = [];

  @observable public activityArr: any = [];

  @observable public resLoading = false;

  @observable public isInternetConnected = true;

  @observable public connecting = false;

  @observable public mounted = false;

  @observable public inactive = false;

  @observable public maxAttemptReached = false;

  @observable public maxTimerRefreshed = false;

  @observable public isChatReachTop = false;

  @observable public mentionedMessageList: Object[] = [];

  @observable public isEarlierChatLoading = true;

  @observable userModalData: User | null | undefined | any = {};

  @observable showUserModal = false;

  @observable showDMForm = false;

  @observable dmFromUserprofile = false;

  @observable dmRoomID = '';

  @observable dmChannel: any = {};

  @observable dmTarget: string | null | undefined = '';

  @observable public prevChatRoomID = '';

  @observable selectedMessage: any = {};

  @observable userInfo: User | null | undefined | any = {};

  @observable participantsForMention: User[] = [];

  @observable public chatRoomThumbnailURL: any = '';

  @observable public moreChats: any = {};

  @observable public chatTopics: any = new Map();

  @observable public onClickOption: any = [];

  @observable public generalChannel: any = '';

  public searchListBoolean = false;

  public top15Users: any = [];

  @observable isUsersStats = false;

  @observable isUserData = false;

  @observable firstChatLoaded: any = {};

  @observable ChatListLoad = false;

  @observable showChatLandingPage = false;

  @observable chatMembersSearch: any = new Map();

  @observable isProfile: any = false;

  @observable isModalTrue = false;

  @observable isOpenProfile = false;

  @observable chatScroll = false;

  @observable myChats = false;

  @observable following = false;

  @observable explore = false;

  dmChatExists = false;

  isInitialChatLoad = true;

  public rootStore: any;

  private authStore: any;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.authStore = rootStore.authStore;
  }

  @action
  public clear = () => {
    this.chatMessages = {};
    this.chatMembers = new Map();
    this.myChatList = new Map();
    this.exploreChatList = new Map();
    this.followingChatList = new Map();
    this.arrOfSharedLinks = [];
    this.arrOfMediaSent = [];
    this.arrOfPost = [];
    this.firstFourMedia = [];
    this.elementOfPostArr = [];
    this.elementOfMediaArr = [];
    this.elementOfDocArr = [];
    this.activityArr = [];
    this.chatTopics = new Map();
    this.chatMembersSearch = new Map();
  };

  public clearMedia = () => {
    this.elementOfMediaArr = [];
  };

  public clearSearchChats = () => {
    this.chatTopics = new Map();
    this.chatMembersSearch = new Map();
  };

  public sortMediaData = (originalData: any) => {
    const arrangedMedia = toJS(originalData);
    arrangedMedia.sort((a: any, b: any) => {
      const keyA = new Date(a.createdAt);
      const keyB = new Date(b.createdAt);
      if (keyA < keyB) {
        return 1;
      }
      if (keyA > keyB) {
        return -1;
      }
      return 0;
    });
    return arrangedMedia;
  };

  @action
  onClickChatRoom = (roomID: string, channelID: string, roomType: string) => {
    this.openChatRoom(roomID, channelID);
    if (this.currChatListType === ChatListType.Participating && roomType === ROOMTYPE.GROUP) {
      this.getParticipantsForMention(roomID);
    }
    if (channelID === '') {
      this.showChatLandingPage = true;
    } else {
      this.showChatLandingPage = false;
      this.loadLatestChatMessages(roomID, channelID);
    }
  };

  @action
  openChatRoom = (roomID: any, channelID: any) => {
    if (this.currRoomID !== roomID) {
      this.rootStore.sidebarStore.closeSidebar();
    }
    this.participantsForMention = [];
    this.resetChatMedia();
    this.resetChatDoc();
    this.currRoomID = roomID;
    this.currChannelID = channelID;
  };

  @action
  setSelectedMessage = (message: any) => {
    this.selectedMessage = message;
  };

  public resGetLatestChatContents = (command: string, data: any) => {
    const docs = data.contents;
    let docsArray = Object.keys(docs).map((i) => docs[i]);
    docsArray = this.sortMediaData(docsArray);
    const arrTemp: any[] = [];

    for (let i = 0; i < docsArray.length; i++) {
      if (docsArray[i].type === 'Post') {
        arrTemp.push(docsArray[i]);
        this.arrOfPost = arrTemp;
      } else {
        arrTemp.push(docsArray[i].attachments);
        this.firstFourMedia = arrTemp;
      }
    }
  };

  topUsersInfo = (__: any, data: any) => {
    this.top15Users = data.ranking;
    this.handleRenderData();
  };

  handleRenderData = () => {
    this.isUsersStats = true;
  };

  handleRenderProfile = () => {
    if (!this.isOpenProfile) {
      this.isOpenProfile = true;
    } else {
      this.isOpenProfile = false;
    }
  };

  public resLoadPostList = (_: string, data: any) => {
    const postElement = data.postMap;
    Object.keys(postElement).forEach((key) => {
      this.elementOfPostArr.push(postElement[key]);
    });
    this.elementOfPostArr = this.sortMediaData(this.elementOfPostArr);
  };

  @action
  public loadMoreChatRooms = (offset: any) => {
    this.isMyChatListLoading = true;
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.LOAD_PARTICIPATING_CHATLIST,
      data: { offset },
    });
  };

  resGetMoreChatList = (data: any) => {
    this.moreChats = data;
  };

  @computed
  get sortedMyChatList() {
    const arr = _.orderBy(
      toJS(this.myChatList),
      (item) => {
        const { updatedAt } = item;
        const date = new Date(updatedAt);
        return date.getTime();
      },
      'desc',
    );
    return arr;
  }

  @computed
  get sortedMySearchList() {
    const arr = _.orderBy(
      toJS(this.chatTopics),
      (item) => {
        const { updatedAt } = item;
        const date = new Date(updatedAt);
        return date.getTime();
      },
      'desc',
    );
    return arr;
  }

  @computed
  get sortedExploreChatList() {
    const arr = _.orderBy(
      toJS(this.exploreChatList),
      (item) => {
        const { updatedAt } = item;
        const date = new Date(updatedAt);
        return date.getTime();
      },
      'desc',
    );

    return arr;
  }

  @computed
  get sortedFollowingChatList() {
    if (this.followingChatList) {
      const arr = _.orderBy(
        toJS(this.followingChatList),
        (item) => {
          const { updatedAt } = item;
          const date = new Date(updatedAt);
          return date.getTime();
        },
        'desc',
      );

      return arr;
    }
    return [];
  }

  @computed
  get messagesInCurrentChannel() {
    return this.getChatMessages(this.currRoomID).filter((item: any) => item.channelID === this.currChannelID);
  }

  @action
  public addContentsToChatDetail = (message: { [key: string]: any }) => {
    const { type } = message;
    switch (type) {
      case 'Text':
        this.getUrlsFromText(message.text, this.arrOfSharedLinks);
        break;

      case 'Post':
        this.arrOfPost.push(message);
        break;

      case 'Photo':
      case 'Video':
        if (message.sent) {
          _.each(message.attachments, (attachment) => {
            const attachedMedia = [];
            attachedMedia.push(attachment);
            this.arrOfMediaSent.push(attachedMedia);
          });
        } else {
          _.each(message.attachments, (attachment) => {
            this.arrOfMediaSent.unshift(attachment);
          });
        }
        break;

      default:
        break;
    }
  };

  public isMemberOfRoom = (roomID: string, uid: string) => {
    const members = this.getChatMemberList(roomID);
    if (!members) {
      return false;
    }

    if (_.find(members, (member) => member.uid === uid && member.status === MEMBER_STATUS.MEMBER)) {
      return true;
    }

    return false;
  };

  @action
  public resFollowChat = async (_: string, data: any) => {
    const { roomID, memberObj } = data;
    this.updateChatMember(roomID, memberObj.uid, memberObj);
  };

  @action
  public resUnfollowChat = (_: string, data: any) => {
    const { roomID, memberObj } = data;
    this.updateChatMember(roomID, memberObj.uid, memberObj);
  };

  @action
  private createChatRoomObject = (roomInfo: any, roomID: string, type: string) => {
    if (!roomInfo) {
      return;
    }
    if (roomID.startsWith('ADMIN')) {
      const originRoomID = roomID.slice(6);
      if (this.myChatList.get(originRoomID)) {
        this.myChatList.get(originRoomID).hasAdminChat = true;
      }
    }
    roomInfo.roomID = roomID;
    _.each(roomInfo.members, (memberObj, memberID) => {
      memberObj.uid = memberID;
      if (memberObj.joinedAt) {
        memberObj.joinedAt = new Date(memberObj.joinedAt);
      }

      if (memberObj.lastReadAt) {
        memberObj.lastReadAt = new Date(memberObj.lastReadAt);
      }
    });
    roomInfo.unreadMessageCount = 0;
    roomInfo.createdAt = new Date(roomInfo.createdAt);
    roomInfo.updatedAt = new Date(roomInfo.updatedAt);
    if (!roomInfo.updatedAt) {
      console.log('updatedAt does not exists.');
    }
    const { lastMessage } = roomInfo;
    if (type === ChatListType.Participating && lastMessage) {
      const lastMessageTime = new Date(lastMessage.createdAt);
      roomInfo.lastMessage.createdAt = lastMessageTime;
      const userID = this.authStore.selfUser.uid;
      const selfMemberObj = roomInfo.members[userID];
      if (!selfMemberObj) {
        roomInfo.unreadMessageCount = 0;
      } else {
        let hasUnreadMsgInRoom = false;
        _.each(roomInfo.channels, (item: any, channelID: string) => {
          const hasUnreadMessage = this.hasUnreadMessageOfChannel(
            item.lastChatMsg,
            selfMemberObj.lastReadTimes,
            channelID,
          );
          roomInfo.channels[channelID].unreadMessageCount = hasUnreadMessage ? 1 : 0;
          if (!hasUnreadMsgInRoom && hasUnreadMessage) {
            hasUnreadMsgInRoom = true;
          }
        });
        if (hasUnreadMsgInRoom) {
          roomInfo.unreadMessageCount = 1;
        } else {
          roomInfo.unreadMessageCount = 0;
        }
      }
    }
    this.setChatMemberList(roomID, roomInfo.members);

    // remove members from roomInfo that is unnecessary
    delete roomInfo.members;
    switch (type) {
      case ChatListType.Explore:
        this.exploreChatList.set(roomID, roomInfo);
        break;
      case ChatListType.Participating:
        this.myChatList.set(roomID, roomInfo); // sets chatList objects into array
        break;
      case ChatListType.Search:
        this.chatTopics.set(roomID, roomInfo);
        break;
      case ChatListType.Following:
        this.followingChatList.set(roomID, roomInfo);
        break;
    }

    this.firstChatLoaded[roomID] = false;
  };

  private hasUnreadMessageOfChannel = (lastMessage: any, memberLastReadTimes: any, channelID: string) => {
    const userID = this.authStore.selfUser.uid;
    if (!lastMessage || lastMessage.senderID === userID) {
      return false;
    }

    if (lastMessage.senderID !== userID) {
      if (!memberLastReadTimes) {
        return true;
      }
      if (
        !memberLastReadTimes[channelID] ||
        new Date(lastMessage.createdAt) > new Date(memberLastReadTimes[channelID])
      ) {
        return true;
      }
    }

    return false;
  };

  @action
  public createGroupChat = (groupTitle = '', memberList: [string]) => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.V3_CREATE_GROUP_CHAT,
      data: { topic: groupTitle, memberList },
    });
    this.resLoading = false;
  };

  @action
  public resCreateGroupChat = (command: string, data: any) => {
    const { roomID, roomInfo } = data;
    const { channels } = roomInfo;
    let channelID: string | undefined = '';

    this.createChatRoomObject(roomInfo, roomID, ChatListType.Participating);

    this.resLoading = false; // set resLoading to false upon room loading
    if (data.creatorID === this.rootStore.getUserUID()) {
      channelID = _.findKey(channels, (item) => {
        return item.name === 'General';
      });
      this.currChannelID = channelID;
      this.onClickChatRoom(roomID, channelID || '', ROOMTYPE.GROUP);
      this.isEarlierChatLoading = true;
    }
  };

  @action
  public addMembersToGroup = (userIDList: any, roomID: string) => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.INVITE_TOGROUP,
      data: {
        roomID,
        invitedIDList: userIDList,
      },
    });
  };

  public resAddMemberToGroup = (_: string, data: any) => {
    const { roomID, roomInfo } = data;
    this.createChatRoomObject(roomInfo, roomID, ChatListType.Participating);
  };

  public isFollower = (roomID: string, uid: string) => {
    const members = this.getChatMemberList(roomID);
    if (!members) {
      return false;
    }

    return _.find(members, (member) => member.uid === uid && member.status === MEMBER_STATUS.FOLLOWER);
  };

  getCurrentChatRoom = () => {
    switch (this.currChatListType) {
      case ChatListType.Participating:
        let roomInfo = this.myChatList.get(this.currRoomID);
        if (!roomInfo) {
          roomInfo = this.chatTopics.get(this.currRoomID);
        }
        return roomInfo;
      case ChatListType.Explore:
        return this.exploreChatList.get(this.currRoomID);
      case ChatListType.Following:
        return this.followingChatList.get(this.currRoomID);
    }
  };

  public getUserLevel = (roomID: string, uid: string) => {
    const members = this.getChatMemberList(roomID);
    if (!members || !members[uid]) {
      return null;
    }

    const userLevel = members[uid].level;
    return userLevel;
  };

  @action
  public resUpdateReadMessage = (__: string, data: any) => {
    const { roomID, channelID, readTime } = data;
    if (this.chatTopics.size === 0) {
      const roomInfo = this.myChatList.get(roomID);
      if (!roomInfo || !roomInfo.lastMessage) {
        console.warn('resUpdateReadMessage:: roomInfo or lastMessage is not exists');
        return;
      }

      if (!this.myChatList.get(roomID).channels) {
        console.warn('resUpdateReadMessage:: no channel exists');
        return;
      }

      if (!this.myChatList.get(roomID).channels[channelID]) {
        console.warn('resUpdateReadMessage:: no channel exists with id');
        return;
      }
      this.myChatList.get(roomID).channels[channelID].unreadMessageCount = 0;

      const selfMemberObj = this.getChatMember(roomID, this.rootStore.getUserUID());

      if (!selfMemberObj) {
        console.warn('resUpdateReadMessage:: no self member exists');
        return;
      }

      selfMemberObj.lastReadTimes = { ...selfMemberObj.lastReadTimes, [channelID]: readTime };
      this.updateChatMember(roomID, this.rootStore.getUserUID(), selfMemberObj);

      let unreadChannelID: string | undefined = '';
      unreadChannelID = _.findKey(roomInfo.channels, (item) => {
        return item.unreadMessageCount === 1;
      });

      unreadChannelID ? (roomInfo.unreadMessageCount = 1) : (roomInfo.unreadMessageCount = 0);

      this.clearSearchChats();
    }
    if (this.chatTopics.size < 0) {
      const roomInfo = this.chatTopics.get(roomID);
      if (!roomInfo || !roomInfo.lastMessage) {
        console.warn('resUpdateReadMessage:: roomInfo or lastMessage is not exists');
        return;
      }

      if (!this.chatTopics.get(roomID).channels) {
        console.warn('resUpdateReadMessage:: no channel exists');
        return;
      }

      if (!this.chatTopics.get(roomID).channels[channelID]) {
        console.warn('resUpdateReadMessage:: no channel exists with id');
        return;
      }
      this.chatTopics.get(roomID).channels[channelID].unreadMessageCount = 0;

      const selfMemberObj = this.getChatMember(roomID, this.rootStore.getUserUID());

      if (!selfMemberObj) {
        console.warn('resUpdateReadMessage:: no self member exists');
        return;
      }

      selfMemberObj.lastReadTimes = { ...selfMemberObj.lastReadTimes, [channelID]: readTime };
      this.updateChatMember(roomID, this.rootStore.getUserUID(), selfMemberObj);

      let unreadChannelID: string | undefined = '';
      unreadChannelID = _.findKey(roomInfo.channels, (item) => {
        return item.unreadMessageCount === 1;
      });

      unreadChannelID ? (roomInfo.unreadMessageCount = 1) : (roomInfo.unreadMessageCount = 0);
    }
  };

  public getChatMember = (roomID: string, userID: string) => {
    let members = this.chatMembers.get(roomID);
    if (!members) {
      members = this.chatMembersSearch.get(roomID);
    }
    if (members && members[userID]) {
      return members[userID];
    }

    return null;
  };

  @action
  public setCurrentChatRoomID = (roomID: string) => {
    this.currRoomID = roomID;
  };

  public setOpenChannelID = (roomID: string) => {
    this.openChannelID = roomID;
  };

  public getChatMessages(roomID: string) {
    return computed(() => {
      if (this.chatMessages && this.chatMessages[roomID]) {
        const arrMessage = Object.values(this.chatMessages[roomID]);
        const arrSendingMessage = _.remove(arrMessage, (item: any) => !item.createdAt);
        arrMessage.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        if (arrSendingMessage.length > 0) {
          return _.concat(arrSendingMessage, arrMessage);
        }
        return arrMessage;
      }
      return [];
    }).get();
  }

  public getUrlsFromText = (text: any, arrLink: any) => {
    const expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    const matches = text.match(expression);
    if ((text = 'undefined')) {
      null;
    }
    if (matches != null) {
      matches.forEach((matchedLink: any) => {
        this.arrOfSharedLinks.push(matchedLink);
      });
    }
  };

  public getChatMedia(roomID: any) {
    // this.authStore.sendMessageToServer({
    //   command: WEBSOCKET_COMMAND_TYPE.GET_MEDIA,
    //   data: { roomID, limit:15 },
    // });
  }

  @action
  public resGetChatMedia = (_: string, data: any) => {
    const mediaElement = data.mediaMap;
    const size = Object.keys(mediaElement).length;
    const key = Object.keys(mediaElement);
    if (this.elementOfMediaArr.length === 0) {
      for (let i = 0; i < size; i++) {
        this.elementOfMediaArr.push(mediaElement[key[i]]);
      }
      this.elementOfMediaArr = this.sortMediaData(this.elementOfMediaArr);
    }
  };

  @action
  public resetChatMedia = () => {
    this.elementOfMediaArr = [];
  };

  public getChatDoc(roomID: any) {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_DOCMAP,
      data: { roomID, limit: 20 },
    });
  }

  @action
  public resGetChatDoc = (_: string, data: any) => {
    const docElement = data.docMap;
    const size = Object.keys(docElement).length;
    const key = Object.keys(docElement);
    if (this.elementOfDocArr.length === 0) {
      for (let i = 0; i < size; i++) {
        this.elementOfDocArr.push(docElement[key[i]]);
      }
      this.elementOfDocArr = this.sortMediaData(this.elementOfDocArr);
    }
  };

  @action
  public resetChatDoc = () => {
    this.elementOfDocArr = [];
  };

  @action
  public loadLatestChatMessages = (roomID: string, channelID: string) => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.LOAD_CHATMESSAGES,
      data: {
        roomID,
        offset: 0,
        limit: this.messagePerPage,
        channelID,
      },
    });
    this.resLoading = false;
    this.isEarlierChatLoading = true;
  };

  @action
  loadEarlierMessages = (roomID: string) => {
    this.isChatReachTop = true;
    if (this.chatScroll) {
      this.chatScroll = false;
    }
    if (!this.chatMessages[roomID]) {
      return;
    }

    if (this.firstChatLoaded[roomID]) {
      return;
    }

    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.LOAD_CHATMESSAGES,
      data: {
        roomID,
        offset: _.size(toJS(this.chatMessages[roomID])),
        limit: this.messagePerPage,
        channelID: this.currChannelID,
      },
    });
  };

  @action
  resLoadChatMessages = (command: string, data: any) => {
    const channelID: string | undefined = this.currChannelID;
    if (!channelID) {
      // this happens when switching channels fast
      return;
    }
    this.isEarlierChatLoading = false;
    this.resLoading = false;
    try {
      const { roomID, messages } = data;
      if (!messages || messages.length === 0) {
        this.firstChatLoaded[roomID] = true;
        return;
      }

      if (!this.chatMessages[roomID]) {
        this.chatMessages[roomID] = {};
      }

      const loadedMessages: any = {};
      _.each(messages, (message: any) => {
        const messageID = message._id;
        loadedMessages[messageID] = { ...message, sent: true };
      });
      Object.assign(this.chatMessages[roomID], loadedMessages);

      const userID = this.authStore.selfUser.uid;
      const status = this.getChatMember(roomID, userID) && this.getChatMember(roomID, userID).status;

      if (data.messages && data.messages.length > 0 && status === MEMBER_STATUS.MEMBER) {
        const messageIDList = data.messages
          .filter(
            (message: any) =>
              !message.system && message.senderID !== userID && !(message.readTime && message.readTime[userID]),
          )
          .map((message: any) => message._id);

        if (messageIDList.length > 0) {
          this.updateReadMessage(roomID, channelID, messageIDList);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  getUserInChatStats = () => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_ACTIVE_GROUP_USER_RANKING,
      data: { roomID: this.currRoomID },
    });
  };

  @action
  public setChatMemberList = (roomID: string, members: any) => {
    if (!this.searchListBoolean) {
      this.chatMembers.set(roomID, members);
    }
    if (this.searchListBoolean) {
      this.chatMembersSearch.set(roomID, members);
    }
  };

  public getChatMemberList = (roomID: string) => {
    let chatMembers = this.chatMembers.get(roomID);
    if (!chatMembers) {
      chatMembers = this.chatMembersSearch.get(roomID);
    }
    return chatMembers;
  };

  public calculateMessageCountPortion = (members: { [key: string]: any }) => {
    const copyMembers = { ...members };
    const totalMessageCount = this.getTotalMessageCount(members);
    _.each(copyMembers, (member) => {
      const portion = member.messageCount / totalMessageCount;
      member.messageCountPortion = portion;
    });
    return copyMembers;
  };

  public getTotalMessageCount = (members: { [key: string]: any }) => {
    let totalMessageCount = 0;
    _.each(members, (member) => {
      if (!member.messageCount) {
        member.messageCount = 0;
      }
      member.messageCountPortion = 0;

      if ((member.status === MEMBER_STATUS.MEMBER || member.status === MEMBER_STATUS.LEAVER) && member.messageCount) {
        totalMessageCount += member.messageCount;
      }
    });
    return totalMessageCount;
  };

  public isMemberOrFollower = (roomID: string, uid: string) => {
    const members = this.getChatMemberList(roomID);
    if (!members) {
      return false;
    }

    return _.find(
      members,
      (member) =>
        member.uid === uid && (member.status === MEMBER_STATUS.MEMBER || member.status === MEMBER_STATUS.FOLLOWER),
    );
  };

  private isNewMessageOfRoom = (message: any) => {
    const { readTime } = message;
    if (!readTime) {
      return true;
    }

    const userID = this.rootStore.getUserUID();
    const readObj = readTime[userID];

    if (!readObj) {
      return true;
    }

    return false;
  };

  private updateReadMessage = async (roomID: string, channelID: string | undefined, messageIDList: string[]) => {
    try {
      this.authStore.sendMessageToServer({
        command: WEBSOCKET_COMMAND_TYPE.V2_UPDATE_READMSG,
        data: { roomID, channelID, messageIDList },
      });
    } catch (err) {
      console.error(err);
    }
  };

  @action
  public sendChatMessage = (roomID: string, message: any) => {
    const messageID = message._id;
    const messageObj = { [messageID]: message };
    const userID = this.rootStore.getUserUID();
    messageObj[messageID].sent = false;
    messageObj[messageID].bytesTransferred = 0;
    messageObj[messageID].senderID = userID;
    messageObj[messageID].text = message.text;
    messageObj[messageID].postCategoryID = message.postCategoryID;
    messageObj[messageID].system = false;
    messageObj[messageID].isReported = false;
    let roomInfo = this.myChatList.get(roomID);
    if (!roomInfo) {
      roomInfo = this.chatTopics.get(roomID);
    }
    const { channels } = roomInfo;
    if (!channels) {
      alert('Cannot find channel info');
      return;
    }

    if (message.channelID) {
      messageObj[messageID].channelID = message.channelID;
    }

    if (message.replyTo) {
      messageObj[messageID].replyTo = message.replyTo;
    }

    if (message.mentions) {
      messageObj[messageID].mentions = message.mentions;
    }

    if (message.html) {
      messageObj[messageID].html = message.html;
    }

    if (!this.chatMessages[roomID]) {
      this.chatMessages[roomID] = {};
    }

    messageObj[messageID].isFirstMessage = _.size(this.chatMessages[roomID]) === 0;
    this.chatMessages[roomID] = Object.assign(messageObj, this.chatMessages[roomID]);
    this.uploadContents(roomID, messageID);
  };

  public uploadContents = (roomID: string, messageID: string) => {
    const message = this.chatMessages[roomID][messageID];
    const { type } = message;
    switch (type) {
      case 'Text':
      case 'Reply':
        this.sendMessage(roomID, message);
        break;
      case 'Document':
      case 'Photo':
      case 'Video':
        message.attachments = [];
        _.each(message.localAttachments, (item, index: number) => {
          this.uploadMedia(roomID, messageID, message, item, index).then(() => {
            this.sendMessage(roomID, message);
          });
        });
        break;
      case 'Post':
        message.attachments = [];
        this.uploadPost(message, roomID, messageID).then(() => {
          this.sendMessage(roomID, message);
        });
        break;
      default:
        break;
    }
  };

  public uploadPost = (message: { [key: string]: any }, roomID: string, messageID: string) =>
    new Promise((resolve) => {
      const promises: any[] = [];
      message.attachments = [];
      _.each(toJS(message.localAttachments), (item, index: number) => {
        const type = item.attachmentType;
        switch (type) {
          case 'Photo': {
            promises.push(this.uploadMedia(roomID, messageID, message, item, index));
            break;
          }
          case 'Video': {
            promises.push(this.uploadMedia(roomID, messageID, message, item, index));
            break;
          }
          // case 'Document': {
          //     promises.push(this.uploadDocument(roomID, messageID, message, item));
          //     break;
          // }
          default:
            break;
        }
      });
      Promise.all(promises).then(() => {
        resolve(promises);
      });
    });

  public sortedMediaData = (originalData: any) => {
    const arrangedMedia = toJS(originalData);
    arrangedMedia.sort((a: any, b: any) => {
      const keyA = new Date(a.createdAt);
      const keyB = new Date(b.createdAt);
      if (keyA < keyB) {
        return 1;
      }
      if (keyA > keyB) {
        return -1;
      }
      return 0;
    });
    return arrangedMedia;
  };

  @action
  public uploadMedia = (
    roomID: string,
    messageID: string,
    message: { [key: string]: any },
    mediaObj: any,
    index: number,
  ) =>
    new Promise((resolve) => {
      let fileRef: any;
      let fileName: string;
      if (mediaObj.attachmentType === 'Photo') {
        const arr = mediaObj.type.split('/');
        const extension = arr[arr.length - 1];
        fileName = `image_${messageID}_${index}.${extension}`;
        fileRef = storage.ref(`chats/${roomID}/images/originalImages/${fileName}`);
      } else if (mediaObj.attachmentType === 'Video') {
        const arr = mediaObj.type.split('/');
        const extension = arr[arr.length - 1];
        fileName = `video_${messageID}_${index}.${extension}`;
        fileRef = storage.ref(`chats/${roomID}/videos/${fileName}`);
      } else if (mediaObj.attachmentType === 'Document') {
        fileName = `doc_${messageID}_${mediaObj.name}`;
        fileRef = storage.ref(`chats/${roomID}/documents/${fileName}`);
      }

      const optionsLarge = {
        quality: 0.7,
        maxWidth: 1920,
        maxHeight: 1080,
        mimeType: 'image/jpeg',
      };
      const optionsSmall = {
        quality: 0.7,
        maxWidth: 400,
        maxHeight: 400,
        mimeType: 'image/jpeg',
      };

      const uploadTask = fileRef.put(mediaObj);
      const unsubscribe: any = uploadTask.on(
        'state_changed',
        action((snapshot: any) => {
          message.bytesTransferred = <any>(snapshot.bytesTransferred / snapshot.totalBytes).toFixed(2) * 10;
        }),
        (err: any) => {
          console.error(err);
          unsubscribe();
        },
        () => {
          unsubscribe();
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL: string) => {
            if (mediaObj.attachmentType === 'Video') {
              fileRef = storage.ref(`chats/${roomID}/videos/preview_${messageID}.jpeg`);
              message.attachments.push({
                name: fileName,
                url: downloadURL,
                type: mediaObj.attachmentType,
                originalURL: downloadURL,
              });
              resolve(message);
            } else if (mediaObj.attachmentType === 'Photo') {
              this.ImageCompressor.compress(mediaObj, optionsSmall).then((response: any) => {
                response.mimeType = mediaObj.type;
                response.attachment = mediaObj.attachmentType;
                storage
                  .ref(`chats/${roomID}/images/thumbnailImages/${fileName}`)
                  .put(response, { contentType: response.mimeType })
                  .then((thumbnail: any) => {
                    thumbnail.ref.getDownloadURL().then(
                      action((url: string) => {
                        message.attachments.push({
                          name: fileName,
                          url,
                          type: mediaObj.attachmentType,
                          originalURL: downloadURL,
                        });
                        resolve(thumbnail);
                      }),
                    );
                  });
              });
              // for document
            } else if (mediaObj.attachmentType === 'Document') {
              storage
                .ref(`chats/${roomID}/documents/${fileName}`)
                .put(mediaObj, { contentType: mediaObj.type })
                .then((thumbnail: any) => {
                  thumbnail.ref.getDownloadURL().then(
                    action((url: string) => {
                      message.attachments.push({
                        name: fileName,
                        url,
                        type: mediaObj.attachmentType,
                        originalURL: downloadURL,
                      });
                      resolve(thumbnail);
                    }),
                  );
                });
            }
          });
        },
      );
    });

  // Send message to chat room
  public sendMessage = (roomID: string, message: any) => {
    try {
      const dbMessage = this.getDBMessageObject(message);
      const messageID = message._id;
      const newObj = { ...dbMessage };
      // Send
      this.authStore.sendMessageToServer({
        command: WEBSOCKET_COMMAND_TYPE.SEND_MESSAGE,
        data: {
          roomID,
          message: newObj,
          messageID,
        },
      });
    } catch (error) {
      console.error(error);
      const { messageID } = message;
      this.authStore.sendMessageToServer({
        command: WEBSOCKET_COMMAND_TYPE.SEND_ERRORLOG,
        data: {
          activity: ERROR_LOG.SEND_MESSAGE,
          error: error.toString(),
          extraData: {
            roomID,
            messageID,
          },
        },
      });
    }
  };

  public resInvitedToGroup = async (_: any, data: any) => {
    await this.authStore.loadInitialData(false);
  };

  private getDBMessageObject = (message: { [key: string]: any }) => {
    const newMessage: { [key: string]: any } = {};
    newMessage._id = message._id;
    newMessage.senderID = message.senderID;
    newMessage.text = message.text;
    newMessage.type = message.type;
    newMessage.system = message.system;
    newMessage.isReported = message.isReported;
    newMessage.origin = 'Web';
    newMessage.channelID = message.channelID;

    if (message.replyTo) {
      newMessage.replyTo = message.replyTo;
      this.sendMessageIdToServer(message.replyTo);
      newMessage.replyMessage = this.replyMessageList[0];
    }

    if (message.mentions) {
      newMessage.mentions = message.mentions;
    }

    if (message.html) {
      newMessage.html = message.html;
    }

    if (message.type !== 'Text') {
      if (message.attachments) {
        newMessage.attachments = message.attachments;
      }
      if (message.title) {
        newMessage.title = message.title;
      }
      if (message.body) {
        newMessage.body = message.body;
      }
      if (message.postCategoryID) {
        newMessage.postCategoryID = message.postCategoryID;
      }
    }
    return newMessage;
  };

  @action
  public resSendMessage = async (__: string, data: any) => {
    const userID = this.authStore.selfUser.uid;
    const { roomID, message, messageID, clientMessageID } = data;
    if (data.command === WEBSOCKET_COMMAND_TYPE.SEND_TEXT_DM && data.message.origin !== 'Mobile') {
      const { roomInfo, senderThumbnail, receiverThumbnail } = data;
      const isSender = message.senderID === userID;

      roomInfo.thumbnailURL = isSender ? receiverThumbnail : senderThumbnail;
      if (!this.myChatList.has(roomID)) {
        this.createChatRoomObject(roomInfo, roomID, ChatListType.Participating);
      }
      if (isSender) {
        this.rootStore.sidebarStore.closeSidebar();
        this.currChatListType = ChatListType.Participating;

        if (this.dmFromUserprofile) {
          this.dmFromUserprofile = false;
          history.push('/home');
        }
        this.openChatRoom(roomID, Object.keys(roomInfo.channels)[0]);
      }
    }

    if (data.command === WEBSOCKET_COMMAND_TYPE.SEND_MESSAGE && !this.myChatList.has(roomID)) {
      this.loadMyChatList();
    }

    const roomInfo = this.myChatList.get(roomID);

    let channelID: string | undefined = '';
    const channels = _.isEmpty(roomInfo) ? null : roomInfo.channels;
    if (!channels) {
      return;
    }

    channelID = this.currChannelID;

    const memberObj = data.sendMemberObj;
    roomInfo.lastMessage = message;
    roomInfo.updatedAt = message.createdAt;

    const isInRoom = this.currRoomID === roomID;
    const isInChannel = channelID === message.channelID;
    if (!isInRoom || !isInChannel) {
      roomInfo.channels[message.channelID].lastMsgID = messageID;

      if (memberObj && message.senderID !== userID) {
        roomInfo.unreadMessageCount = 1;
        roomInfo.channels[message.channelID].unreadMessageCount = 1;
      }

      return;
    }

    message.sent = true;
    message.sender = { _id: message.senderID, name: data.senderName, avatar: data.senderThumbnail };

    this.addContentsToChatDetail(message);

    // Update sender's member info
    if (memberObj) {
      memberObj.uid = message.senderID;
      this.updateChatMember(roomID, memberObj.uid, memberObj);
    }

    if (!this.chatMessages[roomID]) {
      this.chatMessages[roomID] = {};
    }

    // Remove msg generated using clientMessageID
    delete this.chatMessages[roomID][clientMessageID];

    this.chatMessages[roomID][messageID] = message;

    if (message.senderID !== userID) {
      const messageIDList = message.system ? [] : [messageID];
      this.updateReadMessage(roomID, channelID, messageIDList);
    } else {
      roomInfo.unreadMessageCount = 0;
      roomInfo.channels[message.channelID].unreadMessageCount = 0;
    }
  };

  // to be removed when the function to get chat info including a member list is implemented
  public updateChatMember = (roomID: string, userID: string, member: { [key: string]: any }) => {
    const members = this.getChatMemberList(roomID);
    members[userID] = member;
    this.setChatMemberList(roomID, members);
  };

  @action
  public resStarMessage = async (_: string, data: any) => {
    const roomInfo = this.myChatList.get(this.currRoomID);
    if (!roomInfo) {
      return;
    }
    roomInfo.starredMessages = data.starredMessage;
    this.myChatList.set(this.currRoomID, roomInfo);
  };

  public starMessage = async (roomID: string, messageID: string, senderID: string) => {
    const starredMessages: any = {};
    starredMessages.starredID = messageID;
    this.authStore.sendMessageToServer({ command: WEBSOCKET_COMMAND_TYPE.STAR_MESSAGE, data: { roomID, messageID } });
  };

  public getStarredMsg = (roomID: string) => {
    // Commented by KJ's reqeust
    // this.authStore.sendMessageToServer({ command: WEBSOCKET_COMMAND_TYPE.GET_STARREDMSG, data: { roomID } });
  };

  @action
  public resGetStarredMsg = (command: string, data: any) => {
    this.starredMessageList[data.roomID] = data.starredMessages;
    if (data.error) {
    }
  };

  public hideReportedUserMessage = async () => {
    const { roomID, _id } = this.selectedMessage;
    try {
      this.authStore.sendMessageToServer({
        command: WEBSOCKET_COMMAND_TYPE.REPORT_MESSAGE,
        data: { roomID, messageID: _id },
      });
    } catch (err) {
      console.error(err);
    }
  };

  public loadActivityLog = (roomID: string) => {
    this.authStore.sendMessageToServer({ command: WEBSOCKET_COMMAND_TYPE.GET_ACTIVITYLOG, data: { roomID } });
  };

  public resGetActivityLog = (command: string, data: any) => {
    if (data.activityLog) {
      this.activityArr = data.activityLog;
    }
  };

  @action
  public resReportMessage = async (command: string, data: any) => {
    const roomInfo = this.chatMessages[data.roomID];
    if (!roomInfo) {
    }

    const message = roomInfo[data.messageID];
    if (!message) {
    }

    message.isReported = true;
    this.chatMessages[data.roomID] = { ...this.chatMessages[data.roomID] };
    this.myChatList.get(data.roomID).lastMessage.isReported = true;
  };

  @action
  public resCreateChannel = (_: string, data: any) => {
    const roomInfo = this.myChatList.get(data.roomID);
    if (!roomInfo) {
      return;
    }
    roomInfo.channels = data.channels;
    this.myChatList.set(data.roomID, roomInfo);
  };

  @action
  clearCurrentChatRoom = () => {
    this.currRoomID = '';
    this.currChannelID = '';
  };

  @action
  handleClickExploreChatsTab = () => {
    if (this.currChatListType !== ChatListType.Explore) {
      this.currChatListType = ChatListType.Explore;
      this.explore = true;
      this.following = false;
      this.myChats = false;
      this.loadExploreChatList();
      this.clearCurrentChatRoom();
    }
  };

  @action
  loadExploreChatList = (offset = 0) => {
    this.isExploreChatListLoading = true;
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.LOAD_EXPLORE_CHATLIST,
      data: { offset },
    });
  };

  @action
  resLoadExploreChatList = (__: string, data: any) => {
    this.isExploreChatListLoading = false;
    if (Object.keys(data.chatList).length === 0) {
      this.loadedAllExploreChatRooms = true;
    } else {
      this.loadedAllExploreChatRooms = false;
    }
    _.each(data.chatList, (roomInfo, roomID) => {
      this.createChatRoomObject(roomInfo, roomID, ChatListType.Explore);
    });
  };

  @action
  handleClickMyChatsTab = () => {
    if (this.currChatListType !== ChatListType.Participating) {
      this.currChatListType = ChatListType.Participating;
      this.myChats = true;
      this.following = false;
      this.explore = false;
      this.loadMyChatList();
      this.clearCurrentChatRoom();
    }
  };

  loadMyChatList = () => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.LOAD_PARTICIPATING_CHATLIST,
      data: { offset: 0 },
    });
  };

  @action
  resLoadMyChatList = (__: string, data: any) => {
    this.isMyChatListLoading = false;
    const chatListLength = Object.keys(data.chatList).length;
    if (chatListLength === 0) {
      this.loadedAllChatRooms = true;
    } else {
      this.loadedAllChatRooms = false;
    }

    if (chatListLength < 1 && this.isInitialChatLoad) {
      this.currChatListType = ChatListType.Explore;
      this.explore = true;
      this.loadExploreChatList();
    } else {
      _.each(data.chatList, (roomInfo, roomID) => {
        this.createChatRoomObject(roomInfo, roomID, ChatListType.Participating);
      });
    }
    this.isInitialChatLoad = false;
  };

  @action
  handleClickFollowingChatsTab = () => {
    if (this.currChatListType !== ChatListType.Following) {
      this.currChatListType = ChatListType.Following;
      this.following = true;
      this.myChats = false;
      this.explore = false;
      this.loadFollowingChatList();
      this.clearCurrentChatRoom();
    }
  };

  @action
  loadFollowingChatList = (offset = 0) => {
    this.isFollowingChatListLoading = true;
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.LOAD_FOLLOWING_CHATLIST,
      data: { offset },
    });
  };

  @action
  resLoadFollowingChatList = (__: string, data: any) => {
    this.isFollowingChatListLoading = false;
    if (Object.keys(data.chatList).length === 0) {
      this.loadedAllFollowingChatRooms = true;
    } else {
      this.loadedAllFollowingChatRooms = false;
    }
    _.each(data.chatList, (roomInfo, roomID) => {
      this.createChatRoomObject(roomInfo, roomID, ChatListType.Following);
    });
  };

  public resGetMessageById = (command: string, data: { command: string; message: { bk: {} } | null }) => {
    if (data && data.message) {
      this.mentionedMessageList.push(data.message.bk);
      this.replyMessageList.push(data.message.bk);
    }
    return this.mentionedMessageList;
  };

  public sendMessageIdToServer = (messageID: string) => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_MESSAGE_BY_ID,
      data: { messageID },
    });
  };

  public getMemberStatus = (roomID: string, userID: string) => {
    const member = this.getChatMember(roomID, userID);
    if (!member) {
      return null;
    }

    return member.status;
  };

  public promoteMember = (roomID: string, userID: string, level: string) => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.PROMOTE_MEMBER,
      data: { roomID, userID, level },
    });
  };

  public resPromoteMember = (command: string, data: any) => {
    const memberLevel = data.memberObj.level;
    const members = this.getChatMemberList(data.roomID);
    if (memberLevel === MEMBER_LEVEL.LEADER) {
      members[data.requesterID].level = MEMBER_LEVEL.ADMIN;
    }
    members[data.memberObj.uid].level = memberLevel;
    this.setChatMemberList(data.roomID, members);

    if (data.adminRoomID) {
      const adminArr: any = {};
      _.each(data.adminRoomObj.memberList, (item, key) => {
        const { joinedAt } = item;
        const { level } = item;
        const { status } = item;
        const uid = key;

        adminArr[uid] = {
          joinedAt,
          level,
          status,
          uid,
        };
      });
      this.setChatMemberList(data.adminRoomID, adminArr);
    }
  };

  public downgradeMember = (roomID: string, userID: string) => {
    this.authStore.sendMessageToServer({ command: WEBSOCKET_COMMAND_TYPE.DEMOTE_MEMBER, data: { roomID, userID } });
  };

  public resDemoteMember = (command: string, data: any) => {
    const members = this.getChatMemberList(data.roomID);

    members[data.memberObj.uid].level = MEMBER_LEVEL.NORMAL;
    this.setChatMemberList(data.roomID, members);

    if (data.adminRoomID) {
      const adminArr: any = {};
      _.each(data.adminRoomObj.memberList, (item, key) => {
        const { joinedAt } = item;
        const { level } = item;
        const { status } = item;
        const uid = key;

        adminArr[uid] = {
          joinedAt,
          level,
          status,
          uid,
        };
      });
      this.setChatMemberList(data.adminRoomID, adminArr);
    }
  };

  public exitChatRoom = (roomID: string, leaverID: string) => {
    this.authStore.sendMessageToServer({ command: WEBSOCKET_COMMAND_TYPE.LEAVE_CHATROOM, data: { roomID, leaverID } });
  };

  @action
  public resExitChatRoom = (_: string, data: any) => {
    const { adminRoomID, roomID, leaverObj } = data;
    if (data.adminRoomID) {
      this.updateChatMember(adminRoomID, leaverObj.uid, leaverObj);
    }

    this.updateChatMember(roomID, leaverObj.uid, leaverObj);
  };

  @action
  resGetChatTopic = (__: string, data: any) => {
    this.isMyChatListLoading = false;
    if (Object.keys(data.chatList).length === 0) {
      this.loadedAllChatRoomsSearched = true;
    } else {
      this.loadedAllChatRoomsSearched = false;
    }

    _.each(data.chatList, (roomInfo, roomID) => {
      this.createChatRoomObject(roomInfo, roomID, ChatListType.Search);
    });
  };

  @action
  openUserModal = (userData: any) => {
    this.showUserModal = true;
  };

  getUserModalData = (userID: string) => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_USER_BASICINFO,
      data: { targetID: userID },
    });
  };

  @action
  resGetUserModalData = (_: string, data: any) => {
    this.userModalData = data.userInfo;
    if (this.isModalTrue) {
      this.openUserModal(data.userInfo);
    }
  };

  @action
  closeUserModal = () => {
    this.showUserModal = false;
  };

  @action
  setShowDMForm = (showDMForm: boolean) => {
    this.showDMForm = showDMForm;
  };

  @action
  setDMFromUserProfile = (dmFromUserprofile: boolean) => {
    this.dmFromUserprofile = dmFromUserprofile;
  };

  @action
  resetDMData = () => {
    this.dmRoomID = '';
    this.dmChannel = {};
    this.dmTarget = '';
  };

  public createPersonalChat = (userID: any) => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.V2_PREPARE_PERSONAL_CHATROOM,
      data: { userID: this.rootStore.getUserUID(), targetID: userID },
    });
  };

  @action
  public resCreatePersonalChat = (_: string, data: any) => {
    this.dmTarget = data.targetID;
    this.dmRoomID = data.roomID;
    this.dmChannel = data.roomInfo.channels;
    this.dmChatExists = data.chatExists;
  };

  @action
  sendDM = (dmText: string) => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.SEND_TEXT_DM,
      data: {
        targetID: this.dmTarget,
        roomID: this.dmRoomID,
        channelID: Object.keys(this.dmChannel)[0],
        text: dmText,
        requiresRoomInfo: true,
      },
    });
    this.handleClickMyChatsTab();

    if (this.dmChatExists) {
      this.dmChatExists = false;
      this.loadLatestChatMessages(this.dmRoomID, Object.keys(this.dmChannel)[0]);
    }

    if (this.showChatLandingPage) {
      this.currRoomID = this.dmRoomID;
      this.currChannelID = Object.keys(this.dmChannel)[0];
      this.showChatLandingPage = false;
    }
  };

  @action
  public resUpdateChat = (_: string, data: any) => {
    const newRoomInfo = this.rootStore.mergeObjects(data.roomInfo, this.myChatList.get(data.roomInfo.roomID));
    this.rootStore.mergeObjects(data.roomInfo, this.myChatList.get(data.roomInfo.roomID));
    this.myChatList.set(data.roomInfo.roomID, newRoomInfo);
    this.resLoading = false;
  };

  getUserProfileData = (userID: string) => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.V2_GET_USERINFO,
      data: { targetID: userID },
    });
  };

  @action
  resGetUserProfileData = (_: string, data: any) => {
    this.userInfo = data.userInfo;
    this.ChatListLoad = true;
  };

  resGetTopUsers = (user: any) => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_TOP_USERS_INFO,
    });
  };

  getParticipantsForMention = (roomID: string) => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_CHAT_PARTICIPANTLIST_MENTION,
      data: { roomID },
    });
  };

  @action
  resGetParticipantsForMention = (_: string, data: any) => {
    this.participantsForMention = data.userList;
  };

  @action
  public changeChatImage = async (roomID: String, mediaObj: any) => {
    try {
      const imgExtension = 'jpg';
      const uploadedAt = new Date().getTime();
      const fileName = `chat_profilepic_${uploadedAt}.${imgExtension}`;
      const fileRef = storage.ref(`chats/${roomID}/${fileName}`);

      const compressionSmall = {
        quality: 0.7,
        maxWidth: 400,
        maxHeight: 400,
        mimeType: 'image/jpeg',
      };

      this.ImageCompressor.compress(mediaObj, compressionSmall).then((response: any) => {
        fileRef.put(response).then((image: any) => {
          image.ref.getDownloadURL().then(
            action((url: string) => {
              this.authStore.sendMessageToServer({
                command: WEBSOCKET_COMMAND_TYPE.UPDATE_CHATINFO,
                data: {
                  roomID,
                  roomInfo: {
                    thumbnailURL: url,
                  },
                  action: 'CHANGE_PICTURE',
                },
              });
              this.chatRoomThumbnailURL = url;
            }),
          );
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  @action
  public resUpdateChannelName = (_: string, data: any) => {
    const { channelID, channelName } = data;
    this.myChatList.get(this.currRoomID).channels[channelID].name = channelName;
  };
}
