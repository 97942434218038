export const description: { [key: string]: string } = Object.freeze({
  ///// RESPONSE TIME /////
  // Group//
  'avg_resp_time_of_grp_chat/chat': 'Avg response time of chat',
  'avg_resp_time_of_each_member_grp_chat/chat': 'Avg Response Time by Each Member',
  'avg_resp_time_by_others_grp_chat/chat': 'Avg Response Time by Others',
  'avg_r2r_time_each_member_grp_chat/chat': 'Avg read-to-response time',
  ///// READ TIME /////
  'avg_read_time_of_om_each_member_grp_chat/chat': 'Avg read time of msg requiring response',
  'avg_read_time_per_participant_in_chat_room/chat': 'Avg read time general',
  'avg_read_time_by_others_per_participant_in_chat_room/chat': 'Avg read time by others',
  ///// OM /////
  'num_of_om_each_member_grp_chat/chat': 'Msg Requiring Attention',
  ///// Mentions /////
  'num_of_mentions_in_chat/chat': '# of mentions',
  'num_of_mentions_sent_per_person/chat': '# of mentions per member',
  'num_of_mentions_each_member_received/chat"': '# of mentions received per member',
  ///// Msg /////
  'num_msgs_per_user/chat': 'Msg Per Member',
  'percentage_of_msgs_user/user': '% of Msg Per Member',
  // Not on cal list 'avg_msgs_per_day_in_chat/chat': 'Avg # of msg per day',
  // Not on cal list 'num_message_by_hour/chat': '',
  // Not on cal list 'num_message_send/chat': 'Total Msgs',
  // 'num_message_send/chat'  changed to  'num_total_msg/chat'

  ///// Etc. /////
  // Not on cal list 'num_members/chat': '# of members',
  // Not on cal list 'school_list_chat_participants_are_from/chat': '',

  ///// Doesn't Exist /////
  /*
    'avg_responsetime_oneonone_chatroom': 'Avg response time',  
    'avg_readtime/chat': 'Avg read time',
    'avg_readtime_by_others/chat': 'Avg read time by others',
    'avg_readtime_per_participant/chat': 'Avg read time per member',    
    'num_message_by_percentage/chat': '',   
    'busiest_hour/chat': '',     

    */

  ///// Need Audit /////
  //    'num_emojis/chat': '# of emojis',
  //    'num_emojis_per_participant/chat': '# of emojis per member',
  //    'num_unique_emojis/chat': '# of unique emojis',
  //    'num_unique_emojis_per_person/chat': '# of unique emojis per member',
  //    'avg_emojis_per_message/chat': 'Avg # of emojis per message',
  //    'avg_emojis_per_message_per_person/chat': 'Avg # of emojis',
});
