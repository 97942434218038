import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import QuotedMessage from './QuotedMessage';

interface Props {
  messageId: string;
  open: boolean;
  close: () => void;
}

class ReplyMessageWrapper extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="d-flex align-items-center p-2 bgGray-2 replyMsgWrapper">
        <span style={{ flexGrow: 1 }}>
          <QuotedMessage messageId={this.props.messageId} />
        </span>
        <span onClick={() => this.props.close()} className="mx-3 text-white">
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  }
}

export default ReplyMessageWrapper;
