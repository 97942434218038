import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Image, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import _, { isUndefined, debounce } from 'lodash';
import { action, toJS } from 'mobx';
import './ChatRoomList.tsx';

import { blue } from '@material-ui/core/colors';
import { WEBSOCKET_COMMAND_TYPE, GROUPTCHAT_THUMBNAIL_LOGO_URL, ChatListType } from '../../utils/Constants';

interface State {
  isOpenChannel: boolean;
  showModal: boolean;
  text: string;
  inputText: string;
  searchListRender: boolean;
  loadRanking: boolean;
  closeChatRoom: boolean;
  myChatSearchInputText: string;
  searchedChats: any;
  showDropdown: boolean;
}

interface Props {
  rootStore?: any;
  onCreateChannelBtn: any;
  openChatPage: any;
}

type ChatRoom = {
  topic?: string;
  roomID: string;
  channels: any;
  lastMessage?: any;
  roomType: any;
  thumbnailURL?: string;
  unreadMessageCount: number;
};

@inject('rootStore')
@observer
class ChatList extends React.Component<Props, State> {
  private rootStore: any;

  private chatStore: any;

  private authStore: any;

  private channels: any = {};

  private hideLoadChats = false;

  public optionsArr: any = [];

  public itemRender: any = [];

  public globalImage: any = [];

  public globalTopic = '';

  public chatSearchHold: any = [];

  constructor(props: any) {
    super(props);
    this.state = {
      isOpenChannel: false,
      text: '',
      showModal: false,
      inputText: '',
      searchListRender: false,
      loadRanking: false,
      closeChatRoom: false,
      myChatSearchInputText: '',
      searchedChats: [],
      showDropdown: false,
    };
    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
  }

  @action
  componentDidMount(): void {
    this.chatStore.resLoading = true;
    this.renderDropdownButton = this.renderDropdownButton.bind(this);
    this.getSelectedChatCategory = this.getSelectedChatCategory.bind(this);
    this.renderChatRoom = this.renderChatRoom.bind(this);
    this.renderChatList = this.renderChatList.bind(this);
    this.renderSearch = this.renderSearch.bind(this);
    this.renderChannels = this.renderChannels.bind(this);
    this.createChannel = this.createChannel.bind(this);
    this.modalHide = this.modalHide.bind(this);
    this.searchTopic = this.searchTopic.bind(this);
    this.searchChatDelayed = this.searchChatDelayed.bind(this);
    this.loadMoreMyChats = this.loadMoreMyChats.bind(this);
    this.onChatScroll = this.onChatScroll.bind(this);
    this.loadMoreChatsSearched = this.loadMoreChatsSearched.bind(this);
    this.loadMoreExploreChats = this.loadMoreExploreChats.bind(this);
    this.loadChatsByScreenHeight = this.loadChatsByScreenHeight.bind(this);

    window.addEventListener('resize', () => {
      const windowHeight = window.innerHeight;

      const multiplier = Math.floor(windowHeight / 1100);
    });
  }

  @action
  componentDidUpdate(): void {
    this.chatStore.resLoading = false;
    this.chatStore.searchListBoolean = this.state.searchListRender;
  }

  loadChatsByScreenHeight() {
    const extraToLoad = 15 * (Math.floor(window.innerHeight / 1000) + 1);
    if (this.chatStore.currChatListType === ChatListType.Participating) {
      if (
        this.chatStore.myChatList.size < extraToLoad &&
        !this.chatStore.loadedAllChatRooms &&
        !this.chatStore.isMyChatListLoading
      ) {
        console.log('loading...');
        this.chatStore.loadMoreChatRooms(this.chatStore.myChatList.size);
      } else {
        console.log('no need...');
      }
    } else if (this.chatStore.currChatListType === ChatListType.Explore) {
      if (
        this.chatStore.exploreChatList.size < extraToLoad &&
        !this.chatStore.loadedAllExploreChatRooms &&
        !this.chatStore.isExploreChatListLoading
      ) {
        console.log('loading...');
        this.chatStore.loadExploreChatList(this.chatStore.exploreChatList.size);
      } else {
        console.log('no need... explore');
      }
    }
  }

  loadMoreMyChats(): void {
    if (this.chatStore.myChatList.size > 14) {
      this.chatStore.loadMoreChatRooms(this.chatStore.myChatList.size);
    }
  }

  loadMoreChatsSearched(): void {
    if (this.chatStore.chatTopics.size > 14) {
      const { myChatSearchInputText } = this.state;
      const user = this.rootStore.getUserUID();
      const mySearchList = this.chatStore.sortedMySearchList;
      this.chatStore.isMyChatListLoading = true;

      this.authStore.sendMessageToServer({
        command: WEBSOCKET_COMMAND_TYPE.SEARCH_CHAT_LIST,
        data: {
          topic: myChatSearchInputText,
          userID: user,
          offset: mySearchList.length,
        },
      });
    }
  }

  loadMoreExploreChats(): void {
    if (this.chatStore.exploreChatList.size > 14) {
      this.chatStore.loadExploreChatList(this.chatStore.exploreChatList.size);
    }
  }

  onChatScroll(e: any): void {
    const { myChatSearchInputText } = this.state;
    const isBottom = Math.round(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
    if (isBottom) {
      if (this.chatStore.currChatListType === ChatListType.Participating) {
        if (myChatSearchInputText.length > 1) {
          if (!this.chatStore.loadedAllChatRoomsSearched) {
            this.loadMoreChatsSearched();
          }
        } else if (!this.chatStore.loadedAllChatRooms) {
          this.loadMoreMyChats();
        }
      } else if (this.chatStore.currChatListType === ChatListType.Explore) {
        this.loadMoreExploreChats();
      }
    }
  }

  searchTopic(newValue: string): void {
    const inputValue = newValue.replace(/\W/g, '');
    inputValue.trim();
    this.searchChatDelayed(inputValue);
  }

  searchChatDelayed = debounce((newValue) => {
    const user = this.rootStore.getUserUID();
    if (newValue.length >= 2) {
      this.authStore.sendMessageToServer({
        command: WEBSOCKET_COMMAND_TYPE.SEARCH_CHAT_LIST,
        data: {
          topic: newValue,
          userID: user,
          offset: 0,
        },
      });
    }
    this.chatStore.clearSearchChats();
    this.chatStore.loadedAllChatRoomsSearched = false;
  }, 300);

  modalHide(): void {
    this.setState({ showModal: false, text: '' });
  }

  getSelectedChatCategory(): string {
    let selected = 'Chats';
    if (this.chatStore.explore) {
      selected = 'Explore';
    } else if (this.chatStore.myChats) {
      selected = 'Chats';
    } else if (this.chatStore.following) {
      selected = 'Following';
    }

    return selected;
  }

  createChannel() {
    const existedChannel = _.find(this.channels, (item) => {
      return item.name === this.state.text;
    });
    if (!this.state.text) {
      alert('please type something');
    } else if (existedChannel) {
      alert('Chat room has a same name of channel');
    } else {
      this.props.onCreateChannelBtn(this.chatStore.currRoomID, this.state.text);
      this.modalHide();
    }
  }

  getLastMessage(lastMessage: any, channels: any): string {
    let channelName = '';
    let message = '';
    if (lastMessage && lastMessage.text) {
      if (lastMessage.text.length > 10) {
        message = lastMessage.text.substring(0, 18);
        message += '...';
      } else {
        message = lastMessage.text;
      }
    } else {
      message = 'No Message';
    }

    if (lastMessage && channels[lastMessage.channelID] && channels[lastMessage.channelID].name) {
      channelName = channels[lastMessage.channelID].name;
    } else {
      channelName = 'No Channel Name';
    }

    if (_.size(channels) > 1) {
      return `${channelName} - ${message}`;
    }
    return message;
  }

  renderLoadMoreButton(): React.ReactNode {
    return (
      <button
        type="button"
        style={{ outline: 0, border: 'none', padding: '8px 4px' }}
        onClick={() => {
          const { myChatSearchInputText } = this.state;
          switch (this.chatStore.currChatListType) {
            case ChatListType.Participating:
              if (myChatSearchInputText.length > 1) {
                this.loadMoreChatsSearched();
              } else {
                this.loadMoreMyChats();
              }
              break;
            case ChatListType.Explore:
              this.loadMoreExploreChats();
              break;
            case ChatListType.Following:
              break;
            default:
              break;
          }
        }}
      >
        Load More Chats
      </button>
    );
  }

  renderDropdownButton(): React.ReactNode {
    const { showDropdown } = this.state;
    const renderButton = (title: string, onClick: () => void) => {
      return (
        <button
          type="button"
          style={{
            outline: 0,
            border: 0,
            padding: '10px 4px',
            borderBottom: '1px solid #343a40',
            backgroundColor: 'white',
          }}
          onClick={onClick}
        >
          {title}
        </button>
      );
    };
    return (
      <div style={{ position: 'relative', width: '100%', zIndex: 2 }}>
        <button
          type="button"
          style={{
            width: '100%',
            height: '45px',
            background: '#42484e',
            fontWeight: 700,
            color: '#ffffff',
            boxShadow: 'none',
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            outline: 0,
            border: 0,
          }}
          onClick={(e: any) => {
            e.preventDefault();
            this.setState({ showDropdown: !showDropdown });
          }}
        >
          {this.getSelectedChatCategory()}{' '}
          <FontAwesomeIcon style={{ fontSize: '20px', marginLeft: '12px' }} icon={faAngleDown} />
        </button>
        {showDropdown && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              top: '50px',
              left: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {renderButton('Chats', () => {
              this.chatStore.handleClickMyChatsTab();
              this.chatStore.myChats = true;
              this.setState({ showDropdown: false });
            })}
            {renderButton('Explore', () => {
              this.chatStore.handleClickExploreChatsTab();
              this.setState({ showDropdown: false });
            })}
            {renderButton('Following', () => {
              this.chatStore.handleClickFollowingChatsTab();
              this.setState({ showDropdown: false });
            })}
          </div>
        )}
      </div>
    );
  }

  renderSearch(): React.ReactNode {
    return (
      <form onSubmit={(e) => e.preventDefault()} style={{ width: '100%' }}>
        <input
          type="search"
          placeholder="Search for chats..."
          onChange={(e) => {
            this.setState({ myChatSearchInputText: e.target.value });
            this.searchTopic(e.target.value);
          }}
          style={{
            width: '100%',
            padding: '8px',
            backgroundColor: '#252421',
            outline: 0,
            border: 0,
            color: 'white',
          }}
        />
      </form>
    );
  }

  renderChannelBubble(roomID: string, roomType: any, channel: any, chatRoom: any): React.ReactNode {
    const isMember = this.chatStore.isMemberOfRoom(chatRoom.roomID, this.rootStore.getUserUID());
    return (
      <div
        role="button"
        tabIndex={0}
        key={channel.channelID}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #343a40',
          backgroundColor: this.chatStore.currChannelID === channel.channelID ? '#6c757d' : '#2E302D',
          borderRadius: '12px',
          padding: '10px 4px',
          fontSize: '16px',
          margin: '4px 0px',
          width: '90%',
          zIndex: 1000,
          outline: 0,
          color: '#f4f4f4',
        }}
        onClick={() => {
          this.chatStore.onClickChatRoom(roomID, channel.channelID, roomType);
        }}
      >
        {channel.name}
        &nbsp;
        {chatRoom.unreadMessageCount && channel.unreadMessageCount > 0 && isMember ? (
          <div style={{ width: '14px', height: '14px', backgroundColor: 'white', borderRadius: '14px' }} />
        ) : null}
      </div>
    );
  }

  renderChannels(roomID: string, roomType: any, chatRoom: any) {
    const isMember = this.chatStore.isMemberOfRoom(chatRoom.roomID, this.rootStore.getUserUID());
    const memberLevel = this.chatStore.getUserLevel(chatRoom.roomID, this.rootStore.getUserUID());

    const channels: any[] = [];
    Object.keys(chatRoom.channels).map((channelID: any) => {
      channels.push({ channelID, ...chatRoom.channels[channelID] });
      return channelID;
    });

    channels.sort((a: any, b: any) => {
      const general = 'GENERAL';
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA === general) return -1;
      if (nameB === general) return 1;
      return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
    });
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {channels.map((channel) => this.renderChannelBubble(roomID, roomType, channel, chatRoom))}
      </div>
    );
  }

  renderCreateChannel(): React.ReactNode {
    return (
      <Modal show={this.state.showModal} onHide={this.modalHide}>
        <Modal.Header style={{ justifyContent: 'center' }} closeButton>
          <Modal.Title className="text-center">Create a new channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="h-100 w-100">
            <FormControl
              as="textarea"
              aria-label="With textarea"
              placeholder="Please enter a channel name"
              onChange={(e: any) => this.setState({ text: e.target.value })}
              value={this.state.text}
              style={{ height: '100%', display: 'flex' }}
            />
            <InputGroup.Append>
              <Button onClick={() => this.createChannel()}>
                <FontAwesomeIcon icon={faPlusCircle} style={{ fontSize: '25' }} />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Modal.Body>
      </Modal>
    );
  }

  renderChatRoom(chatRoom: ChatRoom, isMember?: boolean): React.ReactNode {
    const { topic, roomID, channels, lastMessage, roomType, thumbnailURL } = chatRoom;
    const { openChatPage } = this.props;
    const generalChannelObj = Object.entries(channels).find(([key, value]: [string, any]) => value.name === 'General');

    let generalID = '';
    if (generalChannelObj) {
      [generalID] = generalChannelObj;
    }
    return (
      <div
        tabIndex={0}
        role="button"
        key={roomID}
        style={{
          backgroundColor: this.chatStore.currRoomID === chatRoom.roomID ? '#6c757d' : '#2E302D',
          display: 'flex',
          flexDirection: 'column',
          color: 'white',
          borderBottom: '1px solid #989B9E',
          outline: 'none',
        }}
        onClick={() => {
          openChatPage();
          if (_.size(channels) > 1) {
            if (roomID !== this.chatStore.openChannelID) {
              this.chatStore.chatScroll = true;
              this.chatStore.setOpenChannelID(roomID);
              this.chatStore.isOpenProfile = true;
              this.chatStore.onClickChatRoom(roomID, '', roomType);
              this.chatStore.handleRenderProfile();
            }
          } else {
            this.chatStore.chatScroll = true;
            this.chatStore.setOpenChannelID(roomID);
            this.chatStore.isOpenProfile = true;
            this.chatStore.onClickChatRoom(roomID, generalID, roomType);
            this.chatStore.handleRenderProfile();
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '12px',
          }}
        >
          <div>
            <Image
              src={thumbnailURL || GROUPTCHAT_THUMBNAIL_LOGO_URL}
              style={{ height: 45, width: 45, minHeight: 45, minWidth: 45 }}
              roundedCircle
            />
          </div>
          <div
            style={{
              flex: 0.85,
              marginLeft: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '1rem',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {topic || 'No Topic'}
            </div>
            <div style={{ fontSize: '14px', color: '#f4f4f4' }}>{this.getLastMessage(lastMessage, channels)}</div>
          </div>
          {chatRoom.unreadMessageCount && chatRoom.unreadMessageCount > 0 && isMember ? (
            <div style={{ width: '14px', height: '14px', backgroundColor: 'white', borderRadius: '14px' }} />
          ) : null}
        </div>
        {_.size(channels) > 1 &&
          this.chatStore.openChannelID === chatRoom.roomID &&
          this.renderChannels(roomID, roomType, chatRoom)}
      </div>
    );
  }

  renderChatList(chatList: any, isMember: boolean): React.ReactNode {
    const { isMyChatListLoading, isExploreChatListLoading, isFollowingChatListLoading } = this.chatStore;
    return (
      <div
        style={{
          backgroundColor: 'gray',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          overflowY: 'auto',
          height: '0px',
          zIndex: 1,
        }}
        onScroll={this.onChatScroll}
      >
        {chatList.length !== 0 ? (
          chatList.map((chatRoom: any) => this.renderChatRoom({ ...chatRoom }, isMember))
        ) : (
          <div
            style={{
              color: 'white',
              textAlign: 'center',
              fontSize: '1rem',
              fontWeight: 'bold',
              padding: '8px',
            }}
          >
            No Result
          </div>
        )}
        {isMyChatListLoading || isExploreChatListLoading || isFollowingChatListLoading
          ? this.renderLoadMoreButton()
          : null}
        {(isMyChatListLoading || isExploreChatListLoading || isFollowingChatListLoading) && (
          <div style={{ padding: '12px', color: 'white', fontSize: '1rem', textAlign: 'center' }}>Loading...</div>
        )}
      </div>
    );
  }

  render(): React.ReactNode {
    const searchList = this.chatStore.sortedMySearchList;
    const chatList = this.chatStore.sortedMyChatList;
    const exploreChatList = this.chatStore.sortedExploreChatList;
    const followingChatList = this.chatStore.sortedFollowingChatList;
    const { myChatSearchInputText } = this.state;

    return (
      <>
        <div style={{ backgroundColor: 'red', flex: 1, display: 'flex', flexDirection: 'column' }}>
          {this.renderDropdownButton()}
          {this.chatStore.currChatListType === ChatListType.Participating && this.renderSearch()}
          {this.chatStore.currChatListType === ChatListType.Explore && this.renderChatList(exploreChatList, true)}
          {this.chatStore.currChatListType === ChatListType.Participating &&
            this.renderChatList(myChatSearchInputText.length > 1 ? searchList : chatList, true)}
          {this.chatStore.currChatListType === ChatListType.Following && this.renderChatList(followingChatList, true)}
        </div>
        {this.renderCreateChannel()}
      </>
    );
  }
}
export default ChatList;
