import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PRODUCTION_BASELOGO_URL } from '../../../utils/Constants';
import User from '../../../utils/User';
import './style/TagPanel.scss';

interface Props {
  rootStore?: any;
  memberList: User[];
  panelClose: () => void;
  taggedName: (value: User) => void;
  mentionKeyword: string;
}

const primaryColor = '#5D7F9D';
@inject('rootStore')
@observer
export class TagPanel extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleTagged = (userInfo: User) => {
    this.props.taggedName(userInfo);
    this.props.panelClose();
  };

  renderTagPanel = () => {
    const tagPanel = [];
    const userList = this.props.memberList;
    const mentionKey = this.props.mentionKeyword;
    const mentionReg = new RegExp(mentionKey, 'gi');
    for (let i = 0; i < userList.length; i++) {
      let FTaggedName = '';
      let FNormalName = '';
      let LTaggedName = '';
      let LNormalName = '';
      const firstName = userList[i].firstName;
      const lastName = userList[i].lastName;
      // const fullName = `${userList[i].firstName} ${userList[i].lastName}`;

      if (mentionKey.length > 1) {
        if (firstName[0].toLowerCase() === mentionKey[0].toLowerCase() && firstName.match(mentionReg)) {
          for (let j = 0; j < firstName.length; j++) {
            if (mentionKey.length > j) {
              FTaggedName += firstName[j];
              // }
            } else {
              FNormalName += firstName[j];
            }
          }
        } else {
          FNormalName = firstName;
        }
      } else {
        FNormalName = firstName;
      }

      if (mentionKey.length > 1 && lastName) {
        if (lastName[0].toLowerCase() === mentionKey[0].toLowerCase() && lastName.match(mentionReg)) {
          for (let j = 0; j < lastName.length; j++) {
            if (mentionKey.length > j) {
              LTaggedName += lastName[j];
              // }
            } else {
              LNormalName += lastName[j];
            }
          }
        } else {
          LNormalName = lastName;
        }
      } else {
        LNormalName = lastName;
      }

      tagPanel.push(
        <li key={`tag${i}`} onClick={() => this.handleTagged(userList[i])}>
          <img src={userList[i].userThumbnailURL || PRODUCTION_BASELOGO_URL} />
          <span className="user-name">
            <text style={{ color: primaryColor, fontWeight: 'bold' }}>{FTaggedName}</text>
            <text>{FNormalName}&nbsp;</text>
            <text style={{ color: primaryColor, fontWeight: 'bold' }}>{LTaggedName}</text>
            <text>{LNormalName}</text>
          </span>
        </li>,
      );
    }

    return tagPanel;
  };

  render() {
    return <ul className="tag-panel-wrapper bgGray-2">{this.renderTagPanel()}</ul>;
  }
}

export default TagPanel;
