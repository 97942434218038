import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Jumbotron, Image, Spinner } from 'react-bootstrap';
import { PRODUCTION_BASELOGO_URL } from '../../../utils/Constants';
import './SignUpSuccessPage.scss';

interface Props {
  rootStore: any;
  history: any;
}

interface State {
  buttonText: any;
  timeCount: number;
}

@inject('rootStore')
@observer
class SignUpSuccessPage extends Component<Props> {
  public state: State;
  constructor(props: any) {
    super(props);
    this.state = {
      buttonText: 'NEXT',
      timeCount: 6,
    };
  }
  render() {
    let timeCounter = 5;

    const finishSignup = () => {
      this.setState({ buttonText: <Spinner animation="grow" variant="light" size="sm" /> });

      setTimeout(() => {
        this.props.rootStore.authStore.load();
        // *** bug patch for TP-514, but need to fix in better way ***
        // this.props.history.push('/');
        window.location.href = '/'; //
      }, 7000);

      for (let i = 0; i <= 5; i++) {
        countingTime(i);
      }
    };

    const countingTime = (i: number) => {
      setTimeout(() => {
        console.log(this.state.timeCount);
        this.setState({ timeCount: timeCounter-- });
      }, 1300 * i);
    };
    return (
      <div className="sign-up-success-page d-flex align-items-center col-12 container-fluid bgGray-1">
        <Jumbotron className="col-12 col-md-8 col-lg-6 m-auto p-4 rounded">
          <div className="text-center mb-4">
            <Image className="col-3 col-md-2 mb-2" src={PRODUCTION_BASELOGO_URL} roundedCircle />
            <h2 className="sign-up-success-page__title">Welcome to BASE!</h2>
          </div>
          <h5 className="sign-up-success-page__text text-center mb-1">BASE is an open communication app.</h5>
          <h5 className="sign-up-success-page__text text-center mb-4">
            Please do not share any sensitive data (password, credit card info, etc.)
          </h5>
          <Button
            className="float-right"
            onClick={() => {
              finishSignup();
            }}
          >
            {this.state.timeCount !== 6 ? `Count: ${this.state.timeCount}` : ''}
            {this.state.buttonText}
          </Button>
        </Jumbotron>
      </div>
    );
  }
}

export default SignUpSuccessPage;
