import React, { Component } from 'react';
import { Button, Container, Row, Col, Image, Tab, Tabs } from 'react-bootstrap';
import _ from 'lodash';
import { PRODUCTION_BASELOGO_URL } from '../../utils/Constants';

interface Props {}
interface State {
  image: string;
  colorChange: string;
}

const primaryColor = '#5D7F9D';
class PostDetail extends React.Component<Props, State> {
  public state: State;
  constructor(props: any) {
    super(props);
    this.state = {
      image: PRODUCTION_BASELOGO_URL,
      colorChange: 'black',
    };
  }

  renderPosts = () => {
    const PostDetail: any = [];
    let counter = 0;
    _.each(postInfo, (value) => {
      counter++;
      PostDetail.push(
        <Container key={counter}>
          <Row>
            <Row>
              <Col key={'postInfo' + value}></Col>
              <Container>
                <button
                  className="col-12 text-truncate d-flex flex-column container-fluid"
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: '370px',
                    marginLeft: -13,
                    display: 'block',
                    background: 'none',
                    padding: '25px',
                    border: 0,
                  }}
                >
                  <div
                    style={{
                      textAlign: 'left',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      display: 'block',
                      color: '#B4AEA4',
                      width: '300px',
                    }}
                  >
                    {value.Post}
                    <div style={{ position: 'absolute' }}>{value.userName}</div>
                  </div>
                </button>
              </Container>
            </Row>
            <Row style={{ marginTop: '20px', borderBottom: '1px solid #44494C', width: '99%', margin: 'auto' }}></Row>
          </Row>
        </Container>,
      );
    });
    return PostDetail;
  };

  render() {
    return (
      <Container>
        <div
          id="PostHeader"
          className="col-12 d-flex flex-column container-fluid"
          style={{ backgroundColor: '#292B2C', margin: 'auto', marginRight: 30, borderRadius: 5 }}
        >
          <div style={{}}></div>

          <h1
            style={{
              fontWeight: 'bold',
              fontSizeAdjust: 5,
              fontFamily: 'Arial, Helvetica, sans-serif',
              color: '#5d7f9c',
              fontSize: 25,
              margin: 'auto',
              marginTop: 20,
            }}
          >
            Post List
          </h1>
        </div>

        <div
          id="postDetail"
          className="col-12 d-flex flex-column container-fluid"
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            height: '100vh',
            display: 'block',
            overflowX: 'scroll',
            margin: 'auto',
            borderWidth: 2,
            borderRadius: 5,
            backgroundColor: '#292B2C',
          }}
        >
          {this.renderPosts()}
        </div>
      </Container>
    );
  }
}

const otherUser = [
  {
    userName: 'Jon Doe',
  },
];

const postInfo = [
  {
    userName: 'Jim Beam',
    Post:
      'This is a post details, where all the details of this chat post is, as well as any other information that might be crucial to the chat.',
  },
  {
    userName: 'Ron Burgandy',
    Post: 'im not even mad.. im impressed, you ate a whole wheel of cheese. And pooped in the refridgerator.',
  },
  {
    userName: 'Quigon Jin',
    Post: 'Only something to relate',
  },
  {
    userName: 'John C. Reilly',
    Post: 'TESTTEST',
  },
];

export default PostDetail;
