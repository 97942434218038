import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Form, Container } from 'react-bootstrap';
// import { countries } from '../../../stores/data/countryCode.json';
import CodeVerificationModal from '../CodeVerificationModal';

interface Props {
  authStore?: any;
}

const PhoneNumberForm: React.FC<Props> = ({ authStore }: Props) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('+1');
  const [showModal, setShowModal] = useState<boolean>(false);

  const onPhoneNumberUpdate = (e: any) => {
    const tempInput = e.target.value.split('');
    const valid = tempInput.every((text: any) => {
      return Number.isInteger(parseInt(text));
    });
    if (valid) {
      setPhoneNumber(e.target.value);
    }
  };

  // const renderCountryCode = () => {
  //   return countries.map(({ name, code }: { name: string; code: string }, i: number) => {
  //     return (
  //       <option key={i} value={code}>
  //         {name} ( {code} )
  //       </option>
  //     );
  //   });
  // };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    authStore.phoneNumberVerification(countryCode, phoneNumber).then(() => {
      setShowModal(true);
    });
  };

  return (
    <>
      <div className="text-white h-100 p-0 d-flex align-items-center justify-content-center col-12 container-fluid bgGray-1">
        <div className="col-11 col-md-6 m-auto p-0 shadow-lg auth__card bgGray-3">
          <header className="w-100 p-4 h5 text-center bgGray-3 auth__card-header">Log in via Phone</header>
          <div className="w-100 p-4 d-flex flex-column align-items-center justify-content-center">
            <Form className="col-12">
              <Form.Group>
                <div className="row justify-content-center font-weight-bold">
                  <div className="col-5 colorWhite">
                    <Form.Label>Country Code </Form.Label>
                    <Form.Control value={countryCode} onChange={(e) => setCountryCode(e.target.value)} as="select">
                      <option value="+1">Canada / United States ( +1 )</option>
                      <option value="+57">Colombia ( +57 )</option>
                      <option value="+91">India ( +91 )</option>
                      <option value="+52">Mexico ( +52 )</option>
                      {/* {renderCountryCode()} */}
                    </Form.Control>
                  </div>
                  <div className="col-5 colorWhite">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      onChange={onPhoneNumberUpdate}
                      value={phoneNumber}
                      placeholder="Phone Number"
                      type="input"
                      onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                          handleSubmit(e);
                        }
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
              <Container className="mt-4 h-100">
                <Button
                  block
                  className="col-9 mx-auto auth__submit-button"
                  variant="dark"
                  onClick={(e: any) => handleSubmit(e)}
                  disabled={!phoneNumber}
                >
                  Send Verification Code
                </Button>
                <div id="recaptcha-container"></div>
              </Container>
            </Form>
          </div>
        </div>
      </div>
      <CodeVerificationModal
        showModal={showModal}
        setShowModal={setShowModal}
        countryCode={countryCode}
        phoneNumber={phoneNumber}
      />
    </>
  );
};

export default inject(({ rootStore }) => ({ authStore: rootStore.authStore }))(observer(PhoneNumberForm));
