import React from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Modal } from 'react-bootstrap';
import { getInvitationLink } from '../../../utils/invitation_code';

interface Props {
  invitationCode: any;
  invitationCodesList: any;
  onCreateInvitationCode: any;
  onUpdateInvitationCodes: any;
  showModal: boolean;
  onClose: any;
}

const InvitationCodesList: React.FC<Props> = (Props) => {
  const {
    showModal,
    onClose,
    invitationCodesList,
    onCreateInvitationCode,
    invitationCode,
    onUpdateInvitationCodes,
  } = Props;

  React.useEffect(() => {
    setTimeout(onUpdateInvitationCodes, 500);
  }, [invitationCode]);

  const handleCopy = async (invitation: any) => {
    const invitaionLink = await getInvitationLink(invitation.inviteCode);
    const textToCopy = `${invitaionLink}\nYour invitation code: ${invitation.inviteCode}`;
    try {
      await navigator.clipboard.writeText(textToCopy);
      alert('Invitation link is copied!');
    } catch (err) {
      alert('Failed to copy! Please check the permissions for the clipboard in your browser.');
    }
  };

  const renderInvitationCode = (invitation: any) => {
    if (invitation.isUsed) {
      return (
        <li
          key={invitation.inviteCode}
          className="disabled list-group-item d-flex justify-content-between align-items-center"
        >
          <h5 className="mb-1">{invitation.inviteCode}</h5>
          <span className="badge badge-secondary badge-pill">USED</span>
        </li>
      );
    }

    return (
      <li
        key={invitation.inviteCode}
        className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
      >
        <h5 className="mb-1">{invitation.inviteCode}</h5>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => handleCopy(invitation)}
          style={{ fontSize: '0.6rem' }}
        >
          COPY LINK
        </Button>
      </li>
    );
  };

  return showModal ? (
    <div>
      <Modal scrollable={true} show={showModal} size="lg" onHide={onClose}>
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <Modal.Title style={{ fontSize: '1.5rem' }}>Invitation codes</Modal.Title>
          <Button variant="primary" size="sm" onClick={onCreateInvitationCode} style={{ fontSize: '0.8rem' }}>
            NEW CODE
          </Button>
        </Modal.Header>

        <Modal.Body>
          <ul className="list-group list-group-flush">
            {invitationCodesList.map((invitation: any) => renderInvitationCode(invitation))}
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  ) : null;
};

export default inject(({ rootStore }) => ({ setIsEmailSignUp: rootStore.authStore.setIsEmailSignUp }))(
  observer(InvitationCodesList),
);
