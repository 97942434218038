import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { Pie, Radar, Doughnut, Bar } from 'react-chartjs-2';
import { title } from 'process';
import Sidebar from './Sidebar';
import { description } from '../../utils/ChatStats';

interface Props {
  sidebarStore?: any;
}

const ChatStats: React.FC<Props> = ({ sidebarStore }: Props) => {
  const { isChatStatsOpen, closeChatStats, chatStatsInfo, inChatStats } = sidebarStore;
  const { chatStats, userInfo } = chatStatsInfo;
  const { ranking } = inChatStats;

  const renderUserInChatStats = (value: any) => {
    const proxyValueInfo: any = [];
    const totalRankValue: any = [];
    const totalRankScore: any = [];
    const userName: any = [];
    const valueLength = function (...value: any) {
      for (let i = 0; i < value.length; i++);
    };
    if (value.communication) {
      const valueInfo = value.communication.slice(0, 24);
      _.each(valueInfo, (data, i) => {
        totalRankValue.push(<div>{data.ranking}</div>);
        totalRankScore.push(<div>{data.score}</div>);
        userName.push(<div>{data.firstName + ' ' + data.lastName}</div>);
      });
      // totalRankValue.push(value.communicationRank)
      // totalRankScore.push(value.communicationScore)
      // userName.push(value.uid)
      return (
        <div className="" style={{ height: '100%', width: '100%' }}>
          <Row>
            <Col className="col-1">{totalRankValue}</Col>
            <Col className="col-8">{userName}</Col>
            <Col className="col-2">{totalRankScore}</Col>
          </Row>
        </div>
      );
    }
    if (value.leadership) {
      const valueInfo = value.leadership.slice(0, 24);
      _.each(valueInfo, (data, i) => {
        totalRankValue.push(<div>{data.ranking}</div>);
        totalRankScore.push(<div>{data.score}</div>);
        userName.push(<div>{data.firstName + ' ' + data.lastName}</div>);
      });
      return (
        <div className="" style={{ height: '100%', width: '100%' }}>
          <Row>
            <Col className="col-1">{totalRankValue}</Col>
            <Col className="col-8">{userName}</Col>
            <Col className="col-2">{totalRankScore}</Col>
          </Row>
        </div>
      );
    }
    if (value.collaboration) {
      const valueInfo = value.collaboration.slice(0, 24);
      _.each(valueInfo, (data, i) => {
        totalRankValue.push(<div>{data.ranking}</div>);
        totalRankScore.push(<div>{data.score}</div>);
        userName.push(<div>{data.firstName + ' ' + data.lastName}</div>);
      });
      return (
        <div className="" style={{ height: '100%', width: '100%' }}>
          <Row>
            <Col className="col-1">{totalRankValue}</Col>
            <Col className="col-8">{userName}</Col>
            <Col className="col-2">{totalRankScore}</Col>
          </Row>
        </div>
      );
    }
    if (value.social) {
      const valueInfo = value.social.slice(0, 24);
      _.each(valueInfo, (data, i) => {
        totalRankValue.push(<div>{data.ranking}</div>);
        totalRankScore.push(<div>{data.score}</div>);
        userName.push(<div>{data.firstName + ' ' + data.lastName}</div>);
      });
      return (
        <div className="" style={{ height: '100%', width: '100%' }}>
          <Row>
            <Col className="col-1">{totalRankValue}</Col>
            <Col className="col-8">{userName}</Col>
            <Col className="col-2">{totalRankScore}</Col>
          </Row>
        </div>
      );
    }
    if (value.influential) {
      const valueInfo = value.influential.slice(0, 24);
      _.each(valueInfo, (data, i) => {
        totalRankValue.push(<div>{data.ranking}</div>);
        totalRankScore.push(<div>{data.score}</div>);
        userName.push(<div>{data.firstName + ' ' + data.lastName}</div>);
      });
      return (
        <div className="" style={{ height: '100%', width: '100%' }}>
          <Row>
            <Col className="col-1">{totalRankValue}</Col>
            <Col className="col-8">{userName}</Col>
            <Col className="col-2">{totalRankScore}</Col>
          </Row>
        </div>
      );
    }
  };

  const userRankLabels = (data: any) => {
    if (data.leadership) {
      return 'Leadership';
    }
    if (data.influential) {
      return 'Influential';
    }
    if (data.social) {
      return 'Social';
    }
    if (data.collaboration) {
      return 'Collaboration';
    }
    if (data.communication) {
      return 'Communication';
    }
    if (data === 'activeness') {
      return null;
    }
    if (data === 'approachability') {
      return 'Approachable';
    }
  };

  const userFullNameLabels = (value: any) => {
    let valueArray: any = [];
    if (value !== null) {
      valueArray = [];
      {
        Object.entries(value).map(([key, val]: [string, any]) => {
          if (!userInfo[key]) return;
          const { firstName, lastName } = userInfo[key];
          const EntireName = userInfo[key].firstName + ' ' + userInfo[key].lastName;
          valueArray.push(EntireName);
        });
      }
    }
    return valueArray;
  };

  const userNameLabels = (value: any) => {
    let valueArray: any = [];
    if (value !== null) {
      valueArray = [];
      {
        Object.entries(value).map(([key, val]: [string, any]) => {
          if (!userInfo[key]) return;
          const { firstName, lastName } = userInfo[key];
          const EntireName = userInfo[key].firstName.slice(0, 1) + '. ' + userInfo[key].lastName;
          valueArray.push(EntireName);
        });
      }
    }
    return valueArray;
  };

  const chatStatsValues = (value: any) => {
    let statsArray: any = [];
    if (value !== null) {
      statsArray = [];
      {
        Object.entries(value).map(([key, val]: [string, any]) => {
          if (!userInfo[key]) return;
          const { firstName, lastName } = userInfo[key];
          statsArray.push(val);
        });
      }
    }
    return statsArray;
  };

  const getColor = (value: any) => {
    const colorArray: any = [];
    const userColor = userNameLabels(value);
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';
    _.each(userColor, (value) => {
      for (let i = 0; i < 6; i++) {
        color = color + letters[Math.floor(Math.random() * 16)];
      }
      colorArray.push(color);
      color = '#';
    });
    return colorArray;
  };

  const ChatStatsPie = (stats: any, value: any) => {
    const userFullNames: any = userFullNameLabels(value);
    const userValues: any = chatStatsValues(value);
    const backgroundColor: any = getColor(value);
    const ChartDisplay = {
      labels: userFullNames,
      datasets: [
        {
          label: 'value',
          data: userValues,
          backgroundColor: backgroundColor,
          hoverBorderColor: '#000',
          borderAlign: 'inner',
        },
      ],
      options: {
        responsive: true,
        aspectRatio: 2.0,
      },
    };
    return (
      <div
        key={stats}
        className="col-12 align-items-center"
        style={{ height: '90%', width: '90%', padding: 0, margin: 'auto' }}
      >
        <div className="col-12 mb-1 mx-2 px-2 flex-column border border-dark">{description[stats]}</div>
        <Doughnut data={ChartDisplay} legend={false} />
      </div>
    );
  };

  const ChatStatsBarLowtoHigh = (stats: any, value: any) => {
    const userNames: any = userNameLabels(value);
    const userFullNames: any = userFullNameLabels(value);
    const userValues: any = chatStatsValues(value);
    const backgroundColor: any = getColor(value);

    const merged = backgroundColor.map((background: any, i: number) => {
      return {
        background: backgroundColor[i],
        username: userNames[i],
        userfullname: userFullNames[i],
        uservalue: userValues[i],
      };
    });

    const dataSort = merged.sort(function (a: any, b: any) {
      return a.uservalue - b.uservalue;
    });

    const bgColor = [];
    const userName = [];
    const userFullName: any[] = [];
    const userValue = [];
    let i: any;
    for (i = 0; i < dataSort.length; i++) {
      bgColor.push(dataSort[i].background);
      userName.push(dataSort[i].username);
      userFullName.push(dataSort[i].userfullname);
      userValue.push(dataSort[i].uservalue);
    }

    const timeStats = [
      'Avg read-to-response time',
      'Avg read time by others',
      'Avg read time of msg requiring response',
      'Avg read time general',
      'Avg Response Time by Others',
      'Avg Response Time by Each Member',
    ];

    const options = {
      tooltips: {
        callbacks: {
          title: function (tooltipItem: { index: any }[]) {
            return userFullName[tooltipItem[0].index];
          },
          label: function (item: any, everything: any) {
            let value = item.yLabel;
            if (timeStats.includes(description[stats])) {
              let sec: any = 0;
              let min: any = 0;
              let hour: any = 0;
              hour = Math.floor(value / 3600);
              min = Math.floor((value % 3600) / 60);
              sec = Math.floor(((value % 3600) / 60) % 60);

              if (min < 10) min = '0' + min;
              if (sec < 10) sec = '0' + sec;
              value = hour + ':' + min + ':' + sec;
            }

            return value;
          },
        },
      },
    };

    const ChartDisplay = {
      labels: userName,
      datasets: [
        {
          data: userValue,
          backgroundColor: bgColor,
          hoverBorderColor: '#000',
          borderAlign: 'inner',
        },
      ],
      options: {
        responsive: true,
        aspectRatio: 2.0,
      },
    };
    return (
      <div
        key={stats}
        className="col-12 align-items-center"
        style={{ height: '100%', width: '100%', padding: 0, margin: 'auto' }}
      >
        <div className="col-12 mb-1 mx-2 px-2 flex-column border border-dark">{description[stats]}</div>
        <Bar data={ChartDisplay} options={options} legend={false} />
      </div>
    );
  };

  const ChatStatsBarHightoLow = (stats: any, value: any) => {
    const userNames: any = userNameLabels(value);
    const userFullNames: any = userFullNameLabels(value);
    const userValues: any = chatStatsValues(value);
    const backgroundColor: any = getColor(value);

    const merged = backgroundColor.map((background: any, i: number) => {
      return {
        background: backgroundColor[i],
        username: userNames[i],
        userfullname: userFullNames[i],
        uservalue: userValues[i],
      };
    });

    const dataSort = merged.sort(function (b: any, a: any) {
      return a.uservalue - b.uservalue;
    });

    const bgColor = [];
    const userName = [];
    const userFullName: any[] = [];
    const userValue = [];
    let i: any;
    for (i = 0; i < dataSort.length; i++) {
      bgColor.push(dataSort[i].background);
      userName.push(dataSort[i].username);
      userFullName.push(dataSort[i].userfullname);
      userValue.push(dataSort[i].uservalue);
    }

    const options = {
      tooltips: {
        callbacks: {
          title: function (tooltipItem: { index: any }[]) {
            return userFullName[tooltipItem[0].index];
          },
        },
      },
    };

    const ChartDisplay = {
      labels: userName,
      datasets: [
        {
          data: userValue,
          backgroundColor: bgColor,
          hoverBorderColor: '#000',
          borderAlign: 'inner',
        },
      ],
      options: {
        responsive: true,
        aspectRatio: 2.0,
      },
    };
    return (
      <div
        key={stats}
        className="col-12 align-items-center"
        style={{ height: '100%', width: '100%', padding: 0, margin: 'auto' }}
      >
        <div className="col-12 mb-1 mx-2 px-2 flex-column border border-dark">{description[stats]}</div>
        <Bar data={ChartDisplay} options={options} legend={false} />
      </div>
    );
  };

  const renderChatStatWithUserInfo = (statKey: string, value: any) => {
    return (
      <Row className="mb-1 mx-2 px-2 flex-column border border-dark">
        <Col key={statKey} className="p-0">
          {description[statKey]}:
        </Col>
        <Col className="p-0">
          {Object.entries(value).map(([key, val]: [string, any]) => {
            if (!userInfo[key]) return;
            const { firstName, lastName } = userInfo[key];
            return (
              <Row key={key} className="m-0 p-0">
                <Col className="p-0 text-left">
                  {firstName} {lastName}:
                </Col>
                <Col className="p-0 text-left">{val}</Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  };

  return (
    <Sidebar open={isChatStatsOpen} onCloseClick={closeChatStats} title="Chat Stats" boundary>
      <div className="col-12 justify-text-center font-weight-bold" style={{ margin: 'auto' }}>
        <span>Soft Skill Rankings</span>
      </div>
      <Col>
        <Row>
          {!_.isEmpty(inChatStats.ranking) &&
            Object.entries(ranking).map(([key, rank]: [any, any]) => {
              return (
                <div className="col-12 align-text-center">
                  <div className="font-weight-bold border-bottom border-top">{userRankLabels(rank)}</div>
                  <div className="">{renderUserInChatStats(rank)}</div>
                </div>
              );
            })}
        </Row>
      </Col>
      {!_.isEmpty(chatStatsInfo) &&
        Object.entries(chatStats).map(([statKey, chatStat]: [string, any]) => {
          if (!description[statKey]) return;

          const { value, withUserInfo } = chatStat;
          if (_.isEmpty(value)) return;

          switch (description[statKey]) {
            case '% of Msg Per Member':
              return ChatStatsPie(statKey, value);
              break;
            case 'Msg Requiring Attention':
            case '# of mentions':
            case '# of mentions per member':
            case 'Msg Per Member':
              return ChatStatsBarHightoLow(statKey, value);
              break;
            default:
              return ChatStatsBarLowtoHigh(statKey, value);
          }
        })}
    </Sidebar>
  );
};

export default inject(({ rootStore }) => ({ sidebarStore: rootStore.sidebarStore }))(observer(ChatStats));
