import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Form, Modal } from 'react-bootstrap';
import { history } from '../../../BaseRouter';

interface Props {
  authStore?: any;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  countryCode: string;
  phoneNumber: string;
}

const CodeVerificationModal: React.FC<Props> = (props: Props) => {
  const { authStore, showModal, setShowModal, countryCode, phoneNumber } = props;
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (!ready) {
      setTimeout(() => {
        setReady(true);
      }, 300000);
    }
  }, [ready]);

  const onVerificationNumberUpdate = (e: any) => {
    const tempInput = e.target.value.split('');
    const valid = tempInput.some((text: any) => {
      return Number.isInteger(parseInt(text));
    });
    if (valid) {
      setVerificationCode(e.target.value);
    }
  };

  const handleResend = () => {
    if (ready) {
      setReady(false);
      authStore.resendVerificationCode(countryCode, phoneNumber);
    }
  };

  const verifyCode = (e: any) => {
    e.preventDefault();
    authStore.verifyCode(verificationCode).then(() => {
      if (authStore.isCodeValid) {
        setShowModal(false);
        history.push('/');
      }
      setVerificationCode('');
    });
  };

  return (
    <Modal
      centered
      show={showModal}
      onHide={() => {
        setShowModal(false);
        window.location.reload();
      }}
    >
      <Modal.Header className="bgGray-1 text-center colorWhite" closeButton>
        <Modal.Title>
          We have sent you a Verification Code at '{countryCode}-{phoneNumber}'.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bgGray-1 colorWhite">
        <Form>
          <Form.Group>
            <Form.Label>Enter Verification Code:</Form.Label>
            <Form.Control
              onChange={onVerificationNumberUpdate}
              value={verificationCode}
              placeholder="######"
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  verifyCode(e);
                }
              }}
            />
          </Form.Group>
        </Form>
        <p className="mt-3 mb-0 mx-2">
          You can request a verification code again 5 minutes after your last send request
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="mr-auto" onClick={handleResend} disabled={!ready}>
          Re-send Verification Code
        </Button>
        <Button variant="primary" onClick={(e: any) => verifyCode(e)} disabled={verificationCode.length !== 6}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default inject(({ rootStore }) => ({ authStore: rootStore.authStore }))(observer(CodeVerificationModal));
