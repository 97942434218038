import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faVideo, faFile } from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card';

interface Props {
  rootStore?: any;
  messageId: string;
  message?: any;
}

@inject('rootStore')
@observer
class QuotedMessage extends Component<Props> {
  private rootStore: any;

  private chatStore: any;

  private message: any;

  private errorMsg = 'loading...';

  private messageId: any;

  constructor(props: Props) {
    super(props);
    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.message = this.props.message;
    this.messageId = this.props.messageId;
  }

  componentDidMount() {
    if (!this.message) {
      this.chatStore.sendMessageIdToServer(this.messageId);
    }
  }

  @computed get quotedMessageList() {
    return this.chatStore.mentionedMessageList;
  }

  private setMessage = () => {
    this.quotedMessageList.forEach((message: any) => {
      if (message._id === this.messageId) {
        this.message = message;
        this.errorMsg = '';
      }
    });
    if (!this.message) {
      this.errorMsg = 'Cannot show the original message';
    }
  };

  private getSenderName = () => {
    return this.message.sender.name;
  };

  private getThumbnail = () => {
    const { attachments } = this.message;
    return <img alt="thumbnail" src={attachments[0].originalURL} width={60} height={60} className="ml-3" />;
  };

  private getSummary = () => {
    const { type, text } = this.message;
    let summary = '';
    const length = type === 'Photo' ? 30 : 80;

    summary += text.length > length ? `${text.substring(0, length)}"..."` : text;

    return summary;
  };

  private mediaIcon = () => {
    const { type } = this.message;
    const styles = {
      fontSize: 14,
      marginRight: 6,
    };

    switch (type) {
      case 'Photo':
        return <FontAwesomeIcon icon={faCamera} style={styles} />;
      case 'Video':
        return <FontAwesomeIcon icon={faVideo} style={styles} />;
      case 'Document':
        return <FontAwesomeIcon icon={faFile} style={styles} />;
      default:
        return null;
    }
  };

  private renderQuote = () => {
    return (
      <>
        <div className="mr-auto">
          <Card.Subtitle className="py-2 text-light">{this.getSenderName()}</Card.Subtitle>
          <Card.Text className="text-dark">
            {this.mediaIcon()}
            {this.getSummary()}
          </Card.Text>
        </div>
        {this.message.attachments && this.getThumbnail()}
      </>
    );
  };

  render() {
    return (
      <Card className="p-2 d-flex flex-row" style={{ background: 'rgba(193,193,193,0.5)', minWidth: 200 }}>
        {this.quotedMessageList && this.setMessage()}
        {this.message ? this.renderQuote() : <small className="text-danger">{this.errorMsg}</small>}
      </Card>
    );
  }
}

export default QuotedMessage;
