import React, { Component } from 'react';
import { Link, Router } from 'react-router-dom';
import { Container, Row, Col, Image, Tab, Tabs } from 'react-bootstrap';
import { render } from 'react-dom';
import _ from 'lodash';
import { addLeadingSlash } from 'history/PathUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { values, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import StarredMessages from './StarredMessages';
import RootStore from '../../stores/RootStore';

interface state {
  userName: any;
}

interface props {
  rootStore?: any;
}

@inject('rootStore')
@observer
class ActivityChatLog extends React.Component<props, state> {
  private rootStore: any;
  private authStore: any;
  private chatStore: any;
  constructor(props: props) {
    super(props);
    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.chatStore;
  }

  renderLogs = () => {
    const activityData = toJS(this.chatStore.activityArr);
    const activeLog: any = [];
    let counter = 0;
    _.each(activityData, (value) => {
      counter++;
      activeLog.push(
        <Container key={counter}>
          <Row>
            <Col xs={12}>{_.replace(value.log, /'/g, '')}</Col>
            <Col xs={12}>
              {value.createdAt.slice(0, 10)} {value.createdAt.slice(11, 19)}
            </Col>
            <Col xs={11} style={{ color: '#525252' }}>
              {value.date}
            </Col>
            <Col>
              <hr style={{ color: '#e6e2e1', height: '5px' }} />
            </Col>
            <Row style={{ marginTop: '20px', borderBottom: '1px solid #383D3F', width: '99%', margin: 'auto' }}></Row>
          </Row>
        </Container>,
      );
    });
    return activeLog;
  };

  render() {
    return (
      <Container>
        <div
          id="LogHeader"
          className="col-12 d-flex flex-column container-fluid"
          style={{ backgroundColor: '#282B2C', margin: 'auto', borderRadius: 5 }}
        >
          <div style={{}}></div>
          <h1
            style={{
              fontWeight: 'bold',
              fontSizeAdjust: 5,
              fontFamily: 'Arial, Helvetica, sans-serif',
              color: '#819EB4',
              fontSize: 25,
              margin: 'auto',
              marginTop: 20,
              borderBottom: '1px solid #819EB4',
            }}
          >
            Activity Log List
          </h1>
        </div>
        <div
          id="ChatLog"
          className="col-12 d-flex flex-column container-fluid"
          style={{
            height: '100vh',
            display: 'block',
            overflowX: 'scroll',
            margin: 'auto',
            borderRadius: 5,
            backgroundColor: 'e6e2e1',
            color: '#D1CDC7',
          }}
        >
          {this.renderLogs()}
        </div>
      </Container>
    );
  }
}

export default ActivityChatLog;
