import React, { ReactNode } from 'react';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import './Sidebar.scss';

interface Props {
  open: boolean;
  onCloseClick(): void;
  title?: string;
  boundary?: boolean;
  sub?: boolean;
  children?: ReactNode;
}

const Sidebar: React.FC<Props> = ({ open, onCloseClick, title, boundary, sub, children }: Props) => {
  return (
    <>
      <div className={classNames('sidebar', { open })}>
        <Container className="p-0 d-flex flex-column">
          <header className={classNames('d-flex align-items-center p-3', { 'boundary mb-3': boundary })}>
            <button className={classNames('closebtn mr-4', { arrow: sub })} onClick={onCloseClick}>
              <FontAwesomeIcon icon={sub ? faAngleRight : faTimes} />
            </button>
            {title && <h5 className="m-0">{title}</h5>}
          </header>
          {children}
        </Container>
      </div>
    </>
  );
};

export default Sidebar;
