import { observable, computed, action } from 'mobx';
import RootStore from './RootStore';
import { WEBSOCKET_COMMAND_TYPE, ROOMTYPE } from '../utils/Constants';
import User from '../utils/User';

export default class SidebarStore {
  authStore: any;
  chatStore: any;

  constructor(rootStore: RootStore) {
    this.authStore = rootStore.authStore;
    this.chatStore = rootStore.chatStore;
  }

  @observable isChatStatsOpen: boolean = false;
  @observable isChatDetailOpen: boolean = false;
  @observable isAddMembersOpen: boolean = false;
  @observable isPostsOpen: boolean = false;
  @observable isMediaOpen: boolean = false;
  @observable isParticipantsOpen: boolean = false;
  @observable isFollowersOpen: boolean = false;
  @observable isActivityLogOpen: boolean = false;
  @observable isChatSettingsOpen: boolean = false;
  @observable isNewWindowOpen: boolean = false;
  @observable isMessageDetailOpen: boolean = false;
  @observable immediate: boolean = false;

  @observable chatStatsInfo: any = {};
  @observable participantList: User[] = [];
  @observable followerList: User[] = [];
  @observable readerList: Object = {};
  @observable inChatStats: any = {};

  @computed
  get isSidebarOpen() {
    return this.isChatStatsOpen || this.isChatDetailOpen || this.isMessageDetailOpen;
  }

  @computed
  get isSubChatDetailOpen() {
    return (
      this.isAddMembersOpen ||
      this.isPostsOpen ||
      this.isMediaOpen ||
      this.isParticipantsOpen ||
      this.isFollowersOpen ||
      this.isChatSettingsOpen ||
      this.isNewWindowOpen
    );
  }

  @action
  closeSidebar = () => {
    this.immediate = true;
    this.closeChatStats();
    this.closeChatDetail();
    this.closeMessageDetail();
    this.inChatStats = {};
  };

  @action
  openChatDetail = () => {
    this.immediate = false;
    this.isChatStatsOpen = false;
    this.isMessageDetailOpen = false;
    this.isChatDetailOpen = true;
  };

  @action
  closeChatDetail = () => {
    this.closeSubChatDetail();
    this.isChatDetailOpen = false;
  };

  @action
  closeSubChatDetail = () => {
    this.participantList = [];
    this.followerList = [];
    this.isAddMembersOpen = false;
    this.isPostsOpen = false;
    this.isMediaOpen = false;
    this.isParticipantsOpen = false;
    this.isFollowersOpen = false;
    this.isActivityLogOpen = false;
    this.isChatSettingsOpen = false;
    this.isNewWindowOpen = false;
  };

  @action
  openMessageDetail = () => {
    this.readerList = {};
    this.getMessageReaderList(this.chatStore.selectedMessage._id);
    this.immediate = false;
    this.isChatStatsOpen = false;
    this.closeChatDetail();
    this.isMessageDetailOpen = true;
  };

  getMessageReaderList = (messageID: string) => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_MESSAGE_READER_LIST,
      data: { messageID },
    });
  };

  @action
  resGetMessageReaderList = (_: string, data: any) => {
    this.readerList = data.readerList;
  };

  @action
  closeMessageDetail = () => {
    this.isMessageDetailOpen = false;
    this.readerList = {};
  };

  @action
  openAddMembers = () => {
    if (this.chatStore.getCurrentChatRoom().roomType === ROOMTYPE.GROUP) {
      this.isAddMembersOpen = true;
    } else {
      this.isAddMembersOpen = false;
      alert("You can't add friends in this personal chat");
    }
  };

  @action
  openPosts = () => {
    this.isPostsOpen = true;
  };

  @action
  openMedia = () => {
    this.isMediaOpen = true;
  };

  @action
  openParticipants = () => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_CHAT_PARTICIPANTLIST,
      data: { roomID: this.chatStore.currRoomID },
    });
    this.isParticipantsOpen = true;
  };

  @action
  resOpenParticipants = (_: string, data: any) => {
    this.participantList = data.userList;
  };

  @action
  openFollowers = () => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_CHAT_FOLLOWERLIST,
      data: { roomID: this.chatStore.currRoomID },
    });
    this.isFollowersOpen = true;
  };

  @action
  resOpenFollowers = (_: string, data: any) => {
    this.followerList = data.userList;
  };

  @action
  openActivityLog = () => {
    this.chatStore.loadActivityLog(this.chatStore.currRoomID);
    this.isActivityLogOpen = true;
  };

  @action
  openChatSettings = () => {
    this.isChatSettingsOpen = true;
  };

  @action
  openChatStats = () => {
    this.getChatStats();
    this.immediate = false;
    this.closeChatDetail();
    this.isMessageDetailOpen = false;
    this.isChatStatsOpen = true;
    this.getUserInChatStats();
  };

  @action
  closeChatStats = () => {
    this.isChatStatsOpen = false;
  };

  getChatStats = () => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_CHAT_STATS,
      data: { roomID: this.chatStore.currRoomID },
    });
  };

  @action
  resGetChatStats = (_: string, data: any) => {
    this.chatStatsInfo = data;
  };

  @action
  getUserInChatStats = () => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_ACTIVE_GROUP_USER_RANKING,
      data: { roomID: this.chatStore.currRoomID },
    });
  };

  resInChatUserRanking = (_: string, data: any) => {
    this.inChatStats = data;
  };
}
