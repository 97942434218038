// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Modal, Button, Image, Form, Col, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScaleLeft, faCamera } from '@fortawesome/free-solid-svg-icons';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { debounce } from 'lodash';

import { WEBSOCKET_COMMAND_TYPE, PRODUCTION_BASELOGO_URL, OTHER_SCHOOL_ID } from '../../utils/Constants';
import MySelect from '../select/Select';
import firebase from '../../firebase/firebase';

interface Props {
  rootStore?: any;
  showModal: boolean;
  onSave: any;
  onCancel: any;
}
interface State {
  image: any;
  email: string;
  manualSchool: string;
  schoolID: string;
  schoolName: string;
  majorID: string;
  majorName: string;
  selectedImage: any;
  file: any;
  userName: string;
  schoolNameSearch: string;
  profileUrl: string;
  validationErrorMsgProfileUrl: string;
  firebaseEmail: string;
  firebasePassword: string;
  emailAdd: boolean;
}

const primaryColor = '#5D7F9D';
// eslint-disable-next-line no-useless-escape
const emailForm = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const profileUrlRegex = /^([a-z0-9-]+)$/;

@inject('rootStore')
@observer
class AccountEdit extends Component<Props, State> {
  private rootStore: any;

  private authStore: any;

  private chatStore: any;

  private isMajorUpdated = false;

  private isSchoolUpdated = false;

  private firebaseUser: any | null;

  private firebaseEmailVerified: boolean;

  private schoolList: any;

  private majorList: any;

  private profileUrlTimeoutID: NodeJS.Timeout | null = null;

  public selectedImg: any = null;

  public originalImg: any = null;

  constructor(props: any) {
    super(props);
    const { rootStore } = this.props;
    this.rootStore = rootStore;
    this.authStore = this.rootStore.authStore;
    this.chatStore = this.rootStore.chatStore;
    this.originalImg = this.authStore.selfUser.thumbnailURL;
    this.schoolList = this.authStore.getSchoolList();
    this.majorList = this.authStore.getMajorList();
    this.firebaseUser = firebase.auth().currentUser;
    this.firebaseEmailVerified = this.firebaseUser ? this.firebaseUser.emailVerified : false;

    this.state = {
      userName: `${this.authStore.selfUser.firstName} ${this.authStore.selfUser.lastName}`,
      image: this.authStore.selfUser.thumbnailURL,
      manualSchool: this.authStore.selfUser.manualSchool || '',
      email: this.authStore.selfUser.email,
      schoolID: this.authStore.selfUser.schoolID,
      schoolName: '',
      majorID: this.authStore.selfUser.majorID,
      majorName: '',
      selectedImage: null, // NOT USED YET
      file: null, // NOT USED YET,
      schoolNameSearch: '',
      profileUrl: this.authStore.selfUser.profileUrl || '',
      validationErrorMsgProfileUrl: '',
      firebaseEmail: '',
      firebasePassword: '',
      emailAdd: false,
    };
  }

  private validateProfileUrlLength = (profileUrl: string) => {
    return profileUrl.length >= 2 && profileUrl.length <= 30;
  };

  private onChangeProfileUrl = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ profileUrl: e.target.value.toLowerCase() });
    if (this.profileUrlTimeoutID) {
      clearTimeout(this.profileUrlTimeoutID);
    }
    this.profileUrlTimeoutID = setTimeout(() => {
      this.validateProfileUrl(e.target.value.toLowerCase()).then((errMsg) => {
        this.setState({ validationErrorMsgProfileUrl: errMsg });
      });
      this.profileUrlTimeoutID = null;
    }, 500);
  };

  private onChangeManualSchool = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ manualSchool: e.target.value });
  };

  private validateProfileUrl = async (profileUrl: string): Promise<string> => {
    // length validation
    if (profileUrl.length === 0) {
      return '';
    }
    if (!this.validateProfileUrlLength(profileUrl)) {
      return 'Profile url: must be minimum 2 characters to maximum 30.';
    }
    // regex validation
    if (!profileUrlRegex.test(profileUrl)) {
      return 'Profile url: only lower case alphabets, numbers and "-" are allowed.';
    }

    // duplicate validation
    const idToken = await this.authStore.getUserIDToken();
    const result = await fetch(
      `${this.authStore.getApiURL()}/validateProfileUrl/${profileUrl}?uid=${
        this.authStore.selfUser.uid
      }&idToken=${idToken}`,
    )
      .then(
        (res) => res.json(),
        (error) => {
          console.error('Error:', error);
          alert('Server error');
          return {
            content: false,
            message: 'Server error',
          };
        },
      )
      .catch((error) => {
        console.error('Error:', error);
        alert('Server error');
        return {
          content: false,
          message: 'Server error',
        };
      });

    return result.content ? '' : result.message;
  };

  // student major functions
  private searchMajorByKeyword = (keyword: string): any => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_MAJORS_BY_KEYWORD,
      data: {
        keyword,
      },
    });
  };

  private handleMajorInputChange = (newValue: any) => {
    this.isMajorUpdated = true;
    const inputValue = newValue.replace(/\W/g, '');
    if (inputValue.trim()) {
      this.searchMajorDelayed(inputValue);
    }
  };

  private handleMajorSelect = (e: any) => {
    this.setState({
      majorID: e.value,
      majorName: e.label,
    });
  };

  private searchMajorDelayed = debounce((e) => {
    this.searchMajorByKeyword(e);
  }, 400);

  // student school functions

  private searchSchoolByKeyword = (keyword: string): any => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_SCHOOLS_BY_KEYWORD,
      data: {
        keyword,
      },
    });
  };

  private handleSchoolInputChange = (newValue: any) => {
    this.isSchoolUpdated = true;
    const inputValue = newValue.replace(/\W/g, '');
    if (inputValue.trim()) {
      this.searchSchoolDelayed(inputValue);
    }
  };

  private handleSchoolSelect = (option: { value: string; label: string }) => {
    const schoolID = option.value;
    const schoolName = option.label;
    this.setState({ schoolID, schoolName });
    const { manualSchool } = this.state;
    if (schoolID !== OTHER_SCHOOL_ID && manualSchool !== '') {
      this.setState({ manualSchool: '' });
    }
  };

  private searchSchoolDelayed = debounce((e) => {
    this.searchSchoolByKeyword(e);
  }, 400);

  private _isEmailValid = (input: string) => {
    const { email } = this.state;
    if (!email || input.length === 0) {
      return true;
    }
    return emailForm.test(input);
  };

  private onEmailUpdate = (event: any) => {
    this.setState({ email: event.target.value });
  };

  private handleSave = () => {
    const {
      email,
      profileUrl,
      validationErrorMsgProfileUrl,
      image,
      schoolName,
      majorName,
      schoolID,
      majorID,
      manualSchool,
    } = this.state;
    if (!this._isEmailValid(email)) {
      alert('Please provide a valid email address!');
      return;
    }
    this.validateProfileUrl(profileUrl).then((errMsg) => {
      this.setState({ validationErrorMsgProfileUrl: errMsg });
    });
    if (validationErrorMsgProfileUrl) {
      alert('Please provide a valid profile url! Or make it empty.');
      return;
    }
    this.updateProfile(email, schoolID, majorID, profileUrl, manualSchool);
    this.originalImg = image;
    const { onSave } = this.props;
    if (this.schoolList[schoolID] && this.schoolList[schoolID].thumbnailURL) {
      onSave(this.schoolList[schoolID].thumbnailURL, schoolName, majorName);
    } else {
      onSave(PRODUCTION_BASELOGO_URL, schoolName, majorName);
    }
  };

  private updateProfile(email: string, schoolID: string, majorID: string, profileUrl: string, manualSchool: string) {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.UPDATE_USERINFO,
      data: {
        email: email || '',
        schoolID,
        majorID,
        manualSchool,
        profileUrl: profileUrl || this.authStore.selfUser.profileUrl,
      },
    });
  }

  private handleCancel = () => {
    this.setState({ email: this.authStore.selfUser.email });

    // if the modal was cancelled, then reset the majorID/schoolID (in state)
    // to what it was before (value was originally contained in props.majorID)
    this.setState({ majorID: this.authStore.selfUser.majorID });
    this.setState({ schoolID: this.authStore.selfUser.schoolID });
    this.setState({ profileUrl: this.authStore.selfUser.profileUrl });
    this.setState({ validationErrorMsgProfileUrl: '' });

    const { image } = this.state;
    if (this.originalImg !== image) {
      this.setState({ image: null });
    }

    // reset each suggestions
    this.authStore.resetSchoolListByKeyword([]);
    this.authStore.resetMajorListByKeyword([]);

    const { onCancel } = this.props;
    onCancel();
  };

  private handleAddEmail = () => {
    this.setState({ emailAdd: true });
  };

  private onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firebaseEmail: e.target.value });
  };

  private onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firebasePassword: e.target.value });
  };

  private handleClickAddEmailButton = () => {
    const { firebaseEmail, firebasePassword } = this.state;
    if (!emailForm.test(firebaseEmail)) {
      alert('Please provide a valid email address!');
      return;
    }

    const credential = firebase.auth.EmailAuthProvider.credential(firebaseEmail, firebasePassword);
    this.firebaseUser
      .linkWithCredential(credential)
      .then((usercred: any) => {
        this.updateProfile(
          firebaseEmail,
          this.authStore.selfUser.schoolID,
          this.authStore.selfUser.majorID,
          this.authStore.selfUser.profileUrl || '',
          this.authStore.selfUser.manualSchool || '',
        );
        this.authStore.signOut();
      })
      .catch((error: any) => {
        console.error('Account linking error', error);
        if (error.code === 'auth/requires-recent-login') {
          alert('Signin timeout. Please logout then login and try again.');
        } else if (
          error.code === 'auth/provider-already-linked' ||
          error.code === 'auth/credential-already-in-use' ||
          error.code === 'auth/email-already-in-use'
        ) {
          alert('The email address you typed is already taken.');
        } else {
          alert('Something went wrong. Please contact our support.');
        }
      });
  };

  private handleImagePicker = (event: any) => {
    const file = event.target.files[0];
    const types = ['image/png', 'image/jpeg'];
    try {
      if (types.every((type) => file.type !== type)) {
        alert('Please choose an image in the supported format (jpeg and png)!');
      } else {
        this.selectedImg = null;
        this.authStore.changeAvatar(file);
        this.render();
      }
    } catch (error) {
      console.error(error);
    }
  };

  private renderOptions(options: any) {
    const optionsMap = Object.keys(options).map((key: any) => (
      <option key={key} data-key={key} value={options[key].name}>
        {options[key].name}
      </option>
    ));
    return optionsMap;
  }

  renderEdit = () => {
    const { schoolID, majorID } = this.state;
    const schoolName = this.schoolList[schoolID] ? this.schoolList[schoolID].name : 'Other';
    const majorName = this.majorList[majorID] ? this.majorList[majorID].name : 'Other';

    if (this.isMajorUpdated === false) {
      this.setState({ majorName });
      this.isMajorUpdated = true;
    }
    if (this.isSchoolUpdated === false) {
      this.setState({ schoolName });
      this.isSchoolUpdated = true;
    }

    const { profileUrl, validationErrorMsgProfileUrl, manualSchool, userName, image } = this.state;
    return (
      <div className="bg-white">
        <div style={{ color: primaryColor }}>
          <div className="col-4 col-sm-4 col-lg-3 px-0 thumbnail-edit mx-auto my-4">
            <Image
              className="mr-2 bs-1"
              width="240"
              height="240"
              src={image || PRODUCTION_BASELOGO_URL}
              roundedCircle
              style={{
                border: '1px #5D7F9D80 solid',
                width: '28vw',
                height: '28vw',
                maxWidth: '240px',
                maxHeight: '240px',
                minWidth: '140px',
                minHeight: '140px',
                marginLeft: '-20px',
              }}
            />
            <label htmlFor="upload-button" className="button base-btn">
              <FontAwesomeIcon icon={faCamera} className="camera-edit" style={{ color: '#2E302D' }} />
              <input type="file" id="upload-button" onChange={this.handleImagePicker} style={{ display: 'none' }} />
            </label>
          </div>
          <div>
            <div>
              <Row className="col-md-10 mx-auto mb-3">
                <Col sm="4" md="2" style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                  {' '}
                  Name{' '}
                </Col>
                <Col style={{ paddingLeft: '12', color: 'black', fontSize: '1.1rem' }}>{userName}</Col>
              </Row>
            </div>
            <Form>
              {/* <Form.Group as={Row} className="col-md-10 mx-auto">
                <Form.Label column style={{ fontWeight: 'bold', fontSize: '1vmax' }}>
                  {' '}
                  Email{' '}
                </Form.Label>
                <Col sm="8" md="10">
                  <Form.Control
                    style={{ fontSize: '1.1vmax' }}
                    onChange={this.onEmailUpdate}
                    placeholder={this.state.email}
                    type="email"
                  />
                </Col>
              </Form.Group> */}

              <Form.Group as={Row} className="col-md-10 mx-auto">
                <Form.Label column style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                  {' '}
                  School{' '}
                </Form.Label>
                <Col sm="8" md="10">
                  <MySelect
                    placeholder={schoolName === '' ? 'Search...' : schoolName}
                    onOptionSelect={this.handleSchoolSelect}
                    onInputValueChange={this.handleSchoolInputChange}
                    options={toJS(this.authStore.schoolListByKeyword)}
                    clearSuggestions={this.authStore.resetSchoolListByKeyword}
                    inputIdentifier="schoolInput"
                  />
                </Col>
              </Form.Group>

              {schoolID === OTHER_SCHOOL_ID && (
                <Form.Group as={Row} className="col-md-10 mx-auto">
                  <Form.Label column style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                    School (manual)
                  </Form.Label>
                  <Col sm="8" md="10">
                    <Form.Control
                      placeholder={manualSchool || 'School (manual)'}
                      aria-label="ManualSchool"
                      aria-describedby="basic-addon1"
                      onChange={this.onChangeManualSchool}
                      value={manualSchool}
                    />
                  </Col>
                </Form.Group>
              )}

              <Form.Group as={Row} className="col-md-10 mx-auto">
                <Form.Label column style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                  {' '}
                  Majors{' '}
                </Form.Label>
                <Col sm="8" md="10">
                  <MySelect
                    placeholder={majorName === '' ? 'Search...' : majorName}
                    onInputValueChange={this.handleMajorInputChange}
                    options={toJS(this.authStore.majorListByKeyword)}
                    onOptionSelect={this.handleMajorSelect}
                    clearSuggestions={this.authStore.resetMajorListByKeyword}
                    inputIdentifier="majorInput"
                  />
                </Col>
              </Form.Group>

              {this.renderEmailAdd()}

              {/* Profile Url was editable at the beginning, but decided not to be editable for now for SEO */}
              {/* So just in case comment out, we will be able to re-use this code when the time has come */}
              {/* Or just delete this when decide not to use this anymore */}
              {/* <Form.Group as={Row} className="col-md-10 mx-auto">
                <Form.Label column style={{ fontWeight: 'bold', fontSize: '1vmax' }}>
                  Profile Url
                </Form.Label>
                <Col sm="8" md="10">
                  <Form.Control
                    placeholder={profileUrl || 'Profile Url'}
                    aria-label="ProfileUrl"
                    aria-describedby="basic-addon1"
                    onChange={this.onChangeProfileUrl}
                    value={profileUrl}
                    isInvalid={!!validationErrorMsgProfileUrl}
                  />
                  {!!validationErrorMsgProfileUrl && (
                    <Form.Control.Feedback type="invalid">{`${validationErrorMsgProfileUrl}`}</Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group> */}
            </Form>
          </div>
        </div>
      </div>
    );
  };

  renderEmailAdd = () => {
    const { email, emailAdd, firebaseEmail } = this.state;
    return (
      <>
        {(email || emailAdd) && (
          <Form.Group as={Row} className="col-md-10 mx-auto">
            <Form.Label column style={{ fontWeight: 'bold', fontSize: '1rem' }}>
              Email
            </Form.Label>
            <Col sm="8" md="10">
              <Form.Control
                placeholder="email"
                aria-label="firebaseEmail"
                aria-describedby="basic-addon1"
                onChange={this.onChangeEmail}
                value={email || firebaseEmail}
                disabled={!emailAdd}
              />
            </Col>
          </Form.Group>
        )}

        {emailAdd && (
          <Form.Group as={Row} className="col-md-10 mx-auto">
            <Form.Label column style={{ fontWeight: 'bold', fontSize: '1rem', paddingRight: '0px' }}>
              Password
            </Form.Label>
            <Col sm="8" md="10">
              <Form.Control
                placeholder=""
                aria-label="firebaseEmail-password"
                aria-describedby="basic-addon1"
                onChange={this.onChangePassword}
                type="password"
              />
            </Col>
          </Form.Group>
        )}

        {emailAdd && (
          <div className="col-md-10 mx-auto form-group row">
            <Button
              className="mx-auto"
              variant="primary"
              onClick={this.handleClickAddEmailButton}
              style={{ fontSize: '1.1rem' }}
            >
              Add Email
            </Button>
          </div>
        )}

        {!email && !emailAdd && (
          <div className="col-md-10 mx-auto px-4">
            <a
              style={{ fontWeight: 'bold', fontSize: '1rem', cursor: 'pointer' }}
              onClick={() => this.setState({ emailAdd: true })}
            >
              + Add Email Address as Login Info
            </a>
          </div>
        )}
      </>
    );
  };

  renderModal = () => {
    const { showModal } = this.props;
    return (
      showModal && (
        <div>
          <Modal show={showModal} size="lg" onHide={this.handleCancel}>
            <Modal.Header style={{ justifyContent: 'center' }}>
              <Modal.Title style={{ fontSize: '1.5rem' }}>Edit Your Profile</Modal.Title>
            </Modal.Header>

            <Modal.Body>{this.renderEdit()}</Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCancel} style={{ fontSize: '1.1rem' }}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.handleSave} style={{ fontSize: '1.1rem' }}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )
    );
  };

  render() {
    if (this.selectedImg === null || this.selectedImg === '') {
      this.selectedImg = this.authStore.selfUserThumbnailURL;
    }
    if (this.selectedImg && this.selectedImg !== 1) {
      this.setState({ image: this.selectedImg });
      this.selectedImg = 1;
    }
    return this.renderModal();
  }
}

export default AccountEdit;
