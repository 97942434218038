import * as React from 'react';
import { faPaperclip, faSmile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  getDefaultKeyBinding,
  SelectionState,
  DraftHandleValue,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { stateToHTML } from 'draft-js-export-html';
import _, { isNull } from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import anchorme from 'anchorme';
import { WEBSOCKET_COMMAND_TYPE, PRODUCTION_BASELOGO_URL } from '../../../utils/Constants';
import User from '../../../utils/User';
import './style/ChatPageFooter.scss';
import compositeDecorator from './compositeDecorator';
import EmojiPickerWrapper from './EmojiPickerWrapper';
import TagPanel from './TagPanel';

interface Props {
  rootStore?: any;
  channelID: string;
  textInPreview: string;
  isReplyWrapperOpen: boolean;
  sendMessage: (text: string, html: string | null, mention: string[] | null) => void;
  handleFileUpload: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface State {
  textMsg: string;
  showEmojiPicker: boolean;
  showCrossInEmojiPicker: boolean;
  isTagPanelOpen: boolean;
  allMembersUID: string[];
  editorState: EditorState;
  startingPoints: number | null;
  mentionKeyword: string;
  clearField: any;
  clearChannel: any;
  invokeClearStop: any;
}

@inject('rootStore')
@observer
class ChatPageFooter extends React.Component<Props, State> {
  rootStore: any;
  chatStore: any;
  authStore: any;
  textInputRef: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      textMsg: '',
      showEmojiPicker: false,
      showCrossInEmojiPicker: false,
      isTagPanelOpen: false,
      allMembersUID: [],
      editorState: EditorState.createEmpty(compositeDecorator),
      startingPoints: null,
      mentionKeyword: '',
      clearField: '',
      clearChannel: '',
      invokeClearStop: false,
    };

    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
    this.textInputRef = React.createRef();
    this.addEmoji = this.addEmoji.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getMemberList();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.state.clearChannel !== this.chatStore.currChannelID) {
      this.setState({
        textMsg: '',
        showEmojiPicker: false,
        isTagPanelOpen: false,
        editorState: this.state.editorState,
        invokeClearStop: true,
        clearChannel: this.chatStore.currChannelID,
      });
    }
    if (this.state.clearField !== this.chatStore.currRoomID) {
      this.setState(
        {
          textMsg: '',
          showEmojiPicker: false,
          isTagPanelOpen: false,
          editorState: this.state.editorState,
          clearField: this.chatStore.currRoomID,
          invokeClearStop: true,
        },
        () => this.getMemberList(),
      );
    }
  }

  followChatRoom = (roomID: String) => {
    try {
      this.authStore.sendMessageToServer({ command: WEBSOCKET_COMMAND_TYPE.FOLLOW_CHAT, data: { roomID } });
    } catch (err) {
      console.error(err);
    }
  };

  unFollowChatRoom = (roomID: String) => {
    try {
      this.authStore.sendMessageToServer({ command: WEBSOCKET_COMMAND_TYPE.UNFOLLOW_CHAT, data: { roomID } });
    } catch (err) {
      console.error(err);
    }
  };

  getMemberList = () => {
    const myId = this.rootStore.getUserUID();
    _.each(toJS(this.chatStore.participantsForMention), (member) => {
      if (member.uid !== myId) {
        this.setState({
          editorState: EditorState.createEmpty(compositeDecorator),
        });
        this.state.allMembersUID.push(member.uid);
      }
    });
  };

  toggleEmojiPicker = () => {
    this.setState({
      showEmojiPicker: !this.state.showEmojiPicker,
      showCrossInEmojiPicker: false,
    });
  };

  handleTaggedName = (user: User) => {
    let fullName = `@${user.firstName}`;

    if (user.firstName !== 'all') {
      fullName += ` ${user.lastName}`;
    }

    this.insertTaggedName(fullName);
  };

  createMentionsList = (html: string) => {
    let UIDList: string[] = [];
    let allMentioned = false;

    const regex = /<span class="mention">(.*?)<\/span>/g;
    const tags = html.match(regex);

    tags &&
      tags.forEach((str) => {
        if (!allMentioned) {
          let name = str.substr(23);
          name = name.substr(0, name.length - 7);

          if (name === 'all') {
            UIDList = this.state.allMembersUID;
            allMentioned = true;
          } else {
            const user = this.chatStore.participantsForMention.find(
              (user: any) => `${user.firstName} ${user.lastName}` === name,
            );
            if (user && !UIDList.includes(user.uid)) {
              UIDList.push(user.uid);
            }
          }
        }
      });

    return UIDList;
  };

  handleSend = () => {
    const { editorState } = this.state;
    const anchorKey = editorState.getSelection().getAnchorKey();
    const currentContentBlock = editorState.getCurrentContent().getBlockForKey(anchorKey);
    const text = currentContentBlock.getText();

    let isSomeoneMentioned = false;

    const options = {
      entityStyleFn: (entity: any) => {
        const entityType = entity.get('type');
        if (entityType === 'MENTION') {
          isSomeoneMentioned = true;
          return {
            element: 'span',
            attributes: {
              className: 'mention',
            },
          };
        }
      },
    };
    const html = stateToHTML(editorState.getCurrentContent(), options);
    const replacedHtml = html
      .replace(/<p>/gi, '')
      .replace(/<\/p>/gi, '')
      .replace(/<br>/gi, '')
      .replace(/&nbsp;+$/gm, '');
    const mentionsList = isSomeoneMentioned ? this.createMentionsList(html) : null;

    let copiedHtml = replacedHtml
      .replace(
        /<a\shref="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})"\starget="_blank">/gi,
        '',
      )
      .replace(/(<\/a>)/gi, '');

    const matchedResult = anchorme({
      input: copiedHtml,
      options: {
        attributes: {
          target: '_blank',
          class: 'linkText',
          fontWeight: 'normal',
        },
        specialTransform: [
          {
            test: /.*\.(png|jpg|gif)$/,
            transform: (s) => `<img src="${s.startsWith('http://') ? s : `http://${s}`}">`,
          },
        ],
      },
    });

    if (matchedResult !== null) {
      copiedHtml = matchedResult;
    }
    if (text && !this.props.textInPreview) {
      this.props.sendMessage(text, copiedHtml, mentionsList);
      const empty = EditorState.createEmpty(compositeDecorator);
      this.setState({
        editorState: EditorState.moveFocusToEnd(empty),
        showEmojiPicker: false,
      });
    }
  };

  insertTaggedName = (name: string) => {
    const { editorState, mentionKeyword } = this.state;

    let contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const block = contentState.getBlockForKey(selection.getAnchorKey());
    const blockKey = block.getKey();
    const startOffset = selection.getStartOffset();
    const contentStateWithEntity = contentState.createEntity('MENTION', 'IMMUTABLE');
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newSelection = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: startOffset - mentionKeyword.length - 1,
      focusKey: blockKey,
      focusOffset: selection.getAnchorOffset(),
    });

    contentState = Modifier.replaceText(contentState, newSelection, name, undefined, entityKey);

    this.setState(
      {
        editorState: EditorState.push(editorState, contentState, 'change-block-data'),
      },
      () => {
        this.focus();
        this.insert(' ');
      },
    );
  };

  addEmoji = (emoji: string) => {
    this.insert(`${emoji} `);
  };

  insert = (text: string) => {
    const { editorState } = this.state;

    const targetRange = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const content = Modifier.insertText(contentState, targetRange, text);
    this.setState(
      {
        editorState: EditorState.push(editorState, content, 'insert-characters'),
      },
      () => this.focus(),
    );
  };

  focus = () => {
    if (this.props.textInPreview != '') {
      null;
    } else {
      if (this.textInputRef && this.textInputRef.current !== null) {
        this.textInputRef.focus();
      }
    }
  };

  onChange = (editorState: EditorState) => {
    const anchorKey = editorState.getSelection().getAnchorKey();
    const currentContentBlock = editorState.getCurrentContent().getBlockForKey(anchorKey);
    this.getMentionKeyword(editorState);
    this.handleAtSign(currentContentBlock);
    this.setState({ editorState });

    if (this.state.clearField != this.chatStore.currRoomID && this.state.invokeClearStop === true) {
      this.setState({
        editorState: EditorState.createEmpty(compositeDecorator),
      });
    }
    if (this.state.clearChannel != this.chatStore.currChannelID && this.state.invokeClearStop === true) {
      this.setState({
        editorState: EditorState.createEmpty(compositeDecorator),
      });
    }
  };

  handleAtSign = (contentBlock: any) => {
    const text = contentBlock.getText();
    const lengthOfText = text.length;
    const { isTagPanelOpen } = this.state;
    const lastLetter = text.charAt(lengthOfText - 1);
    let atSignEntered = false;

    if (this.chatStore.getCurrentChatRoom().roomType === 'groupChat') {
      if (text.charAt(lengthOfText - 1) === '@' && lengthOfText !== 0) atSignEntered = true;
      else if (isTagPanelOpen && lengthOfText === 0) atSignEntered = false;
      else if (isTagPanelOpen && text.charAt(lengthOfText - 1) !== ' ') return;

      if (lastLetter === '@' && lengthOfText !== 0) {
        atSignEntered = true;
      } else if (isTagPanelOpen) {
        if (lengthOfText === 0) {
          atSignEntered = false;
        } else if (lastLetter !== ' ') return;
      }
    }

    this.setState({
      isTagPanelOpen: atSignEntered,
    });
  };

  keyBindingFn = (e: any) => {
    if (e.shiftKey && e.key === 'Enter') return 'split-block';
    if (e.altKey && e.key === 'Enter') return 'split-block';
    if (e.key === 'Enter') return 'send';
    return getDefaultKeyBinding(e);
  };

  handleKeyCommand = (command: string, editorState: EditorState) => {
    if (command === 'send') {
      this.handleSend();
      return 'handled';
    }

    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  handlePastedText = (text: string, html?: string): DraftHandleValue => {
    const expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    console.log(text, html);
    if (text.indexOf('') != -1) {
      const newContent = Modifier.insertText(
        this.state.editorState.getCurrentContent(),
        this.state.editorState.getSelection(),
        text,
      );

      this.onChange(EditorState.push(this.state.editorState, newContent, 'insert-characters'));
    }
    if (text.match(expression)) {
      const newContent = Modifier.insertText(
        this.state.editorState.getCurrentContent(),
        this.state.editorState.getSelection(),
        text,
      );
    }
    return 'handled';
  };

  getCurrentBlock = (editorState: EditorState) => {
    const currentSelection = editorState.getSelection();
    const blockKey = currentSelection.getStartKey();
    return editorState.getCurrentContent().getBlockForKey(blockKey);
  };

  getCurrentLetter(editorState: EditorState) {
    const currentBlock = this.getCurrentBlock(editorState);
    const blockText = currentBlock.getText();
    return blockText[editorState.getSelection().getStartOffset() - 1];
  }

  getMentionKeyword(editorState: EditorState) {
    const { startingPoints } = this.state;
    const currentContent = this.getCurrentBlock(editorState);
    const text = currentContent.getText();
    const length = currentContent.getLength();
    const keyword = startingPoints ? text.substr(startingPoints) : null;

    if (!startingPoints) this.setState({ startingPoints: length - 1 });

    if (this.state.isTagPanelOpen && keyword) this.setState({ mentionKeyword: keyword });
    else if (!this.state.isTagPanelOpen) this.setState({ mentionKeyword: '', startingPoints: null });
  }

  filterMemberListByKey = (memberList: User[], editorState: EditorState): User[] => {
    const { mentionKeyword } = this.state;
    const lowerKeyword = mentionKeyword.toLowerCase();
    return memberList.filter((target) => {
      const { firstName, lastName } = target;
      const lowerFirtsName = firstName.toLowerCase();
      const lowerlastName = lastName.toLowerCase();
      return lowerFirtsName.indexOf(lowerKeyword) === 0 || lowerlastName.indexOf(lowerKeyword) === 0;
    });
  };

  createUserList = (editorState: EditorState) => {
    const userArr: User[] = [];

    userArr.push({
      firstName: 'all',
      lastName: '',
      userThumbnailURL: PRODUCTION_BASELOGO_URL,
      uid: '',
      majorName: '',
      schoolName: '',
    });

    this.chatStore.participantsForMention.map((member: User) => {
      userArr.push(member);
    });

    return this.filterMemberListByKey(userArr, editorState);
  };

  render() {
    const { showEmojiPicker, showCrossInEmojiPicker, isTagPanelOpen, editorState } = this.state;
    const isMember = this.chatStore.isMemberOfRoom(this.chatStore.currRoomID, this.rootStore.getUserUID());
    const isFollowing = this.chatStore.isFollower(this.chatStore.currRoomID, this.rootStore.getUserUID());
    const memberList = this.createUserList(editorState);
    if (!isMember) {
      if (isFollowing) {
        return (
          <button className="btn--unfollow" onClick={() => this.unFollowChatRoom(this.chatStore.currRoomID)}>
            Unfollow
          </button>
        );
      }
      return (
        <button className="btn--follow" onClick={() => this.followChatRoom(this.chatStore.currRoomID)}>
          Follow
        </button>
      );
    }

    return (
      <>
        <div className="footer-wrapper">
          <button className="btn--icon" onClick={() => this.toggleEmojiPicker()}>
            <FontAwesomeIcon icon={faSmile} style={{ fontSize: 25 }} />
          </button>
          <Editor
            editorState={this.state.editorState}
            onChange={(e: EditorState) => this.onChange(e)}
            handlePastedText={this.handlePastedText}
            keyBindingFn={this.keyBindingFn}
            handleKeyCommand={this.handleKeyCommand}
            placeholder="Enter some messages..."
            ariaLabel="With textarea"
            ref={(editor: Editor | null) => (this.textInputRef = editor)}
            spellCheck={true}
            stripPastedStyles={true}
            readOnly={false}
            onTab={(e: any) => {
              if (memberList.length === 1) {
                e.preventDefault();
                this.handleTaggedName(memberList[0]);
              }
            }}
          />
          <button
            className="btn--icon"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.props.handleFileUpload(e)}
          >
            <FontAwesomeIcon icon={faPaperclip} style={{ fontSize: 25 }} />
          </button>
        </div>
        {showEmojiPicker && (
          <EmojiPickerWrapper
            addEmoji={this.addEmoji}
            showEmojiPicker={showEmojiPicker}
            showCrossInEmojiPicker={showCrossInEmojiPicker}
            handleClickCrossButton={() => this.setState({ showEmojiPicker: false, showCrossInEmojiPicker: false })}
            isReplyWrapperOpen={this.props.isReplyWrapperOpen}
          />
        )}
        {isTagPanelOpen && (
          <TagPanel
            memberList={this.createUserList(editorState)}
            panelClose={() => this.setState({ isTagPanelOpen: false })}
            taggedName={(user: User) => this.handleTaggedName(user)}
            mentionKeyword={this.state.mentionKeyword}
          />
        )}
      </>
    );
  }
}

export default ChatPageFooter;
