import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faFileExcel,
  faFilePowerpoint,
  faFileWord,
  faFileArchive,
  faFilePdf,
  faChartBar,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import {
  FormControl,
  Button,
  Col,
  Row,
  Image,
  ListGroup,
  ListGroupItem,
  Modal,
  Card,
  Table,
  Alert,
  Badge,
  Spinner,
} from 'react-bootstrap';
import './css/ChatPage.css';
import { Link } from 'react-router-dom';
import { ETIME } from 'constants';
import { median } from 'd3';
import BubbleList from './BubbleList';
import ChatPageFooter from './ChatPageFooter';
import ReplyMessageWrapper from './ReplyMessageWrapper';
import { PRODUCTION_TRANSPARENT_LOGO_URL } from '../../utils/Constants';
import NavBar from './NavBar';

interface State {
  height: any;
  openChatDetail: any;
  closeNotification: boolean;
  modal: boolean;
  textInPreview: string;
  file: any;
  isShowRanking: boolean;
  isShowPreview: boolean;
  colorTheme: boolean;
  arrPhoto: any;
  url: string;
  title: string;
  images: string;
  urlChecker: any;
  reply: {
    quotedMsgId: string;
    showWrapper: boolean;
  };
}

interface Props {
  rootStore?: any;
  schoolList: any;
  channelID: any;
  openChatPage: any;
  onSend: (text: string, html: string | null, reply: string | null, mention: string[] | null) => void;
}

@inject('rootStore')
@observer
class ChatPage extends Component<Props, State> {
  private rootStore: any;

  private chatStore: any;

  private authStore: any;

  private sidebarStore: any;

  private prevScrollHeight: any;

  private initialScrollHeight: any;

  private inTimeout = false;

  private footerRef: any;

  constructor(props: any) {
    super(props);
    this.state = {
      height: props.height,
      openChatDetail: false,
      modal: false,
      closeNotification: false,
      textInPreview: '',
      file: null,
      isShowPreview: false,
      isShowRanking: false,
      arrPhoto: [],
      colorTheme: true, // true for dark theme
      url: '',
      title: '',
      images: '',
      urlChecker: [],
      reply: {
        quotedMsgId: '',
        showWrapper: false,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
    this.sidebarStore = this.rootStore.sidebarStore;
    this.prevScrollHeight = 0;
    this.initialScrollHeight = 0;
    this.footerRef = React.createRef();
  }

  private onEndReached = () => {
    const element = document.getElementById('MessageList');
    if (element) {
      if (element.scrollTop === 0 && !this.chatStore.isEarlierChatLoading) {
        this.chatStore.isEarlierChatLoading = true;
        if (!this.inTimeout) {
          this.inTimeout = true;
          setTimeout(() => {
            this.chatStore.loadEarlierMessages(this.chatStore.currRoomID);
            this.inTimeout = false;
          }, 1000);
        } else {
          this.chatStore.isEarlierChatLoading = false;
        }
      }
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (this.footerRef && this.chatStore.isInternetConnected) {
      this.footerRef.focus();
    }

    const messageContainer = document.getElementById('MessageList');
    if (this.prevScrollHeight !== 0 && this.chatStore.isChatReachTop) {
      if (messageContainer) {
        const scrollGap = messageContainer.scrollHeight - this.prevScrollHeight;
        messageContainer.scrollTo(0, scrollGap);
        this.prevScrollHeight = 0;
      }
    }

    if (!this.chatStore.isChatReachTop && this.chatStore.chatScroll) {
      this.scrollToBottom();
    }

    if (this.props.channelID !== prevProps.channelID) {
      this.closeChannelText();
      this.initialScrollHeight = 0;
      this.setState({
        reply: {
          quotedMsgId: '',
          showWrapper: false,
        },
      });
      if (this.sidebarStore.isChatDetailOpen) {
        this.sidebarStore.closeChatDetail();
      }
    }

    if (this.initialScrollHeight === 0) {
      if (messageContainer) {
        this.prevScrollHeight = messageContainer.scrollHeight;
      }
    }

    if (this.chatStore.isChatReachTop) {
      this.chatStore.isChatReachTop = false;
    }
  }

  private scrollToBottom = () => {
    const element = document.getElementById('MessageList');
    if (element) {
      const { scrollHeight } = element;
      const height = element.clientHeight;
      const maxScrollTop = scrollHeight - height;
      element.scrollTo(0, maxScrollTop);
    }
  };

  private modalHide = () => {
    this.setState({ isShowPreview: false, isShowRanking: false, textInPreview: '' });
  };

  private updateInputOnPreview = (e: any) => {
    this.setState({ textInPreview: e.target.value });
    // if (text.charAt(text.length - 1) === '@') {
    //     this.setState({ isShowTagPanel: true });
    // } else if (text.charAt(text.length - 1) === ! '@') {
    //     this.setState({ isShowTagPanel: false });
    // }
  };

  private handleFileUpload = (e: any) => {
    e.preventDefault();
    this.fileSelector().click();
  };

  private fileSelector = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.onchange = (e: any) => {
      this.handleChange(e.target.files);
    };
    return fileSelector;
  };

  private handleChange = (selectorFiles: FileList) => {
    let preview;
    if (selectorFiles[0].type.split('/')[0] === 'application') {
      if (selectorFiles[0].size > 52428800) {
        alert('Over 50MB file is not supported ');
        return;
      }
      const extensionArr = selectorFiles[0].name.split('.');
      const extension = extensionArr[extensionArr.length - 1];

      if (extension.startsWith('xl')) {
        preview = 'excel';
      } else if (extension.startsWith('pp')) {
        preview = 'ppt';
      } else if (extension.startsWith('pdf')) {
        preview = 'pdf';
      } else if (extension.startsWith('doc')) {
        preview = 'doc';
      } else if (extension.startsWith('zip')) {
        preview = 'zip';
      }
    } else if (selectorFiles[0].type.split('/')[0] === 'image') {
      preview = URL.createObjectURL(selectorFiles[0]);
    } else if (selectorFiles[0].type === 'video/mp4') {
      preview = URL.createObjectURL(selectorFiles[0]);
    } else {
      alert('The file is not supported');
      return;
    }
    if (selectorFiles) {
      this.setState({ isShowPreview: true, file: preview, arrPhoto: selectorFiles });
    }
  };

  private setQuote = (message: { _id: string }) => {
    this.setState({
      reply: {
        quotedMsgId: message._id,
        showWrapper: true,
      },
    });
    if (this.footerRef) {
      this.footerRef.focus();
    }
  };

  private closeReplyWrapper = () => {
    this.setState({
      reply: {
        quotedMsgId: '',
        showWrapper: false,
      },
    });
  };

  private closeChannelText = () => {
    this.setState({
      textInPreview: '',
    });
  };

  private truncateTopic = (topic: any) => {
    if (topic.length > 6) {
      topic = topic.substring(0, 6);
      topic += '...';
    }
    return <span className="mr-1">{topic}</span>;
  };

  private renderDetail = () => {
    if (this.state.isShowPreview === true) {
      null;
    } else {
      const roomInfo = this.chatStore.getCurrentChatRoom();
      const topic = roomInfo.topic || 'No topic';
      const channelName = roomInfo.channels[this.chatStore.currChannelID].name;
      return (
        <Row className="chatroom-title h-100 mx-auto col-12">
          <Col className="d-flex align-items-center col-9 chatroom-title">
            <Link to="/home">
              <Button variant="link" className="backbtn-space" onClick={this.props.openChatPage}>
                <FontAwesomeIcon className="backbtn-icon" icon={faArrowLeft} />
              </Button>
            </Link>
            <Alert
              className="bgGray-4 colorWhite"
              style={{ borderRadius: '50px', padding: '8px 8px', marginBottom: 0 }}
            >
              <span className="mr-1">
                {window.matchMedia('(min-width: 768px)').matches ? topic : this.truncateTopic(topic)}
              </span>
              <Badge variant="secondary">{channelName}</Badge>
            </Alert>
          </Col>
          <Col className="d-flex justify-content-end align-items-center col-3 icon-style">
            <Button
              onClick={this.sidebarStore.openChatStats}
              variant="link"
              style={{ textDecoration: 'none' }}
              className="btn-space-left"
            >
              <FontAwesomeIcon className="backbtn-icon" icon={faChartBar} />
            </Button>
            <Button onClick={this.sidebarStore.openChatDetail} variant="link" style={{ textDecoration: 'none' }}>
              <FontAwesomeIcon className="backbtn-icon" icon={faInfoCircle} />
            </Button>
          </Col>
        </Row>
      );
    }
  };

  private sendMessage = (textMsg: string, html: string | null, mention: string[] | null) => {
    if (textMsg) {
      if (textMsg.length < 6479) {
        if (!this.state.textInPreview) {
          this.props.onSend(textMsg, html, this.state.reply.quotedMsgId, mention);
          this.setState({
            reply: {
              quotedMsgId: '',
              showWrapper: false,
            },
          });
        } else {
          this.props.onSend(this.state.textInPreview, null, null, mention);
          this.setState({ textInPreview: '' });
        }
      } else {
        alert('Your message length cannot exceed 6479 characters');
      }
    }
    this.chatStore.chatScroll = true;
    this.scrollToBottom();
  };

  private closePhotoModal = () => {
    this.setState({ textInPreview: '' });
    for (let i = 0; i < this.state.arrPhoto.length; i++) {
      if (this.state.arrPhoto[i].type === 'video/mp4') {
        this.chatStore.sendChatMessage(
          this.chatStore.currRoomID,
          this.generateMediaMessageObj(this.state.arrPhoto[i], this.rootStore.getUserUID(), 'Video'),
        );
      } else if (this.state.arrPhoto[i].type.split('/')[0] === 'application') {
        this.chatStore.sendChatMessage(
          this.chatStore.currRoomID,
          this.generateMediaMessageObj(this.state.arrPhoto[i], this.rootStore.getUserUID(), 'Document'),
        );
      } else {
        this.chatStore.sendChatMessage(
          this.chatStore.currRoomID,
          this.generateMediaMessageObj(this.state.arrPhoto[i], this.rootStore.getUserUID(), 'Photo'),
        );
      }
    }
    this.modalHide();
    this.setState({ arrPhoto: [] });
  };

  private generateMediaMessageObj(media: any, senderID: any, type: any) {
    media.attachmentType = type;
    const object = {
      _id: this.rootStore.generateMessageID(this.chatStore.currRoomID),
      text: this.state.textInPreview === '' ? null : this.state.textInPreview,
      localAttachments: [media],
      senderID,
      type,
      channelID: this.props.channelID,
    };
    if (type === 'Document' && object.text === null) {
      object.text = 'Document';
    } else if (type === 'Photo' && object.text === null) {
      object.text = 'Photo';
    } else if (type === 'Video' && object.text === null) {
      object.text = 'Video';
    }
    return object;
  }

  private openRanking = () => {
    this.setState({ isShowRanking: true });
  };

  private renderSpinner = () => {
    if (this.chatStore.connecting) {
      return (
        <div className="indicator" style={{ justifyContent: 'center' }}>
          <Spinner animation="border" variant="light">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      );
    }
  };

  private renderConnectMessage = () => {
    if (!navigator.onLine) {
      return (
        <div
          id="mainDiv"
          className="col-xs-12 h-100"
          style={{ color: 'white', backgroundColor: '#5D7F9D', padding: 0 }}
        >
          <h2>
            Internet has been disconnected. Please reconnect to your network{' '}
            <Badge pill variant="warning">
              Attention
            </Badge>
          </h2>
        </div>
      );
    }
    if (this.chatStore.maxTimerRefreshed) {
      return (
        <div
          id="mainDiv"
          className="col-xs-12 h-100"
          style={{ color: 'white', backgroundColor: '#5D7F9D', padding: 0 }}
        >
          <h2>
            You have been inactive for too long!{' '}
            <Button variant="warning" href="javascript:location.reload(true)">
              Click to Refresh
            </Button>
          </h2>
        </div>
      );
    }
    if (this.chatStore.connecting) {
      return (
        <div
          id="mainDiv"
          className="col-xs-12 h-100"
          style={{ color: 'white', backgroundColor: '#5D7F9D', padding: 0 }}
        >
          <h2>
            Connection back on!{' '}
            <Badge pill variant="success">
              Reconnected
            </Badge>
          </h2>
          {this.renderSpinner()}
        </div>
      );
    }
    return (
      <div id="mainDiv" className="col-xs-12 h-100" style={{ color: 'white', backgroundColor: '#5D7F9D', padding: 0 }}>
        <h2>
          <Badge pill variant="danger">
            Disconnected
          </Badge>{' '}
          Reconnecting...
        </h2>
      </div>
    );
  };

  render() {
    let previewAddress;
    if (this.state.file) {
      previewAddress = this.state.file.startsWith('blob');
    }

    let fileIcon;
    if (this.state.file) {
      switch (this.state.file) {
        case 'excel':
          fileIcon = (
            <FontAwesomeIcon
              icon={faFileExcel}
              style={{ marginTop: 20, fontSize: '100', color: '#56d28b', textAlign: 'center' }}
            />
          );
          break;
        case 'pdf':
          fileIcon = <FontAwesomeIcon icon={faFilePdf} style={{ marginTop: 20, fontSize: '100', color: '#f34646' }} />;
          break;
        case 'doc':
          fileIcon = (
            <FontAwesomeIcon
              icon={faFileWord}
              style={{ margin: 'auto', marginTop: 20, fontSize: '100', color: '#60a9f6' }}
            />
          );
          break;
        case 'ppt':
          fileIcon = (
            <FontAwesomeIcon
              icon={faFilePowerpoint}
              style={{ margin: 'auto', marginTop: 20, fontSize: '100', color: '#f34646' }}
            />
          );
          break;
        case 'zip':
          fileIcon = (
            <FontAwesomeIcon
              icon={faFileArchive}
              style={{
                margin: 'auto',
                marginTop: 20,
                fontSize: '100',
                color: '#ffffff',
                background: '#000000',
                // border: '2px solid #000000',
                borderRadius: 5,
                borderTopRightRadius: 10,
              }}
            />
          );
          break;
      }
    }

    if (!this.chatStore.isInternetConnected) {
      return (
        <div
          id="mainDiv"
          className="col-xs-12 h-100"
          style={{ color: 'white', backgroundColor: '#5D7F9D', padding: 0 }}
        >
          {this.renderConnectMessage()}
        </div>
      );
    }
    const getHeight = window.innerHeight - 54;
    const height = window.matchMedia('(min-width: 768px)').matches ? window.innerHeight : getHeight;
    return (
      <div className="chatDiv bg-dark h-100" style={{ height }}>
        <div style={{ paddingRight: 0, paddingLeft: 0, top: 0, minHeight: 54 }}>
          <NavBar openChatPage={this.props.openChatPage} />
        </div>
        <div
          id="MessageList"
          // className='bgGray-3'
          onScroll={() => this.onEndReached()}
          style={{ display: 'block', overflowY: 'auto' }}
        >
          <BubbleList
            messages={this.chatStore.messagesInCurrentChannel}
            colorTheme={this.state.colorTheme}
            setReply={this.setQuote}
          />
        </div>

        <div
          className="chatFooterDiv col-12 p-0"
          style={{ height: 75, backgroundColor: '#2E302D', paddingBottom: 0, bottom: 0 }}
        >
          <ChatPageFooter
            channelID={this.props.channelID}
            textInPreview={this.state.textInPreview}
            sendMessage={this.sendMessage}
            isReplyWrapperOpen={this.state.reply.showWrapper}
            handleFileUpload={this.handleFileUpload}
            ref={(component: React.Component | null) => (this.footerRef = component)}
          />
          {this.state.reply.showWrapper && (
            <ReplyMessageWrapper
              messageId={this.state.reply.quotedMsgId}
              open={this.state.reply.showWrapper}
              close={() => this.closeReplyWrapper()}
            />
          )}
        </div>
        <Modal show={this.state.isShowPreview} onHide={this.modalHide}>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card style={{ borderRadius: 10, textAlign: 'center' }} className="col-12">
              {previewAddress ? (
                <Card.Img variant="top" src={this.state.file} />
              ) : (
                <div>
                  {fileIcon}
                  <Card.Text>{this.state.arrPhoto[0] ? this.state.arrPhoto[0].name : null}</Card.Text>
                </div>
              )}
            </Card>
            <FormControl
              className="col-12"
              as="textarea"
              aria-label="With textarea"
              placeholder="Enter some messages..."
              onChange={this.updateInputOnPreview}
              value={this.state.textInPreview}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closePhotoModal()}>Upload</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const OPACITY_COLORS = ['#016189', '#007dad', '#6ea0bb', '#9bbbc8', '#cddae4'];

export default ChatPage;
