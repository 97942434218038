import _ from 'lodash';
import { addLeadingSlash } from 'history/PathUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faCameraRetro,
  faLink,
  faFolderOpen,
  faFileExcel,
  faFilePowerpoint,
  faFileWord,
  faFileArchive,
  faFilePdf,
  faArrowAltCircleDown,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
import { values } from 'mobx';
import { Context } from 'vm';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Button,
  Tab,
  Container,
  Row,
  Col,
  Image,
  Jumbotron,
  ListGroup,
  ListGroupItem,
  Nav,
  Card,
  Media,
  Modal,
} from 'react-bootstrap';
import { toJS } from 'mobx';
import { any } from 'prop-types';
import firebase from 'firebase';
import { Redirect } from 'react-router';
import LightBox from 'react-image-lightbox';
import { ImageStore } from 'react-native';
import { POINT_CONVERSION_UNCOMPRESSED } from 'constants';
import moment from 'moment';
import Linkify from 'react-linkify';
import RootStore from '../../stores/RootStore';
import { WEBSOCKET_COMMAND_TYPE } from '../../utils/Constants';
import ChatDetailPage from './ChatDetailPage';

interface State {
  pictureResource: any;
  index: any;
  showModal: boolean;
  Hide: any;
  URL: any;
  arrayImages: any;
  arrayLinks: any;
  Left: any;
  Right: any;
}

interface Props {
  roomID: string;
  chatStore?: any;
  authStore?: any;
  rootStore?: any;
  onClickMedia?: any;
  getChatMedia?: any;
  getUrlsFromText?: any;
  data: any;
}

@inject('rootStore')
@observer
class MediaDocs extends React.Component<Props, State> {
  private rootStore: any = {};
  private authStore: any = {};
  private mediaFiles: any = {};
  private chatStore: any = {};
  constructor(props: any) {
    super(props);
    this.rootStore = this.props.rootStore;
    this.authStore = this.rootStore.authStore;
    this.chatStore = this.rootStore.chatStore;
    this.state = {
      pictureResource: '',
      index: 0,
      showModal: false,
      Hide: this.HandleClose,
      URL: this.chatStore.elementOfMediaArr,
      arrayImages: [],
      arrayLinks: [],
      Left: Boolean,
      Right: Boolean,
    };
  }

  public formatDate = (dateObj: any) => {
    dateObj = new Date(dateObj);
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const date = dateObj.getDate();
    const month = monthNames[dateObj.getMonth()];
    const year = dateObj.getFullYear();
    return `${month} ${date}, ${year}`;
  };
  private convertToLocalTime = (time: any) => {
    return moment(time).local().toDate();
  };
  private renderDate = (timestamp: any) => {
    const messageDate = this.convertToLocalTime(timestamp);
    return this.formatDate(messageDate);
  };
  renderImageAttachments = (attachmentData: any) => {
    const imageAr: any = [];
    if (_.size(attachmentData) > 0) {
      _.each(attachmentData, (value, index) => {
        if (typeof value.attachments != 'undefined') {
          this.state.arrayImages.push(value.attachments[0].originalURL);
          imageAr.push(
            <Container
              key={value._id}
              className="col-sm-3"
              style={{ display: 'inline-block', width: '100px', height: '100px', padding: '2px' }}
            >
              <Row
                className=""
                style={{ display: '', width: '100%', height: '100%', padding: '2px', borderRadius: '8px' }}
              >
                <Image
                  id="Image"
                  className="active tab-active"
                  style={{ position: 'absolute', height: '100px', width: '100px', padding: '5px' }}
                  src={value.attachments[0].originalURL}
                  onClick={() => {
                    this.HandleClick(index);
                  }}
                />
              </Row>
            </Container>,
          );
        }
      });
      return imageAr;
    } else {
      return <div style={{ textAlign: 'center', marginTop: 30, color: '#D1CDC7' }}>No Media Files</div>;
    }
  };
  private downloadFile = (link: string) => {
    window.open(link);
  };
  private renderDocBubble = (msgObj: any) => {
    const nameArr = msgObj.attachments[0].name.split('_');
    let fileName = '';
    let iconName;
    let iconStyle;
    for (let i = 2; i < nameArr.length; i++) {
      if (fileName !== '') {
        fileName = fileName + '_' + nameArr[i];
      } else {
        fileName = fileName + nameArr[i];
      }
    }
    const iconNameArr: any = fileName.split('.');
    const isUpperCase = (string: any) => /^[A-Z]*$/.test(string);
    let upperCaseCounter = 0;
    _.each(fileName, (item) => {
      if (isUpperCase(item)) {
        upperCaseCounter++;
      }
    });
    if (fileName.length > 20) {
      if (upperCaseCounter > 15) {
        fileName = fileName.slice(0, 15) + '...';
      } else {
        fileName = fileName.slice(0, 25) + '...';
      }
    }
    switch (iconNameArr[iconNameArr.length - 1]) {
      case 'docx':
        iconName = faFileWord;
        iconStyle = { fontSize: '30', color: '#60a9f6' };
        break;
      case 'xlsx':
        iconName = faFileExcel;
        iconStyle = { fontSize: '30', color: '#56d28b' };
        break;
      case 'pptx':
        iconName = faFilePowerpoint;
        iconStyle = { fontSize: '30', color: '#f34646' };
        break;
      case 'pdf':
        iconName = faFilePdf;
        iconStyle = { fontSize: '30', color: '#f34646' };
        break;
      case 'zip':
        iconName = faFileArchive;
        iconStyle = {
          fontSize: '30',
          color: '#ffffff',
          background: '#000000',
          border: '1px solid #000000',
          borderRadius: 3,
        };
        break;
    }
    return (
      <div style={{ background: '#ffffff', padding: 10, margin: 3, borderRadius: 10 }}>
        <Row
          style={{ cursor: 'pointer' }}
          onClick={() => {
            this.downloadFile(msgObj.attachments[0].url);
          }}
        >
          <Col>
            <Row>
              <Col md="auto">
                {iconName ? (
                  <FontAwesomeIcon icon={iconName} style={iconStyle} />
                ) : (
                  <FontAwesomeIcon icon={faFile} style={{ fontSize: '30', color: '#000000' }} />
                )}
              </Col>
              <Col style={{ paddingLeft: 0, lineHeight: '30px', color: '#000000' }}>
                <Card.Text>{fileName}</Card.Text>
              </Col>
            </Row>
          </Col>
          <Col md="auto" style={{ float: 'right' }}>
            <FontAwesomeIcon
              icon={faArrowAltCircleDown}
              style={{
                borderRadius: 15,
                fontSize: '30',
                color: '#ffffff',
                border: '1px solid #7b7b7b',
                background: '#7b7b7b',
              }}
            />
          </Col>
        </Row>
      </div>
    );
  };
  renderMyDocs = (docFiles: any) => {
    if (_.size(docFiles) > 0) {
      const myDocuments = docFiles.map((myDocuments: any) => (
        <ListGroup as="ul" style={{ overflowX: 'hidden', width: '100%', padding: '5px 0' }} key={myDocuments._id}>
          <ListGroup.Item action href="" style={{ backgroundColor: '#e6e2e1', borderWidth: '0', padding: 5 }}>
            {this.renderDocBubble(myDocuments)}
          </ListGroup.Item>
        </ListGroup>
      ));
      return myDocuments;
    } else {
      return <div style={{ textAlign: 'center', marginTop: 30, color: '#D1CDC7' }}>No Docs</div>;
    }
  };
  renderMyLinks = (LinkAttachments: any) => {
    const LinkAr: any = [];
    const check = ['web.base.town'];
    const expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    _.each(LinkAttachments, (link, index) => {
      if (link.text != null && link.type != 'Photo') {
        const slicedArr = LinkAttachments.slice(1, 15);
        if (link.text.match(expression) || link.text.match(check)) {
          slicedArr.push(link.text);
          LinkAr.push(
            <Container
              className="col-12 block"
              style={{ border: '1px solid #234623', padding: '1px', borderBottom: '1px solid #8B8173' }}
            >
              <Row className="block" style={{ padding: '1px', fontSize: '10px' }}>
                <Row className="col-12 block" style={{ margin: 'auto', fontSize: 6, color: '#234623' }}>
                  {/* <Linkify properties={{ target: '_blank', style: { fontWeight: 'bold', fontSize: 10 } }}> */}
                  <Linkify>
                    <Row className="block" style={{ fontSize: 12, margin: 'auto', border: '1px', padding: '1px' }}>
                      {link.text}
                    </Row>
                  </Linkify>
                </Row>
                <Row className="block" style={{ fontSize: 7, marginLeft: 20, color: '#D1CDC7' }}>
                  {this.renderDate(link.createdAt)}
                </Row>
                <Row className="block" style={{ fontSize: 10, marginLeft: 35, fontWeight: 'bold', color: '#D1CDC7' }}>
                  {'By'} {link.sender && link.sender.name}
                </Row>
              </Row>
            </Container>,
          );
        } else if (link.text != null && link.text != link.text.match(expression)) {
        } else if ((link.text = null)) {
          {
            ('No Links');
          }
        }
      }
    });
    return LinkAr;
  };
  public HandleClick = (index: any) => {
    this.chatStore.elementOfMediaArr;
    this.setState({
      showModal: true,
      pictureResource: toJS(this.chatStore.elementOfMediaArr[index].attachments[0].originalURL),
      index: index,
      Left: true,
      Right: true,
    });
  };
  public HandleClose = () => {
    this.setState({ showModal: false });
  };
  render() {
    return (
      <div>
        <Tab.Container defaultActiveKey="MediaLink" id="tabs">
          <div className="col-12 float-left" style={{ width: '100%', display: 'inline-block' }}>
            <Nav
              fill
              variant="tabs"
              style={{ display: 'inline-flex flex-column tab-active', padding: '0', margin: 'auto' }}
            >
              <Nav.Item style={{ padding: '0', margin: 'auto', display: 'active' }}>
                <Nav.Link
                  eventKey="MediaLink"
                  style={{ borderTopLeftRadius: '1rem', fontWeight: 'bold', fontSize: '2rem', lineHeight: '10rem' }}
                >
                  <FontAwesomeIcon icon={faCameraRetro} style={{ color: '#3694FE', fontSize: '25' }} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="DocumentLink"
                  style={{ borderTopLeftRadius: '1rem', fontWeight: 'bold', fontSize: '2rem', lineHeight: '10rem' }}
                >
                  <FontAwesomeIcon icon={faFolderOpen} style={{ color: '#3694FE', fontSize: '25' }} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="JustLink"
                  style={{ borderTopLeftRadius: '1rem', fontWeight: 'bold', fontSize: '2rem', lineHeight: '10rem' }}
                >
                  <FontAwesomeIcon icon={faLink} style={{ color: '#3694FE', fontSize: '25' }} />
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content
              className="flex-row d-inline-flex d-flex"
              style={{ display: 'none', padding: '0', marginBottom: '20px', width: '100%' }}
            >
              <Tab.Pane id="media" eventKey="MediaLink">
                {this.renderImageAttachments(toJS(this.chatStore.elementOfMediaArr))}
                <p>Feature is currently down for maintainence</p>
              </Tab.Pane>
              <Tab.Pane eventKey="DocumentLink" style={{ width: '100%' }}>
                {this.renderMyDocs(toJS(this.chatStore.elementOfDocArr))}
                <p>Feature is currently down for maintainence</p>
              </Tab.Pane>
              <Tab.Pane eventKey="JustLink">
                {this.renderMyLinks(this.chatStore.getChatMessages(this.chatStore.currRoomID))}
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
        <Container>
          {this.state.showModal && (
            <LightBox
              mainSrc={toJS(this.chatStore.elementOfMediaArr[this.state.index].attachments[0].originalURL)}
              nextSrc={
                this.state.Right
                  ? toJS(this.chatStore.elementOfMediaArr[this.state.index].attachments[0].originalURL)
                  : undefined
              }
              prevSrc={
                this.state.Left
                  ? toJS(this.chatStore.elementOfMediaArr[this.state.index].attachments[0].originalURL)
                  : undefined
              }
              onCloseRequest={() => this.setState({ showModal: false })}
              onMovePrevRequest={() => {
                if (this.state.index === 0) {
                  this.setState({
                    // showModal: false
                    Left: false,
                  });
                } else {
                  this.setState({
                    index: this.state.index - 1,
                    Left: true,
                    Right: true,
                  });
                }
              }}
              onMoveNextRequest={() => {
                if (this.state.index === this.chatStore.elementOfMediaArr.length - 1) {
                  this.setState({
                    index: this.state.index,
                    Right: false,
                  });
                } else {
                  this.setState({
                    index: this.state.index + 1,
                    Right: true,
                    Left: true,
                  });
                }
              }}
            />
          )}
        </Container>
      </div>
    );
  }
}
export default MediaDocs;
