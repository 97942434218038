import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, InputGroup, FormControl, Modal } from 'react-bootstrap';

interface State {
  dmText: string;
}

interface Props {
  rootStore?: any;
  fromUserProfile?: boolean;
}

const primaryColor = '#5D7F9D';

@inject('rootStore')
@observer
class DMForm extends Component<Props> {
  state: State;
  chatStore: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      dmText: '',
    };
    this.chatStore = this.props.rootStore.chatStore;
  }

  handleHide = () => {
    this.chatStore.resetDMData();
    this.chatStore.setShowDMForm(false);
  };

  handleClickSend = () => {
    this.chatStore.sendDM(this.state.dmText);
    this.setState({ dmText: '' });
    this.chatStore.setShowDMForm(false);
    this.chatStore.closeUserModal();
  };

  FormBody = () => {
    return (
      <div className="m-auto text-center">
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Enter a message"
            value={this.state.dmText}
            onChange={(e: any) => this.setState({ dmText: e.target.value })}
          />
          <InputGroup.Append>
            <Button
              style={{ backgroundColor: primaryColor, border: '0px' }}
              disabled={!this.state.dmText}
              onClick={() => this.handleClickSend()}
            >
              Send
            </Button>
          </InputGroup.Append>
        </InputGroup>
        <Button variant="danger" style={{ border: '0px' }} block onClick={this.handleHide}>
          Close DM
        </Button>
      </div>
    );
  };

  render() {
    return this.props.fromUserProfile ? (
      this.chatStore.showDMForm ? (
        <Modal show={this.chatStore.showDMForm} onHide={this.handleHide} size="sm" centered>
          <Modal.Header className="py-2 border-bottom-0" closeButton />
          <Modal.Body className="pt-2">
            <this.FormBody />
          </Modal.Body>
        </Modal>
      ) : null
    ) : (
      <this.FormBody />
    );
  }
}

export default DMForm;
