import React, { Component } from 'react';
import { Dropdown, Image, InputGroup, FormControl, Button } from 'react-bootstrap';
import './css/UserList.css';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSlidersH, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import UserProfile from './UserProfile';
import { BASENAME_URL } from '../../utils/Constants';
import SearchedUserList from './SearchedUserList';

interface Props {
  rootStore: any;
  authStore: any;
  userData: any;
  match: any;
}
interface State {
  showExploreList: any;
  search: any;
  userData: any;
}

@inject('rootStore')
@observer
class UserList extends React.Component<Props, State> {
  public state: State;
  private rootStore: any;
  private authStore: any;
  private inTimeout: boolean = false;
  private searchedListRef: any;

  constructor(props: any) {
    super(props);

    this.state = {
      showExploreList: true,
      search: '',
      userData: null,
    };

    this.rootStore = this.props.rootStore;
    this.authStore = this.rootStore.authStore;
    this.searchedListRef = React.createRef();
  }

  private onchange = (e: any) => {
    this.setState({
      search: e.target.value,
    });

    if (!this.inTimeout) {
      this.inTimeout = true;
      setTimeout(() => {
        this.submitSearch();
        this.inTimeout = false;
      }, 800);
    }
  };

  private submitSearch = () => {
    const searchKeyword: String = this.state.search.toLowerCase();
    this.searchedListRef.current.submitSearch(searchKeyword);
  };

  private onClickUser = (userData: any) => {
    this.setState({ userData });
  };

  private renderUserProfile = () => {
    if (this.state.userData) {
      return (
        <div style={{ zIndex: 1 }}>
          <UserProfile match={this.props.match} />
        </div>
      );
    } else {
      return (
        <div className="d-flex align-items-center justify-content-center bgGray-1" style={{ height: '100%' }}>
          <Image src={BASENAME_URL} />
        </div>
      );
    }
  };

  // private renderSearchOption = () => {
  //     return (
  //         <div style={{ zIndex: 1 }} className='container'>
  //             <div className='row w-100 p-1 h-100'>
  //                 <Dropdown className="col-3" >
  //                     <Dropdown.Toggle className="col 3 w-100" style={{ backgroundColor: "#2b62c6", borderColor: "#2b62c6" }} variant="success" id="dropdown-basic">
  //                         University
  //                             </Dropdown.Toggle>
  //                     <Dropdown.Menu style={{ width: "90%", height: 200, overflowX: "scroll" }}>
  //                         {SchoolNames.map((school: React.ReactNode) => (
  //                             <Dropdown.Item onClick={() => { this.filterBySchool(school) }} >{school}</Dropdown.Item>
  //                         ))}
  //                     </Dropdown.Menu>
  //                 </Dropdown>
  //                 <Dropdown className="col-3">
  //                     <Dropdown.Toggle className="col 3" style={{ backgroundColor: "#2b62c6", borderColor: "#2b62c6" }} variant="success" id="dropdown-basic">
  //                         Major
  //                             </Dropdown.Toggle>
  //                     <Dropdown.Menu style={{ width: "90%", height: 200, overflowX: "scroll" }}>
  //                         {MajorNames.map((major: React.ReactNode) => (
  //                             <Dropdown.Item onClick={() => { this.filterByMajor(major) }}>{major}</Dropdown.Item>
  //                         ))}
  //                     </Dropdown.Menu>
  //                 </Dropdown>
  //             </div>
  //         </div>
  //     );
  // }

  private renderSearchBar = () => {
    return (
      <InputGroup className="col-12 h-20" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Link to="/home">
          <Button variant="link" className="btn-space" style={{ textDecoration: 'none' }}>
            <FontAwesomeIcon className="icon-size" icon={faArrowLeft} style={{ color: 'white' }} />
          </Button>
        </Link>

        <InputGroup.Prepend>
          <InputGroup.Text style={{ background: 'white', color: 'gray', border: 'none' }}>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          placeholder="Search for a User"
          aria-label="User Search Bar"
          onChange={this.onchange}
          value={this.state.search}
          style={{ border: 'none' }}
        />
        <InputGroup.Prepend>
          {/* TODO: Implement search option  */}
          <Button
            variant="secondary"
            onClick={() => alert('This filter option is not available now. \nSorry for the inconvenience.')}
          >
            <FontAwesomeIcon icon={faSlidersH} />
          </Button>
        </InputGroup.Prepend>
      </InputGroup>
    );
  };

  render() {
    return (
      <div className="h-100 col-md-12 col-sm-12 mx-auto d-flex bgGray-1" id="mainDiv">
        <div className="col-xl-4 h-100 w-100 p-3 overflow-auto" style={{ zIndex: 0 }}>
          {this.renderSearchBar()}
          <SearchedUserList onClickUser={this.onClickUser} ref={this.searchedListRef} isHidden={false} />
        </div>
        <div className="col-8 col-xl-8 float-right mt-2 d-none d-sm-inline-block overflow-auto">
          {this.renderUserProfile()}
        </div>
      </div>
    );
  }
}

export default UserList;
