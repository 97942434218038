/* @flow */
import moment from 'moment';
import AuthStore from './AuthStore';
import ChatStore from './ChatStore';
import SidebarStore from './SidebarStore';
import {
  // MEMBER_STATUS,
  // MEMBER_LEVEL,
  // DEV_PACKAGE_NAME,
  // PROD_PACKAGE_NAME,
  RELEASE_MODE,
  CURRENT_RELEASE_MODE,
  // DEV_DYNAMIC_LINK_DOMAIN,
  // PROD_DYNAMIC_LINK_DOMAIN,
  STAGING_BASELOGO_URL,
  STAGING_TRANSPARENT_LOGO_URL,
  PRODUCTION_BASELOGO_URL,
  PRODUCTION_TRANSPARENT_LOGO_URL,
  STAGING_WEBSOCKET_URL,
  // PRODUCTION_WEBSOCKET_URL,
  // STAGING_FIREBASE_WEB_API_KEY,
  // PRODUCTION_FIREBASE_WEB_API_KEY,
  // PRODUCTION_ANDROID_MARKET_URL,
  // PRODUCTION_IOS_MARKET_URL,
  // STAGING_ANDROID_MARKET_URL,
  // STAGING_IOS_MARKET_URL,
} from '../utils/Constants';

export default class RootStore {
  public chatStore: ChatStore;
  public authStore: AuthStore;
  public sidebarStore: SidebarStore;
  public defaultLogoURL: string;

  constructor() {
    this.authStore = new AuthStore(this);
    this.chatStore = new ChatStore(this);
    this.sidebarStore = new SidebarStore(this);

    this.updateStore();
    this.defaultLogoURL = PRODUCTION_BASELOGO_URL;
  }

  public getUserUID = () => {
    const firebaseUser = this.authStore.getFirebaseUser();
    if (!firebaseUser) {
      return '';
    }
    return `user::${firebaseUser.uid}`;
  };

  public getSelfUser = () => this.authStore.selfUser;

  public updateStore = () => {
    this.authStore.chatStore = this.chatStore;
  };
  public isProduction = () => {
    console.info(CURRENT_RELEASE_MODE);
    if (CURRENT_RELEASE_MODE === RELEASE_MODE.PRODUCTION) {
      return true;
    }
    return false;
  };

  public generateMessageID = (roomID: string | undefined) => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15); // this.authStore.sendMessageToServer({ command: WEBSOCKET_COMMAND_TYPE.GENERATE_MESSAGEID, data: { roomID }});
  };

  public formatTime = (timeObj: any) => {
    timeObj = new Date(timeObj);
    let hour = timeObj.getHours();
    let minute = timeObj.getMinutes();
    const amPM = hour > 11 ? 'PM' : 'AM';
    if (hour > 12) {
      hour -= 12;
    } else if (hour === 0) {
      hour = '12';
    }
    if (minute < 10) {
      minute = `0${minute}`;
    }
    return `${hour}:${minute} ${amPM}`;
  };

  public convertToLocalTime = (time: any) => moment(time).local().toDate();

  public formatDate(timestamp: any) {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    let lastMessageTime = '';
    const currentDate = new Date();
    const messageDate: any = this.convertToLocalTime(timestamp);
    // if (this.isSameDateAs(currentDate, messageDate)) {
    //     lastMessageTime = this.formatTime(messageDate);
    // } else {
    lastMessageTime = `${months[messageDate.getMonth()]}-${messageDate.getDate()}-${messageDate.getFullYear()}`;
    // }
    return lastMessageTime;
  }

  // Need to move to util class with static function
  public isSameDateAs = (date1: any, date2: any) =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

  public mergeObjects = (newObj: any, existingObj: any) => {
    const newLength = Object.keys(newObj).length;
    for (let i = 0; i < newLength; i++) {
      const objKey = Object.keys(newObj)[i];
      existingObj[objKey] = newObj[objKey];
    }
    return existingObj;
  };
}
