// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import './css/NewChatComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Image, InputGroup, FormControl, Alert } from 'react-bootstrap';
import _, { values } from 'lodash';
import swal from 'sweetalert';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import SearchedUserList from '../user/SearchedUserList';
import UserModal from '../user/UserModal';

interface State {
  search: any;
  groupName: any;
  candidateList: any[];
  groupSelection: any;
  isUseSymbol: boolean;
  isValidLength: boolean;
}
interface Props {
  rootStore?: any;
  openNewChat: any;
  closeNewChat: any;
  openGroup: any;
  closeGroup: any;
}

const groupTitleRegex = /^([A-Za-z0-9()_'"!\-: ]+)$/;

@inject('rootStore')
@observer
class NewChatComponent extends Component<Props, State> {
  private rootStore: any;

  private chatStore: any;

  private authStore: any;

  private inTimeout = false;

  private searchedListRef: any;

  private timeoutId: NodeJS.Timeout | null = null;

  constructor(props: any) {
    super(props);
    this.state = {
      search: '',
      groupName: '',
      candidateList: [],
      groupSelection: false,
      isUseSymbol: false,
      isValidLength: false,
    };
    const { rootStore } = this.props;
    this.rootStore = rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
    this.searchedListRef = React.createRef();
  }

  private onchange = (e: any) => {
    const { name, value } = e.target;

    if (name === 'search') {
      if (!this.inTimeout) {
        this.inTimeout = true;
        this.timeoutId = this.timeoutSubmit(800);
      } else if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId);
        this.timeoutId = this.timeoutSubmit(800);
      }
    }

    this.setState({ [name]: value } as Pick<State, keyof State>);
    this.messageAlerts(value);
  };

  private timeoutSubmit = (ms: number): NodeJS.Timeout => {
    return setTimeout(() => {
      this.submitSearch();
      this.inTimeout = false;
      this.timeoutId = null;
    }, ms);
  };

  private messageAlerts = (str: string): void => {
    const lengthCondition = str.length < 2 || str.length > 45;
    this.setState({
      isValidLength: !lengthCondition,
    });

    if (str.length === 0) {
      this.setState({ isUseSymbol: false });
    } else {
      const regexCondition = !groupTitleRegex.test(str);
      this.setState({ isUseSymbol: regexCondition });
    }
  };

  private closeNewChat = () => {
    const { closeNewChat } = this.props;
    closeNewChat();
    this.authStore.setSearchedUsersList([]);
  };

  private submitSearch = () => {
    const { search } = this.state;
    const searchKeyword: string = search.toLowerCase();
    this.searchedListRef.current.submitSearch(searchKeyword);
  };

  public renderUserModal = (userData: any) => {
    const { uid } = userData;
    this.chatStore.isModalTrue = true;
    this.chatStore.getUserModalData(uid);
  };

  private createGroupChat = () => {
    const { candidateList, groupName } = this.state;
    const userIDList: string[] = candidateList.map((member: any) => member.uid);
    const { closeGroup } = this.props;

    swal({
      title: 'Create Group',
      text: 'Are you sure?',
      buttons: ['Cancel', 'Ok'],
    }).then((isOkay) => {
      if (isOkay) {
        this.chatStore.handleClickMyChatsTab();
        this.chatStore.createGroupChat(groupName, userIDList);
      }
      this.setState({
        candidateList: [],
        search: '',
        groupSelection: false,
        groupName: '',
      });
      closeGroup(true);
    });
  };

  private addUser = (user: any) => {
    let { candidateList } = this.state;
    const contains = candidateList.some((candidate) => {
      return user.uid === candidate.uid;
    });

    if (contains) {
      candidateList = candidateList.filter((candidate) => {
        return user.uid !== candidate.uid;
      });
    } else {
      candidateList.push(user);
    }

    this.setState({ candidateList });
  };

  private closeGroupPanel = () => {
    this.setState({
      candidateList: [],
      groupSelection: false,
    });
  };

  private renderSideNavHeader = (handleClose: Function, title: String, skipButton: Boolean) => {
    return (
      <div className="d-flex p-2" style={{ fontSize: '20px' }}>
        <a
          onClick={() => {
            handleClose();
            this.setState({ search: '' });
          }}
          className="buttons text-left"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </a>
        <p className="my-0 flex-grow-1 text-center">{title}</p>
        {skipButton ? (
          <a onClick={() => this.createGroupChat()} className="buttons text-right">
            skip
          </a>
        ) : (
          <div className="buttons text-right" />
        )}
      </div>
    );
  };

  private renderSearchBar = () => {
    const { search } = this.state;
    return (
      <InputGroup className="col-12 my-3">
        <InputGroup.Prepend>
          <InputGroup.Text style={{ background: 'white', color: 'gray', border: 'none' }}>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          placeholder="Search for a User"
          aria-label="User Search Bar"
          onChange={this.onchange}
          value={search}
          name="search"
          style={{ border: 'none' }}
        />
      </InputGroup>
    );
  };

  private renderGroupMemberIcon = (user: any) => {
    return (
      <span key={user.uid} className="memberListImage">
        <Image className="my-2 mr-2" width="40" height="40" src={user.userThumbnailURL} roundedCircle />
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <span
          className="text-secondary memberListDeleteBtn"
          onClick={() => {
            this.addUser(user);
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
      </span>
    );
  };

  private renderGroupMemberList = () => {
    const { candidateList } = this.state;
    return candidateList.length > 0 ? (
      <div className="text-truncate d-flex groupMemberList m-2">
        <div className="col-10" style={{ overflowX: 'scroll' }}>
          {candidateList.map((user: any) => this.renderGroupMemberIcon(user))}
        </div>
        <div className="col-2 p-0">
          <button type="button" className="goBtn" onClick={this.createGroupChat}>
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    ) : null;
  };

  private NewChat = (props: any) => {
    const { groupSelection } = this.state;
    return groupSelection ? (
      <div className="newChatSideNav" style={{ width: '100%' }}>
        {this.renderSideNavHeader(this.closeGroupPanel, 'Invite Users', true)}
        {this.renderSearchBar()}
        {this.renderGroupMemberList()}
        <SearchedUserList onClickUser={this.addUser} ref={this.searchedListRef} isHidden={!props.open} />
      </div>
    ) : (
      <div className={classNames('newChatSideNav', props.open ? 'group-body-open' : 'group-body-close')}>
        {this.renderSideNavHeader(props.close, 'Invite Users', false)}
        {this.renderSearchBar()}
        <SearchedUserList onClickUser={this.renderUserModal} ref={this.searchedListRef} isHidden={!props.open} />
      </div>
    );
  };

  private NewGroup = (props: any) => {
    const { isUseSymbol, isValidLength, groupName } = this.state;
    return (
      <div
        className={classNames('newChatSideNav', 'groupPanel', props.openGroup ? 'group-body-open' : 'group-body-close')}
      >
        {this.renderSideNavHeader(props.closeGroup, 'Create Group', false)}
        <input
          type="text"
          placeholder="Enter Group Name"
          name="groupName"
          className="inputGroup"
          value={groupName}
          onChange={this.onchange}
        />
        <p className="infoText">(Alphabets, Numbers and these characters &apos;&quot;:()-_! are allowed)</p>
        <button
          type="button"
          className={isUseSymbol || !isValidLength ? 'chat-button-disabled' : 'chatBtn'}
          onClick={() => this.setState({ groupSelection: true })}
          disabled={isUseSymbol || !isValidLength}
        >
          Next
        </button>
        <div>
          {!isValidLength && (
            <div>
              <Alert style={{ color: 'red' }}>Characters between 2 and 45 is allowed.</Alert>
            </div>
          )}
        </div>
        <div>
          {isUseSymbol && (
            <div>
              <Alert style={{ color: 'red' }}>Symbols cannot be used in group names.</Alert>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { openNewChat, openGroup, closeGroup } = this.props;
    return (
      <>
        <this.NewChat open={openNewChat} close={this.closeNewChat} />
        <UserModal />
        <this.NewGroup openGroup={openGroup} closeGroup={closeGroup} />
      </>
    );
  }
}

export default NewChatComponent;
