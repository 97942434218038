import React, { Component, Suspense } from 'react';
import {
  Button,
  InputGroup,
  FormControl,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Modal,
  Image,
} from 'react-bootstrap';
import './LandingPage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faSearch,
  faComment,
  faUser,
  faUserFriends,
  faCommentAlt,
  faCommentMedical,
} from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { toJS } from 'mobx';
import classNames from 'classnames';
import ChatList from '../../chatList/ChatList';
import ChatPage from '../../chat/ChatPage';
import ChatDetailPage from '../../chat/ChatDetailPage';
import NewChatComponent from '../../chat/NewChatComponent';
import MessageDetailPage from '../../chat/MessageDetailPage';
import Account from '../../user/Account';

import { WEBSOCKET_COMMAND_TYPE, PRODUCTION_BASELOGO_URL, MEMBER_STATUS, BASENAME_URL } from '../../../utils/Constants';
import ChatStats from '../../chat/ChatStats';
import SchoolsChart from './SchoolsChart';
import UserProfile from '../../user/UserProfile';
import ChatLandingPage from '../ChatLandingPage';

const primaryColor = '#5D7F9D';
const RankingChart = React.lazy(() => import('../../chat/RankingChart'));

interface State {
  newChatOpen: any;
  openNewGroup: any;
  channelList: any;
  channelID: any;
  closeNotification: boolean;
  openChatPage: any;
  isUsersStats: any;
  key: boolean;
  userLink: boolean;
  profileData: any;
  isUserData: boolean;
  isChatListLoaded: boolean;
  renderRank: boolean;
  userData: any;
}

interface Props {
  rootStore?: any;
  match?: any;
}
@inject('rootStore')
@observer
class LandingPage extends Component<Props, State> {
  state: State;

  rootStore: any;

  chatStore: any;

  authStore: any;

  sidebarStore: any;

  messageData: any = {};

  memberData: any = {};

  userData: any;

  thumbnailURL: string;

  constructor(props: any) {
    super(props);
    this.state = {
      newChatOpen: false,
      openNewGroup: false,
      openChatPage: false,
      channelList: {},
      channelID: '',
      closeNotification: false,
      isUsersStats: false,
      key: false,
      userLink: false,
      profileData: [],
      isUserData: false,
      isChatListLoaded: false,
      renderRank: false,
      userData: [],
    };
    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
    this.sidebarStore = this.rootStore.sidebarStore;
    this.userData = this.authStore.getUserData();
    this.thumbnailURL = this.userData.thumbnailURL;
  }

  componentDidUpdate = () => {
    this.permissionNotify();
  };

  componentDidMount() {
    this.chatStore.myChats = true;
  }

  private onSend = (text: string, html: string | null, reply: string | null, mention: string[] | null) => {
    const messageObj: any = {};
    messageObj._id = this.rootStore.generateMessageID(this.chatStore.currRoomID);
    messageObj.text = text;
    messageObj.type = 'Text';
    messageObj.channelID = this.chatStore.currChannelID;
    if (reply) {
      messageObj.type = 'Reply';
      messageObj.replyTo = reply;
    }
    if (mention) {
      messageObj.mentions = mention;
    }
    if (html) {
      messageObj.html = html;
    }
    this.chatStore.sendChatMessage(this.chatStore.currRoomID, messageObj);
  };

  private closeNotify = () => {
    this.setState({ closeNotification: true });
  };

  private getMobileOS(): string {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return 'unknown';
  }

  private notifyButton = () => {
    if ('Notification' in window) {
      if (Notification.permission !== 'denied' && Notification.permission !== 'granted') {
        Notification.requestPermission();
        this.closeNotify();
      }
    }
  };

  private permissionNotify = () => {
    const isGetMobile = this.getMobileOS();
    if (isGetMobile === 'unknown') {
      if ('Notification' in window === false) {
        return null;
      }
      if (Notification.permission !== 'granted' && this.state.closeNotification === false) {
        return (
          <div style={{ width: '100%', fontSize: '10px', color: 'white' }}>
            <Col className="bgBaseColor d-flex align-items-center" style={{ padding: '6px 6px' }}>
              <Button
                variant="secondary"
                style={{
                  border: 'none',
                  backgroundColor: 'inherit',
                  fontSize: '16px',
                  margin: 'auto',
                  fontWeight: 600,
                }}
                onClick={() => this.notifyButton()}
              >
                Enable desktop notifications!
              </Button>
            </Col>
          </div>
        );
      }
      if (
        Notification.permission === 'granted' ||
        Notification.permission === 'denied' ||
        Notification.permission === 'default'
      ) {
        return null;
      }
    }
  };

  private followChatRoom = (roomID: any) => {
    try {
      // this.chatStore.resLoading = true;
      this.authStore.sendMessageToServer({ command: WEBSOCKET_COMMAND_TYPE.FOLLOW_CHAT, data: { roomID } });
    } catch (err) {
      console.error(err);
    }
  };

  private unFollowChatRoom = (roomID: any) => {
    try {
      // this.chatStore.resLoading = true;
      this.authStore.sendMessageToServer({ command: WEBSOCKET_COMMAND_TYPE.UNFOLLOW_CHAT, data: { roomID } });
    } catch (err) {
      console.error(err);
    }
  };

  private onCreateChannel = (roomID: any, title: any) => {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.CREATE_CHANNEL,
      data: { roomID, channel: title },
    });
  };

  private renderErrorModal = () => {
    if (this.chatStore.maxAttemptReached) {
      return (
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>Please try refreshing your page to continue using the app </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>If you continue to experience any issue after refreshing the app, please come back again later!</p>
            <Image src="https://cdn.pixabay.com/photo/2016/03/22/04/08/pepe-the-frog-1272162_960_720.jpg" fluid />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" href="javascript:location.reload(true)">
              Refresh the page
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      );
    }
  };

  private renderInactiveModal = () => {
    if (this.chatStore.inactive) {
      return (
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>It appears you have been away for too long!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Your connection has been lost due to inactivity. Please refresh your page to continue using the app.</p>

            <Image src="https://cdn.pixabay.com/photo/2016/03/22/04/08/pepe-the-frog-1272162_960_720.jpg" fluid />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" href="javascript:location.reload(true)">
              Refresh the page
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      );
    }
  };

  renderLogOut = () => {
    const logoutConfirm = confirm('Are you sure you want to SignOut?');
    if (logoutConfirm === true) {
      this.authStore.signOut();
      return true;
    }
    return false;
  };

  openChatToggle = () => {
    this.setState({ openChatPage: !this.state.openChatPage });
  };

  closeChatPage = () => {
    this.setState({ openChatPage: false });
  };

  renderRanking = () => {
    if (this.state.renderRank === false) {
      this.setState({ isChatListLoaded: true, renderRank: true });
    }
  };

  getSelfProfileData() {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.GET_USER_BASICINFO,
      data: { targetID: this.authStore.selfUser.uid },
    });
  }

  getSelfUserData() {
    this.authStore.sendMessageToServer({
      command: WEBSOCKET_COMMAND_TYPE.V2_GET_USERINFO,
      data: { targetID: this.authStore.selfUser.uid },
    });
  }

  render() {
    this.userData = this.authStore.getUserData();
    this.thumbnailURL = this.userData.thumbnailURL;
    this.state.isUsersStats = this.chatStore.isUsersStats;
    const userData = this.chatStore.userInfo;
    this.state.userLink = this.chatStore.isOpenProfile;
    if (this.chatStore.ChatListLoad === true) {
      this.renderRanking();
    }
    return (
      <div
        id="landingDiv"
        className="d-flex col-12 container-fluid mx-auto"
        style={{ height: '100%', paddingRight: 0, paddingLeft: 0, paddingBottom: 0 }}
      >
        <Col
          className="col-12 col-md-3 h-100"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            paddingRight: 0,
            paddingLeft: 0,
          }}
        >
          {this.userData.uid ? (
            <Col
              className="col-12 d-flex align-items-center"
              style={{
                minHeight: '54px',
                maxHeight: '54px',
                paddingRight: 0,
                paddingLeft: 0,
              }}
            >
              <Col className="col-4">
                <Image className="col-12 h-100" style={{ borderWidth: 0 }} src={BASENAME_URL} />
              </Col>
              <Col className="h-100 d-flex justify-content-end align-items-center" style={{ padding: 0 }}>
                <Image
                  style={{ padding: 0 }}
                  className="justify-content-end"
                  width="35"
                  height="35"
                  src={this.thumbnailURL || PRODUCTION_BASELOGO_URL}
                  onClick={() => {
                    (this.chatStore.isOpenProfile = false),
                      this.chatStore.handleRenderProfile(),
                      this.getSelfUserData(),
                      this.getSelfProfileData(),
                      this.closeChatPage(),
                      this.setState({
                        userLink: this.chatStore.isOpenProfile,
                      }),
                      (this.chatStore.isModalTrue = false);
                  }}
                  roundedCircle
                />

                <div className="col-4 h-100 d-flex justify-content-end align-items-center" style={{ padding: 0 }}>
                  <DropdownButton
                    variant="link"
                    title={<FontAwesomeIcon icon={faCommentMedical} style={{ color: 'white', fontSize: 20 }} />}
                    id="dropdown-basic-button"
                  >
                    <Dropdown.Item
                      onClick={() => {
                        this.setState({ newChatOpen: !this.state.newChatOpen }),
                          (this.chatStore.isOpenProfile = true),
                          this.chatStore.handleRenderProfile();
                      }}
                    >
                      <FontAwesomeIcon icon={faUser} /> 1:1 Chat
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        this.setState({ openNewGroup: !this.state.openNewGroup }),
                          (this.chatStore.isOpenProfile = true),
                          this.chatStore.handleRenderProfile();
                      }}
                    >
                      <FontAwesomeIcon icon={faUserFriends} /> Group Chat
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
                <DropdownButton
                  className="justify-content-end "
                  id="dropdown-basic-button"
                  variant="link"
                  title={<FontAwesomeIcon icon={faEllipsisV} style={{ color: 'white', marginRight: 0 }} />}
                >
                  {/* <Dropdown.Item eventKey="3">
                    <Button
                      className="btn-secondary"
                      onClick={() => {
                        this.chatStore.handleClickMyChatsTab(), (this.chatStore.myChats = true);
                      }}
                    >
                      My Chats
                    </Button>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="3">
                    <Button
                      className="btn-secondary"
                      onClick={() => {
                        this.chatStore.handleClickExploreChatsTab(), (this.chatStore.explore = true);
                      }}
                    >
                      Explore Chats
                    </Button>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="3">
                    <Button
                      className="btn-secondary"
                      onClick={() => {
                        this.chatStore.handleClickFollowingChatsTab(), (this.chatStore.following = true);
                      }}
                    >
                      Following Chats
                    </Button>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="2">
                    <Link to="/ranking" style={{ color: 'black' }}>
                      Rank Page
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="3">
                    <Link to="/userlist" style={{ color: 'black' }}>
                      User List
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Divider /> */}
                  <Dropdown.Item
                    href="/"
                    onClick={() => {
                      this.renderLogOut();
                    }}
                  >
                    Log out
                  </Dropdown.Item>
                </DropdownButton>
              </Col>
            </Col>
          ) : (
            <Button href="/" style={{ backgroundColor: primaryColor, borderColor: primaryColor, fontWeight: 'bold' }}>
              Log In
            </Button>
          )}
          {this.permissionNotify()}
          {!this.chatStore.maxAttemptReached && this.chatStore.isUsersStats && (
            <Container
              fluid
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                paddingRight: 0,
                paddingLeft: 0,
              }}
            >
              <ChatList
                openChatPage={this.openChatToggle}
                onCreateChannelBtn={(roomID: any, title: any) => this.onCreateChannel(roomID, title)}
              />
              <NewChatComponent
                openNewChat={this.state.newChatOpen}
                closeNewChat={() => this.setState({ newChatOpen: !this.state.newChatOpen })}
                openGroup={this.state.openNewGroup}
                closeGroup={() => this.setState({ openNewGroup: !this.state.openNewGroup })}
              />
            </Container>
          )}
          {this.chatStore.maxAttemptReached && (
            <Container className="h-80" style={{ paddingTop: 10, height: '100%' }} fluid>
              {this.renderErrorModal()}
            </Container>
          )}

          {this.chatStore.maxTimerRefreshed && (
            <Container className="h-80" style={{ paddingTop: 10, height: '100%' }} fluid>
              {this.renderInactiveModal()}
            </Container>
          )}
        </Col>
        {!!this.chatStore.currRoomID && !this.state.userLink && !!this.chatStore.getCurrentChatRoom() ? (
          <div>
            <div
              className={classNames(
                'p-0',
                'chatPage',
                this.sidebarStore.isSidebarOpen ? 'chat-body--shrunken' : 'chat-body',
                this.state.openChatPage ? 'chat-responsive-open' : 'chat-responsive-close',
              )}
            >
              <Container className="h-100 p-0" fluid>
                {this.chatStore.showChatLandingPage ? (
                  <ChatLandingPage openChatList={this.openChatToggle} closeChatPage={this.closeChatPage} />
                ) : (
                  !this.state.userLink && (
                    <ChatPage
                      openChatPage={this.openChatToggle}
                      schoolList={this.authStore.schoolList}
                      channelID={this.chatStore.currChannelID}
                      onSend={(text: string, html: string | null, reply: string | null, mention: string[] | null) =>
                        this.onSend(text, html, reply, mention)
                      }
                    />
                  )
                )}
              </Container>
            </div>
            <div className={classNames('p-0', this.sidebarStore.isSidebarOpen ? 'sidebar--open' : 'sidebar--closed')}>
              {this.sidebarStore.isChatDetailOpen && (
                <ChatDetailPage
                  onCreateChannelBtn={(roomID: any, title: any) => this.onCreateChannel(roomID, title)}
                  schoolList={this.authStore.schoolList}
                />
              )}
              {this.sidebarStore.isMessageDetailOpen && <MessageDetailPage />}
              {this.sidebarStore.isChatStatsOpen && <ChatStats />}
            </div>
          </div>
        ) : (
          this.state.isChatListLoaded &&
          !this.state.userLink && (
            <Suspense
              fallback={
                <div className="justify-content-center">
                  <p style={{ margin: 'auto', fontWeight: 'bold', fontSize: '25px' }}>Ranking Loading...</p>
                </div>
              }
            >
              <Col xl={9} className="block align-items-center" style={{ overflow: 'auto', height: '100%' }}>
                <Container className="" style={{ borderRadius: '10', position: 'inherit' }}>
                  {this.state.isUsersStats && window.matchMedia('(min-width: 768px)').matches ? <RankingChart /> : null}
                  {/* <SchoolsChart /> */}
                </Container>
              </Col>
            </Suspense>
          )
        )}
        {this.state.userLink ? (
          <div
            className={classNames(this.state.userLink ? 'chat-responsive-open' : 'chat-responsive-close')}
            style={{ height: '100%', width: '100%', overflow: 'auto' }}
          >
            <div className="">
              <Container className="col-12" fluid>
                <UserProfile match={this.props.match} rootStore={this.props.rootStore} />
              </Container>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default LandingPage;
