// eslint-disable-next-line no-use-before-define
import React, { PureComponent } from 'react';
import { Image, Spinner } from 'react-bootstrap';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';
import { WEBSOCKET_COMMAND_TYPE } from '../../utils/Constants';
import './css/SearchedUserList.css';

interface Props {
  rootStore?: any;
  roomID?: string;
  onClickUser: Function;
  isHidden: boolean;
}

interface CardProps {
  userData: any;
  onClick: Function;
}

interface State {
  isKeywordLongEnough: boolean;
}

const UserCardForUserList = ({ userData, onClick }: CardProps) => {
  const { firstName, lastName, userThumbnailURL, schoolName, majorName, uid } = userData;
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="bgGray-3 d-flex pt-3 pr-3"
      style={{ borderBottom: '1px solid #5D7F9D50' }}
      onClick={() => onClick(userData)}
    >
      <div className="col-3 pr-0">
        <Image className="mr-2 bs-1" width="60" height="60" src={userThumbnailURL} roundedCircle />
      </div>
      <div className="col-9">
        <p className="exploreList_user_name">
          {firstName} {lastName}
        </p>
        <p className="exploreList_school_name">{schoolName}</p>
        <p className="exploreList_major_name">{majorName}</p>
      </div>
    </div>
  );
};

@inject('rootStore')
@observer
class SearchedUserList extends PureComponent<Props, State> {
  private authStore: any;

  private userDOMList: any = [];

  private isEndOfList = false;

  private isGettingMoreUsers = false;

  private exploreListRef: any;

  private searchKeyword = '';

  private prevSearchKeyword = '';

  constructor(props: any) {
    super(props);
    this.state = {
      isKeywordLongEnough: false,
    };
    this.exploreListRef = React.createRef();
    const { rootStore } = this.props;
    const { authStore } = rootStore;
    this.authStore = authStore;
  }

  private submitSearch = (keyword: string) => {
    this.searchKeyword = keyword;
    if (this.searchKeyword.length >= 2) {
      this.setState({ isKeywordLongEnough: true });
      this.getUsersFromDB(this.searchKeyword, 0);
    } else {
      this.setState({ isKeywordLongEnough: false });
      this.authStore.setSearchedUsersList([]);
    }
  };

  private getUsersFromDB = (keyword: string, offset: number) => {
    const { roomID } = this.props;
    if (roomID) {
      try {
        this.authStore.sendMessageToServer({
          command: WEBSOCKET_COMMAND_TYPE.V2_LOAD_USER_LIST_W_FILTER,
          data: { searchword: keyword, offset, roomID },
        });
      } catch (err) {
        console.error('getUsersFromDB Error:', err);
      }
    } else {
      try {
        this.authStore.sendMessageToServer({
          command: WEBSOCKET_COMMAND_TYPE.V2_LOAD_STUDENT_LIST_SEARCH,
          data: { searchword: keyword, offset },
        });
      } catch (err) {
        console.error('getUsersFromDB Error:', err);
      }
    }
  };

  private getMoreUsers = () => {
    const element = this.exploreListRef.current;
    if (element && !this.isEndOfList) {
      if (Math.ceil(element.scrollTop) >= element.scrollHeight - element.clientHeight) {
        this.getUsersFromDB(this.searchKeyword, this.userDOMList.length);
        this.isGettingMoreUsers = true;
      }
    }
  };

  private renderExploreList = () => {
    const user = this.authStore.getSearchedUsersList();
    // when user.count is 10 it's maximum fetch number of users from server
    // if user.count is not 10 it means there's no more users to show
    this.isEndOfList = user.count !== 10;

    const { isKeywordLongEnough } = this.state;
    if (isKeywordLongEnough) {
      // this is executed when
      // - a keyword is typed
      // - same keyword as prev is typed
      // - scrolled to the bottom (load more users)

      // reset userDOMList when
      // - typed different keyword from prev keyword
      // - typed same keyword
      if (
        this.prevSearchKeyword !== this.searchKeyword ||
        (this.prevSearchKeyword === this.searchKeyword && !this.isGettingMoreUsers)
      ) {
        this.userDOMList = [];
      }
      const { onClickUser } = this.props;
      _.each(user.userList, (userData: any) => {
        this.userDOMList.push(<UserCardForUserList userData={userData} onClick={onClickUser} key={userData.uid} />);
      });
    } else {
      // this is executed when
      // - keyword is not long enough
      this.userDOMList = [];
      this.isEndOfList = true;
    }

    this.userDOMList = [...this.userDOMList];
    this.prevSearchKeyword = this.searchKeyword;
    this.isGettingMoreUsers = false;

    return this.userDOMList;
  };

  render() {
    const { isKeywordLongEnough } = this.state;
    return (
      <div
        className="sticky-top base-card user-list-wrapper"
        style={{ height: '85vh' }}
        ref={this.exploreListRef}
        onScroll={() => this.getMoreUsers()}
      >
        {isKeywordLongEnough && this.renderExploreList()}
        <div className="bgBaseColor p-3 text-center text-white">
          {!isKeywordLongEnough ? (
            'type at least 2 characters to start search'
          ) : this.isEndOfList ? (
            'no more user to show'
          ) : (
            <>
              loading... <Spinner animation="border" size="sm" />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default SearchedUserList;
