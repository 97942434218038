import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './style/EmojiPickerWrapper.css';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

interface Props {
  addEmoji: Function;
  showEmojiPicker: boolean;
  showCrossInEmojiPicker: boolean;
  handleClickCrossButton: Function;
  isReplyWrapperOpen: boolean;
}

class EmojiPickerWrapper extends Component<Props> {
  componentDidMount() {
    // remove aria-hidden attribute to change style to put cross button on the left side
    document.getElementsByClassName('emoji-mart-preview-emoji')[0].removeAttribute('aria-hidden');
    document.getElementsByClassName('emoji-mart-preview-data')[0].removeAttribute('aria-hidden');
  }

  render() {
    const props = this.props;
    const positionBottom = props.isReplyWrapperOpen ? 155 : 75;

    return (
      <div className="emoji-picker-wrapper">
        <Picker
          set="google"
          onSelect={(emoji: any) => props.addEmoji(emoji.native)}
          title="Select Emoji..."
          emoji="point_up"
          style={{ width: '100%', bottom: positionBottom }}
        />
        <div
          className="cross-button"
          style={{ bottom: positionBottom + 25 }}
          onClick={() => {
            props.handleClickCrossButton();
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    );
  }
}

export default EmojiPickerWrapper;
