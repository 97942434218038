import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Alert, Badge, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faArrowLeft, faChartBar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { inject, observer } from 'mobx-react';

interface State {}

interface Props {
  openChatPage: any;
  rootStore?: any;
}
@inject('rootStore')
@observer
class NavBar extends Component<Props, State> {
  private rootStore: any;
  private authStore: any;
  private chatStore: any;
  private sidebarStore: any;

  constructor(props: any) {
    super(props);
    this.state = {};
    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
    this.sidebarStore = this.rootStore.sidebarStore;
  }

  private truncateTopic = (topic: any) => {
    if (topic.length > 6) {
      topic = topic.substring(0, 6);
      topic += '...';
    }
    return <span className="mr-1">{topic}</span>;
  };

  renderNavBar = () => {
    const roomInfo = this.chatStore.getCurrentChatRoom();
    const topic = roomInfo.topic || 'No topic';
    const channelName = roomInfo.channels[this.chatStore.currChannelID].name;
    // let navBarHeight = window.matchMedia('(min-width: 768px)').matches ? 54 : 108;
    return (
      <Row className="chatroom-title d-flex align-items-center mx-auto col-12" style={{ height: 54 }}>
        <Col className="d-flex align-items-center">
          <Link to="/home">
            <Button variant="link" className="backbtn-space" onClick={this.props.openChatPage}>
              <FontAwesomeIcon className="backbtn-icon" icon={faArrowLeft} />
            </Button>
          </Link>
          <Alert className="bgGray-4 colorWhite" style={{ borderRadius: '10px', padding: '8px 8px', marginBottom: 0 }}>
            <span className="mr-1">
              {window.matchMedia('(min-width: 800px)').matches ? topic : this.truncateTopic(topic)}
            </span>
            <Badge variant="secondary">{channelName}</Badge>
          </Alert>
        </Col>
        <Col className="d-flex justify-content-end align-items-center col-3 icon-style">
          <Button
            onClick={this.sidebarStore.openChatStats}
            variant="link"
            style={{ textDecoration: 'none' }}
            className="btn-space-left"
          >
            <FontAwesomeIcon className="backbtn-icon" icon={faChartBar} />
          </Button>
          <Button onClick={this.sidebarStore.openChatDetail} variant="link" style={{ textDecoration: 'none' }}>
            <FontAwesomeIcon className="backbtn-icon" icon={faInfoCircle} />
          </Button>
        </Col>
      </Row>
    );
  };

  render() {
    return <div>{this.renderNavBar()}</div>;
  }
}
export default NavBar;
