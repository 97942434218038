import React, { Component } from 'react';
import { Container, Row, Image, DropdownButton, Dropdown, Card, Alert, Spinner, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboard,
  faReply,
  faCheck,
  faFileExcel,
  faFilePowerpoint,
  faFileWord,
  faFileArchive,
  faFilePdf,
  faFile,
  faArrowAltCircleDown,
  faInfoCircle,
  faArrowCircleUp,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import Lightbox from 'react-image-lightbox';
import Linkify from 'react-linkify';
import ReactPlayer from 'react-player';
import UserModal from '../../user/UserModal';
import 'react-image-lightbox/style.css';
import QuotedMessage from '../QuotedMessage';
import './BubbleList.scss';

interface State {
  mounted: boolean;
  url: string;
  title: string;
  images: string;
  tagName: string;
  text: string;
  selectedMessage: any;
  isShowImagePreview: boolean;
  imageResource: any;
  modalData: any;
}

interface Props {
  messages: any;
  colorTheme: any;
  rootStore?: any;
  setReply: Function;
}
@inject('rootStore')
@observer
class BubbleList extends Component<Props, State> {
  private rootStore: any;
  private chatStore: any;
  private authStore: any;
  private sidebarStore: any;
  private userName = '';
  private thumbnail = '';

  constructor(props: any) {
    super(props);
    this.state = {
      mounted: true,
      url: '',
      title: '',
      images: '',
      tagName: '',
      text: '',
      selectedMessage: '',
      isShowImagePreview: false,
      imageResource: '',
      modalData: {},
    };
    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
    this.sidebarStore = this.rootStore.sidebarStore;
  }

  private timestampToTime = (timestamp: any) => {
    const messageDate = this.convertToLocalTime(timestamp);
    return this.formatTime(messageDate);
  };

  private renderDate = (timestamp: any) => {
    const messageDate = this.convertToLocalTime(timestamp);
    return this.formatDate(messageDate);
  };

  private convertToLocalTime = (time: any) => {
    return moment(time).local().toDate();
  };

  private formatTime = (timeObj: any) => {
    timeObj = new Date(timeObj);
    let hour = timeObj.getHours();
    let minute = timeObj.getMinutes();
    const amPM = hour > 11 ? 'PM' : 'AM';
    if (hour > 12) {
      hour -= 12;
    } else if (hour === 0) {
      hour = '12';
    }
    if (minute < 10) {
      minute = `0${minute}`;
    }
    return `${hour}:${minute} ${amPM}`;
  };

  public formatDate = (dateObj: any) => {
    dateObj = new Date(dateObj);
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

    const date = dateObj.getDate();
    const month = monthNames[dateObj.getMonth()];
    const year = dateObj.getFullYear();
    return `${month} ${date}, ${year}`;
  };

  private copyText = (text: any) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        alert('Copied to clipboard!');
      } else {
        alert('Unable to copy to clipboard');
      }
    } catch (err) {
      alert('Unable to copy to clipboard');
    }
    document.body.removeChild(textArea);
  };

  private downloadFile = (link: string) => {
    window.open(link);
  };

  private renderDocBubble = (msgObj: any) => {
    const nameArr = msgObj.attachments[0].name.split('_');
    let fileName = '';
    let iconName;
    let iconStyle;
    for (let i = 2; i < nameArr.length; i++) {
      if (fileName !== '') {
        fileName = fileName + '_' + nameArr[i];
      } else {
        fileName = fileName + nameArr[i];
      }
    }
    const iconNameArr: any = fileName.split('.');
    if (fileName.length > 20) {
      fileName = fileName.slice(0, 20) + '...';
    }
    switch (iconNameArr[iconNameArr.length - 1]) {
      case 'docx':
        iconName = faFileWord;
        iconStyle = { fontSize: '30', color: '#60a9f6' };
        break;
      case 'xlsx':
        iconName = faFileExcel;
        iconStyle = { fontSize: '30', color: '#56d28b' };
        break;
      case 'pptx':
        iconName = faFilePowerpoint;
        iconStyle = { fontSize: '30', color: '#f34646' };
        break;
      case 'pdf':
        iconName = faFilePdf;
        iconStyle = { fontSize: '30', color: '#f34646' };
        break;
      case 'zip':
        iconName = faFileArchive;
        iconStyle = {
          fontSize: '30',
          color: '#ffffff',
          background: '#000000',
          border: '1px solid #000000',
          borderRadius: 3,
        };
        break;
    }

    return (
      <div style={{ background: '#ffffff', padding: 10, margin: 3, borderRadius: 10 }}>
        <Row
          style={{ cursor: 'pointer' }}
          onClick={() => {
            this.downloadFile(msgObj.attachments[0].url);
          }}
        >
          <Col md="auto">
            {iconName ? (
              <FontAwesomeIcon icon={iconName} style={iconStyle} />
            ) : (
              <FontAwesomeIcon icon={faFile} style={{ fontSize: '30', color: '#000000' }} />
            )}
          </Col>
          <Col md="auto" style={{ paddingLeft: 0, lineHeight: '30px', color: '#000000' }}>
            <Card.Text>{fileName}</Card.Text>
          </Col>
          <Col md="auto" style={{ paddingLeft: 0 }}>
            <FontAwesomeIcon
              icon={faArrowAltCircleDown}
              style={{
                borderRadius: 15,
                fontSize: '30',
                color: '#ffffff',
                border: '1px solid #7b7b7b',
                background: '#7b7b7b',
              }}
            />
          </Col>
        </Row>
      </div>
    );
  };

  private showMessageInfo = (message: any) => {
    this.chatStore.setSelectedMessage(message);
    this.sidebarStore.openMessageDetail();
  };

  private showImagePreview = (image: any) => {
    this.setState({
      isShowImagePreview: true,
      imageResource: image,
    });
  };

  public renderUserModal = (userID: any) => {
    this.chatStore.getUserModalData(userID);
  };

  private renderDropDownMenu = (message: any) => {
    const dropdownItemsStyle = {
      fontSize: 15,
      width: 20,
    };
    const isMember = this.chatStore.isMemberOfRoom(this.chatStore.currRoomID, this.rootStore.getUserUID());

    return (
      !message.isReported && (
        <div className="d-flex flex-column justify-content-end px-1">
          <DropdownButton variant="link" title="" id="option" size="sm" style={{ marginBottom: -6 }}>
            {isMember && (
              <Dropdown.Item href="#" onClick={() => this.props.setReply(message)}>
                <FontAwesomeIcon icon={faReply} className="text-secondary mr-2" style={dropdownItemsStyle} />
                Reply
              </Dropdown.Item>
            )}
            <Dropdown.Item href="#" onClick={() => this.copyText(message.text)}>
              <FontAwesomeIcon icon={faClipboard} className="text-secondary mr-2" style={dropdownItemsStyle} />
              Copy
            </Dropdown.Item>
            <Dropdown.Item href="#" onClick={() => this.showMessageInfo(message)}>
              <FontAwesomeIcon icon={faInfoCircle} className="text-secondary mr-2" style={dropdownItemsStyle} />
              Message Info
            </Dropdown.Item>
          </DropdownButton>
        </div>
      )
    );
  };

  private renderBubbleAttachments = (message: any) => {
    if (message.attachments && message.attachments.length > 0) {
      switch (message.type) {
        case 'Photo':
          return (
            <Card.Img
              variant="top"
              src={message.attachments[0].originalURL}
              onClick={() => this.showImagePreview(message.attachments[0].originalURL)}
              style={{ height: 200, maxWidth: '100%' }}
            />
          );
        case 'Video':
          return (
            <ReactPlayer
              variant="top"
              controls={true}
              url={message.attachments[0].originalURL}
              onClick={() => ReactPlayer.canPlay(message.attachments[0].originalURL)}
              style={{ height: 200, maxWidth: 200 }}
            />
          );
        case 'Document':
          return this.renderDocBubble(message);
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  private renderReply = (message: any, i: number) => {
    const replyMessage = 'replyMessage' in message ? message.replyMessage : null;
    this.chatStore.replyMessageList = [];
    return <QuotedMessage key={message.replyTo + i} messageId={message.replyTo} message={replyMessage} />;
  };

  private renderBubbleText = (message: { isReported: boolean; text: string; html: string | null }) => {
    const componentDecorator = (href: any, text: any, key: any) => (
      <a href={href} key={key} target="_blank" rel="noreferrer" style={{ color: 'skyblue' }}>
        {text}
      </a>
    );

    return message.isReported ? (
      <Card.Text style={{ fontSize: 16, color: '#fff', opacity: 0.8, fontStyle: 'italic', marginBottom: 0 }}>
        [Reported message]
      </Card.Text>
    ) : (
      <Card.Text style={{ marginBottom: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
        {message.html ? (
          <span className="htmlMessage" dangerouslySetInnerHTML={{ __html: message.html }} />
        ) : (
          <Linkify componentDecorator={componentDecorator}>{message.text}</Linkify>
        )}
      </Card.Text>
    );
  };

  // System message bubble
  private systemBubble = (i: number, text: string) => {
    return (
      <Row key={'message' + i} style={{ paddingTop: 30 }}>
        <Alert className="mx-auto" variant={'dark'}>
          {text}
        </Alert>
      </Row>
    );
  };

  // Date bubble
  private dateBubble = (i: number, s: string) => {
    return (
      <Row key={i} style={{ paddingTop: 30 }}>
        <Alert className="mx-auto" variant={'dark'} style={{ borderRadius: 30, fontSize: 10 }}>
          {s}
        </Alert>
      </Row>
    );
  };

  // Message from logged in user
  private myBubble = (i: number, msg: any) => {
    return (
      <Row key={'message' + i} style={{ justifyContent: 'flex-end' }}>
        <div style={{ paddingTop: 5, paddingRight: 15, display: 'flex', flexDirection: 'row', paddingBottom: 5 }}>
          {this.renderDropDownMenu(msg)}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              paddingRight: 5,
              fontSize: 10,
              color: 'white',
              opacity: 0.8,
            }}
          >
            {this.timestampToTime(msg.createdAt)}
          </div>
          <Card style={{ maxWidth: 400, borderRadius: 10, backgroundColor: '#5D7E9D', color: 'white' }}>
            <Card.Body
              style={{
                padding: '0.5rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {this.renderBubbleAttachments(msg)}
              {msg.replyTo && this.renderReply(msg, i)}
              <div style={{ display: 'flex' }}>
                {msg.text && this.renderBubbleText(msg)}
                <div style={{ float: 'right', lineHeight: 1, display: 'flex', alignItems: 'flex-end' }}>
                  {msg.sent ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: 'white', fontSize: '10', marginLeft: 12, marginBottom: 5 }}
                    />
                  ) : (
                    <Spinner animation="border" size="sm" />
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Row>
    );
  };

  // Message from others with Avatar
  private othersBubbleWithAvatar = (i: number, msg: any, senderID: string) => {
    return (
      <Row key={'message' + i}>
        <div style={{ paddingTop: 5, paddingLeft: 15, display: 'flex', flexDirection: 'row', paddingBottom: 5 }}>
          <div className="chaticon-wrap-left">
            <Image
              className="chaticon-size"
              src={this.thumbnail}
              onClick={() => {
                (this.chatStore.isModalTrue = true), this.renderUserModal(senderID);
              }}
              roundedCircle
            />
          </div>
          <div className="bubble-wrap">
            <div style={{ fontWeight: 'bold', fontSize: 16, paddingBottom: 5, color: 'white' }}>{this.userName}</div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Card style={{ maxWidth: 400, borderRadius: 10, color: 'white' }} className="bgGray-3">
                <Card.Body className="bubble-space">
                  {this.renderBubbleAttachments(msg)}
                  {msg.replyTo && this.renderReply(msg, i)}
                  {msg.text && this.renderBubbleText(msg)}
                </Card.Body>
              </Card>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  paddingLeft: 5,
                  fontSize: 10,
                  color: 'white',
                  opacity: 0.8,
                }}
              >
                {this.timestampToTime(msg.createdAt)}
              </div>
              {this.renderDropDownMenu(msg)}
            </div>
          </div>
        </div>
      </Row>
    );
  };

  // Message from others without Avatar
  private othersBubbleWithoutAvatar = (i: number, msg: any, senderID: string) => {
    return (
      <Row key={'message' + i}>
        <div style={{ paddingTop: 5, paddingLeft: 15, display: 'flex', flexDirection: 'row', paddingBottom: 5 }}>
          <div className="chaticon-wrap" />
          <div className="bubble-wrap">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Card style={{ maxWidth: 400, borderRadius: 10, color: 'white' }} className="bgGray-3">
                <Card.Body className="bubble-space">
                  {this.renderBubbleAttachments(msg)}
                  {msg.replyTo && this.renderReply(msg, i)}
                  {msg.text && this.renderBubbleText(msg)}
                </Card.Body>
              </Card>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  paddingLeft: 5,
                  fontSize: 10,
                  color: 'white',
                  opacity: 0.8,
                }}
              >
                {this.timestampToTime(msg.createdAt)}
              </div>
              {this.renderDropDownMenu(msg)}
            </div>
          </div>
        </div>
      </Row>
    );
  };

  loadLargeButton = () => {
    const { messages } = this.props;
    if (messages.length > 10) {
      if (messages.length < 40) {
        return (
          <button
            className="bounce btn-secondary"
            style={{ backgroundColor: 'transparent', border: 'none', fontSize: 50, display: 'flex' }}
            onClick={() => {
              this.chatStore.loadEarlierMessages(this.chatStore.currRoomID);
            }}
          >
            <FontAwesomeIcon icon={faArrowCircleUp} />
          </button>
        );
      }
    }
  };

  // Create all bubbles in chat page
  bubbles = () => {
    const bubbles = [];
    const messages = this.props.messages;

    // checking starts from old messages to new messages
    for (let i = this.props.messages.length - 1; i >= 0; i--) {
      const msg = messages[i];
      const messageCreatedAt = this.renderDate(msg.createdAt);
      const renderDay: boolean =
        i === this.props.messages.length - 1 || messageCreatedAt !== this.renderDate(messages[i + 1].createdAt)
          ? true
          : false;
      const isMsgFromSystem: boolean = msg.system;
      const showAvatar: boolean =
        renderDay || (i < this.props.messages.length - 1 && msg.senderID !== messages[i + 1].senderID) ? true : false;
      if (msg.sender) {
        this.userName = msg.sender.name;
        this.thumbnail = msg.sender.avatar;
      }
      if (renderDay) {
        // add the date bubble
        bubbles.push(this.dateBubble(i, messageCreatedAt));
      }
      if (isMsgFromSystem) {
        // render system message bubble
        bubbles.push(this.systemBubble(i, msg.text));
        continue;
      }
      if (msg.senderID === this.authStore.selfUser.uid) {
        // logged in user's message (it goes right side)
        bubbles.push(this.myBubble(i, msg));
      } else if (showAvatar) {
        // message from others (it goes leftside with avatar)
        bubbles.push(this.othersBubbleWithAvatar(i, msg, msg.sender._id));
      } else {
        // message from others (it goes leftside without avatar)
        bubbles.push(this.othersBubbleWithoutAvatar(i, msg, msg.sender._id));
      }
    }

    return bubbles;
  };

  render() {
    const { isEarlierChatLoading, firstChatLoaded, openChannelID } = this.chatStore;
    return (
      <Container style={{ maxWidth: 10000 }}>
        {firstChatLoaded[openChannelID] && (
          <Row
            style={{
              paddingTop: 30,
              color: 'white',
              display: 'flex',
              justifyContent: 'Center',
            }}
          >
            <h4>There are no more earlier messages</h4>
          </Row>
        )}
        {!firstChatLoaded[openChannelID] && (
          <div className="col-12" style={{ display: 'flex', justifyContent: 'Center', margin: 'auto' }}>
            {this.loadLargeButton()}
          </div>
        )}
        {isEarlierChatLoading && this.props.messages.length > 0 && !firstChatLoaded[openChannelID] && (
          <Row style={{ paddingTop: 30 }}>
            <Spinner animation="border" variant="secondary" className="mx-auto" />
          </Row>
        )}
        {this.bubbles()}
        {this.state.isShowImagePreview && (
          <Lightbox
            mainSrc={this.state.imageResource}
            onCloseRequest={() => this.setState({ isShowImagePreview: false })}
          />
        )}
        <UserModal />
      </Container>
    );
  }
}

export default BubbleList;
