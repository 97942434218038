import React, { Component } from 'react';
import { Row, Col, Image, ListGroup } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { PRODUCTION_BASELOGO_URL, GROUPTCHAT_THUMBNAIL_LOGO_URL } from '../../utils/Constants';

interface Props {
  chatRooms: any;
  rootStore: any;
}

interface State {}

@inject('rootStore')
@observer
class ChatRoomList extends React.Component<Props, State> {
  private rootStore: any;
  private authStore: any;
  private chatStore: any;
  private chatRoomList: any = {};

  constructor(props: any) {
    super(props);
    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
  }

  public timestampToTime(time: any) {
    const messageDate = this.convertToLocalTime(time);
    return this.formatTime(messageDate);
  }

  public convertToLocalTime = (time: any) => moment(time).local().toDate();

  public formatTime = (timeObj: any) => {
    timeObj = new Date(timeObj);
    const date = timeObj.getDate();
    const month = timeObj.getMonth() + 1;
    const year = timeObj.getFullYear();
    return `${month}/${date}/${year}`;
  };

  renderChatRoomList = (chatList: any) => {
    const myChat = [];
    const size = Object.keys(chatList).length;
    for (let i = 0; i < size; i++) {
      const LastMessage = chatList[i].lastMessage;
      const lastMessageTime = LastMessage ? this.timestampToTime(LastMessage.createdAt) : null;
      const chatRoomData = this.chatStore.myChatList.get(chatList[i].roomID);
      const generalChannelObj = Object.entries(chatRoomData.channels).find(
        ([key, value]: [string, any]) => value.name === 'General',
      );
      let generalID: string = '';
      if (generalChannelObj) {
        generalID = generalChannelObj[0];
      }
      myChat.push(
        <Link
          to={'/home'}
          className="colorWhite"
          style={{ borderBottom: '1px solid #5D7F9D50' }}
          onClick={() => this.chatStore.onClickChatRoom(chatList[i].roomID, generalID, chatList[i].roomType)}
        >
          <Row className="py-3">
            <Col xs="5" lg="3" className="pr-0">
              <Col xs="11" className="pr-0 pl-4" style={{ textAlign: 'center' }}>
                <Image
                  src={chatList[i].groupLogo ? chatList[i].groupLogo : GROUPTCHAT_THUMBNAIL_LOGO_URL}
                  style={{
                    height: '10vw',
                    width: '10vw',
                    maxHeight: 90,
                    maxWidth: 90,
                    marginTop: 10,
                    alignSelf: 'flex-end',
                  }}
                  roundedCircle
                />
              </Col>
            </Col>
            <Col xs="5" lg="7" className="pl-0">
              <Row
                className="col-12 text-truncate"
                style={{
                  display: 'inline-block',
                  fontSize: '1.3vmax',
                  marginTop: '1rem',
                  flexDirection: 'row',
                  fontWeight: 'bold',
                }}
              >
                {chatList[i].title}
              </Row>
              <Row
                className="col-12 text-truncate"
                style={{ display: 'inline-block', fontSize: '1.25vmax', flexDirection: 'row', marginBottom: '1rem' }}
              >
                {LastMessage ? LastMessage.text : 'No Message'}
              </Row>
            </Col>
            <Col xs="1" className="p-0">
              <Row
                className="col-12 text-truncate p-0 colorWhite"
                style={{
                  display: 'inline-block',
                  fontSize: '0.8vmax',
                  flexDirection: 'row',
                  marginBottom: '1rem',
                  overflow: 'visible',
                }}
              >
                {lastMessageTime}
              </Row>
            </Col>
          </Row>
        </Link>,
      );
    }
    return <ListGroup as="ul">{myChat}</ListGroup>;
  };

  private createChatRoomList(chats: any) {
    this.chatRoomList = {};
    for (let i = 0; i < _.size(chats); i++) {
      const chatroom = chats[i];
      const roomID = chatroom.roomID;
      const title = chatroom.topic || 'No topic';
      const roomType = chatroom.roomType;
      const groupLogo = chatroom.thumbnailURL;
      const lastMessage = chatroom.lastMessage;
      this.chatRoomList[i] = { roomID, title, lastMessage, groupLogo, roomType };
    }
    return this.chatRoomList;
  }

  render() {
    this.createChatRoomList(this.props.chatRooms);
    return this.renderChatRoomList(this.chatRoomList);
  }
}
export default ChatRoomList;
