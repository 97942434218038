import React, { Component } from 'react';
import './css/ChatDetailPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faTimes,
  faEdit,
  faChartBar,
  faUserPlus,
  faCog,
  faDoorOpen,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import { inject, observer } from 'mobx-react';
import {
  Row,
  ListGroup,
  Col,
  Badge,
  Modal,
  InputGroup,
  Button,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import { flow } from 'mobx';
import _ from 'lodash';
import { toJS } from 'mobx';
import {
  faEllipsisV,
  faSearch,
  faComment,
  faUser,
  faUserFriends,
  faCommentAlt,
  faCommentMedical,
} from '@fortawesome/free-solid-svg-icons';
import Linkify from 'react-linkify';
import ChatMemberList from '../chatMemberList/ChatMemberList';
import ChatFollowerList from '../chatMemberList/ChatFollowerList';
import ActivityChatLog from './ActivityChatLog';
import PostDetail from './Posts';
import MediaDocs from './Media';
import ChatSettings from './ChatSettings';
import Sidebar from './Sidebar';
import InviteMembersToGroup from './InviteMembersToGroup';
import {
  PRODUCTION_BASELOGO_URL,
  MEMBER_LEVEL,
  ROOMTYPE,
  GROUPTCHAT_THUMBNAIL_LOGO_URL,
  WEBSOCKET_COMMAND_TYPE,
} from '../../utils/Constants';

interface State {
  showModal: boolean;
  editable: boolean;
  channelIDToEditName?: string;
  channelNameToEdit: string;
  text: string;
  getInvitationLink: any;
}
interface Props {
  rootStore?: any;
  schoolList: any;
  onCreateChannelBtn: any;
}

const leaveChatRoom = (props: any) => {
  swal({
    title: 'Leave chat room?',
    text: 'This chat room will be removed from your room list',
    buttons: ['Cancel', 'Ok'],
  });
};

@inject('rootStore')
@observer
class ChatDetailPage extends Component<Props, State> {
  public state: State;
  private rootStore: any;
  private chatStore: any;
  private authStore: any;
  private sidebarStore: any;
  private channels: any = {};
  constructor(props: any) {
    super(props);
    this.state = {
      getInvitationLink: null,
      showModal: false,
      editable: false,
      channelNameToEdit: '',
      text: '',
    };
    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
    this.sidebarStore = this.rootStore.sidebarStore;
  }

  public clearChatMessages = () => {
    this.chatStore.getChatMessages = {};
  };

  private renderChannels = (item: any) => {
    const memberLevel = this.chatStore.getUserLevel(item.roomID, this.rootStore.getUserUID());
    this.channels = item.channels;

    const channelNames: any = [];
    _.each(this.channels, (channel) => {
      channelNames.push(channel.name);
    });

    channelNames.sort((a: any, b: any) => {
      const general = 'GENERAL';
      const nameA = a.toUpperCase();
      const nameB = b.toUpperCase();

      if (nameA === general) return -1;
      if (nameB === general) return 1;
      return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
    });

    const channelList: any = [];
    if (memberLevel == 'admin' || memberLevel == 'leader') {
      channelList.push(
        <ListGroup.Item variant="light" style={{ padding: '0', border: 'none' }}>
          <Col
            className="d-flex align-items-left align-items-center"
            style={{
              padding: '0',
              fontSize: '20px',
              color: '#d1cdc7',
              backgroundColor: '#191b1c',
            }}
          >
            Channel
            <span onClick={() => this.setState({ showModal: true })}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{
                  cursor: 'pointer',
                  margin: '0 10px',
                  color: '#707070',
                  fontSize: '20px',
                }}
              />
            </span>
            <span onClick={() => this.setState({ editable: !this.state.editable })}>
              <FontAwesomeIcon
                icon={faCog}
                style={{
                  cursor: 'pointer',
                  margin: '0 10px',
                  color: '#707070',
                  fontSize: '20px',
                }}
              />
            </span>
          </Col>
        </ListGroup.Item>,
      );
    }
    {
      _.size(item.channels) > 1 &&
        _.each(channelNames, (channelName) => {
          const channel = _.find(this.channels, (channel) => {
            return channel.name === channelName;
          });
          const key = _.find(Object.keys(this.channels), (key) => {
            return this.channels[key] === channel;
          });
          channelList.push(
            <Badge
              key={key}
              style={{
                cursor: 'pointer',
                borderRadius: 50,
                margin: '1% 1% 1% 0',
                padding: '3% 4%',
                color: 'white',
                background: this.chatStore.currChannelID === key ? '#e6e1e2' : '#707070',
              }}
              onClick={() => {
                if (this.state.editable) {
                  // Will open a modal!
                  this.setState({
                    channelIDToEditName: key,
                    channelNameToEdit: channel.name,
                  });
                } else {
                  this.chatStore.onClickChatRoom(item.roomID, key, item.roomType);
                }
              }}
            >
              {channel.name}
              {this.state.editable && (
                <FontAwesomeIcon
                  icon={faCog}
                  style={{
                    cursor: 'pointer',
                    margin: '0 10px',
                    color: 'black',
                    fontSize: '16px',
                  }}
                />
              )}
            </Badge>,
          );
        });
    }
    return (
      <Col className="align-items-left" style={{ padding: '0' }}>
        {channelList}
      </Col>
    );
  };

  private showEditChannelName = () => {
    return (
      <Modal show={this.state.channelIDToEditName !== undefined} onHide={this.onCloseEditChannelNameModal}>
        <Modal.Header style={{ justifyContent: 'center' }} closeButton>
          <Modal.Title className="text-center">Edit Channel Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup
            className="h-100 w-100"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormControl
              onChange={(e: any) => this.setState({ channelNameToEdit: e.target.value })}
              value={this.state.channelNameToEdit}
              placeholder="Please enter a channel name"
              style={{
                width: '100%',
                display: 'flex',
                marginBottom: '1em',
              }}
            />
            <Button onClick={this.onEditChannelNamePressed}>
              <span>Edit</span>
            </Button>
          </InputGroup>
        </Modal.Body>
      </Modal>
    );
  };

  private showCreateChannel = () => {
    return (
      <Modal show={this.state.showModal} onHide={this.modalHide}>
        <Modal.Header style={{ justifyContent: 'center' }} closeButton>
          <Modal.Title className="text-center">Create a new channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="h-100 w-100">
            <FormControl
              as="textarea"
              aria-label="With textarea"
              placeholder="Please enter a channel name"
              onChange={(e: any) => this.setState({ text: e.target.value })}
              value={this.state.text}
              style={{ height: '100%', display: 'flex' }}
            />
            <InputGroup.Append>
              <Button onClick={() => this.createChannel()}>
                <FontAwesomeIcon icon={faPlusCircle} style={{ fontSize: '25' }} />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Modal.Body>
      </Modal>
    );
  };

  private createChannel = () => {
    const existedChannel = _.find(this.channels, (item) => {
      return item.name === this.state.text;
    });
    if (!this.state.text) {
      alert('please type something');
    } else if (existedChannel) {
      alert('Chat room has a same name of channel');
    } else {
      this.props.onCreateChannelBtn(this.chatStore.currRoomID, this.state.text);
      this.modalHide();
    }
  };

  private modalHide = () => {
    this.setState({ showModal: false, text: '' });
  };

  private onCloseEditChannelNameModal = () => {
    this.setState({
      channelIDToEditName: undefined,
      channelNameToEdit: '',
    });
  };

  private onEditChannelNamePressed = () => {
    const newChannelName = this.state.channelNameToEdit;
    if (newChannelName === '' || newChannelName.length > 45) {
      alert('Channel name cannot be empty or more than 45 characters.');
    } else {
      this.authStore.sendMessageToServer({
        command: WEBSOCKET_COMMAND_TYPE.UPDATE_CHANNEL_NAME,
        data: {
          channelID: this.state.channelIDToEditName,
          newChannelName: this.state.channelNameToEdit,
        },
      });
    }
    this.setState({
      channelIDToEditName: undefined,
      channelNameToEdit: '',
    });
  };

  public copyInvitationLink = async (roomID: string) => {
    this.authStore.createInvitationCode(this.authStore.selfUser.uid, roomID);
  };

  expressionTest = (text: any) => {
    const expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    const match = text.match(expression);
    const componentDecorator = (href: any, text: any, key: any) => (
      <a href={href} key={key} target="_blank" rel="noreferrer" style={{ color: 'skyblue' }}>
        {text}
      </a>
    );
    if (match) {
      return <Linkify componentDecorator={componentDecorator}>{text}</Linkify>;
    } else {
      return text;
    }
  };

  private SideNav = (props: any) => {
    const userID = this.rootStore.getUserUID();
    const roomInfo = this.chatStore.getCurrentChatRoom();
    const myLevel = this.chatStore.getUserLevel(roomInfo.roomID, userID);
    let chatImage = this.chatStore.getCurrentChatRoom().thumbnailURL;
    const status = this.chatStore.getMemberStatus(roomInfo.roomID, userID);
    if (this.chatStore.chatRoomThumbnailURL == '') {
      chatImage = this.chatStore.getCurrentChatRoom().thumbnailURL;
    } else if (this.chatStore.getCurrentChatRoom().thumbnailURLL !== this.chatStore.chatRoomThumbnailURL) {
      chatImage = this.chatStore.chatRoomThumbnailURL;
    }

    const chatUserList = toJS(this.chatStore.getChatMemberList(this.chatStore.currRoomID));
    const participantList = _.filter(chatUserList, (chatUser) => chatUser.status === 'member');
    const followerList = _.filter(chatUserList, (chatUser) => chatUser.status === 'follower');

    return (
      <Sidebar {...props} title="Chat Detail">
        <div className="gr-1">
          <div className="Name d-flex flex-column justify-content-center">
            <div className="col-5" style={{ marginRight: '20px' }}>
              {' '}
              <img
                className="image"
                // src={roomInfo.thumbnailURL || GROUPTCHAT_THUMBNAIL_LOGO_URL}
                src={chatImage || GROUPTCHAT_THUMBNAIL_LOGO_URL}
                width="80"
                height="80"
              ></img>
            </div>
            <div className="ml-20">
              <span className="namePosition">{roomInfo.topic}</span>
            </div>
          </div>
          {roomInfo.roomType === 'groupChat' && roomInfo.description && (
            <div>
              <div>
                <p className="chatDescription">Group Description</p>
              </div>
              <div className="groupDescription">{this.expressionTest(roomInfo.description)}</div>
            </div>
          )}
          {roomInfo.roomType === 'groupChat' && (
            <div className="mt-1">
              <Row className="justify-content-around d-flex align-items-center">
                <OverlayTrigger placement="top" overlay={this.createTooltip('addUserTooltip', 'Add Users')}>
                  <span>
                    {' '}
                    <DropdownButton
                      as={InputGroup.Append}
                      variant="transparent"
                      title={
                        <FontAwesomeIcon icon={faUserPlus} size={'lg'} color={'#D1CDC7'} className="addUserClick" />
                      }
                      id="input-group-dropdown-2"
                    >
                      <Dropdown.Item onClick={() => this.sidebarStore.openAddMembers()}>BASE Users</Dropdown.Item>
                      {(myLevel == 'admin' || myLevel == 'leader') && (
                        <Dropdown.Item
                          onClick={() => {
                            this.copyInvitationLink(this.chatStore.currRoomID);
                          }}
                        >
                          External User
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  </span>
                </OverlayTrigger>
                {(myLevel === 'leader' || myLevel === 'admin') && <>{this.showSettingIcon()}</>}
              </Row>
            </div>
          )}
          {this.chatStore.getCurrentChatRoom().roomType === 'groupChat' && (
            <div style={{ paddingBottom: 10 }}>{this.renderChannels(this.chatStore.getCurrentChatRoom())}</div>
          )}
          {/* <div>
            <div
              className="posts"
              onClick={() => this.sidebarStore.openPosts()}
            >
              <span>Posts</span>
              <span className="arrow">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </div>
          </div> */}
        </div>
        <div className="gr-2">
          {/* <div
            className="media"
            onClick={() => {
              this.onClickMedia(this.chatStore.currRoomID);
            }}
          >
            <span>Media</span>
            <span className="arrow">
              {' '}
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </div> */}
          <div className="participant mt-2" onClick={() => this.sidebarStore.openParticipants()}>
            Participants ({_.size(participantList)})
          </div>
        </div>
        <div className="gr-3">
          <div className="followers" onClick={() => this.sidebarStore.openFollowers()}>
            Followers ({_.size(followerList)})
          </div>
        </div>
        <div className="gr-4">
          <div className="activityLog" onClick={() => this.sidebarStore.openActivityLog()}>
            Activity Log
          </div>
        </div>
        <div className="gr-4">
          {this.chatStore.getCurrentChatRoom().roomType === 'groupChat' && (myLevel == 'admin' || myLevel == 'leader') && (
            <div
              className="invitationLink"
              onClick={() => {
                this.copyInvitationLink(this.chatStore.currRoomID);
              }}
            >
              Get Invitation Link
            </div>
          )}
        </div>
        {status === 'member' && (
          <div className="gr-1">
            <div className="mt-1">
              <Row className="justify-content-end d-flex align-items-center">
                <span onClick={leaveChatRoom} className="exitBtn">
                  {' '}
                  <FontAwesomeIcon icon={faDoorOpen} size={'lg'} color={'#D1CDC7'} className="exitChatClick" />
                </span>
              </Row>
            </div>
          </div>
        )}
      </Sidebar>
    );
  };

  participantPage = (props: any) => {
    return (
      <Sidebar {...props} sub>
        <ChatMemberList />
      </Sidebar>
    );
  };

  private postsPage = (props: any) => {
    return (
      <Sidebar {...props} sub>
        <PostDetail />
      </Sidebar>
    );
  };

  private addMembersPage = (props: any) => {
    return (
      <Sidebar {...props} sub>
        <InviteMembersToGroup />
      </Sidebar>
    );
  };

  public onClickMedia = (chatRoomId: string) => {
    this.chatStore.getChatMedia(chatRoomId);
    this.chatStore.getChatDoc(chatRoomId);
    this.chatStore.getChatMessages(chatRoomId);

    this.sidebarStore.openMedia();
  };

  private mediaPage = (props: any) => {
    return (
      <Sidebar {...props} sub>
        <MediaDocs data={toJS(this.chatStore.elementOfMediaArr)} roomID={this.chatStore.currRoomID} />
      </Sidebar>
    );
  };

  private followersPage = (props: any) => {
    return (
      <Sidebar {...props} sub>
        <ChatFollowerList />
      </Sidebar>
    );
  };

  private activityLogPage = (props: any) => {
    return (
      <Sidebar {...props} sub>
        <ActivityChatLog />
      </Sidebar>
    );
  };

  private chatSettingPage = (props: any) => {
    return (
      <Sidebar {...props} sub>
        <ChatSettings
          roomID={this.chatStore.currRoomID}
          roomData={this.chatStore.getCurrentChatRoom()}
          schoolList={this.props.schoolList}
          openChatSettings={this.sidebarStore.openChatSettings}
        />
      </Sidebar>
    );
  };

  private showSettingIcon() {
    const roomInfo = this.chatStore.getCurrentChatRoom();
    const userID = this.rootStore.getUserUID();
    const myLevel = this.chatStore.getUserLevel(roomInfo.roomID, userID);
    if (myLevel === MEMBER_LEVEL.LEADER || MEMBER_LEVEL.ADMIN) {
      return (
        <OverlayTrigger placement="top" overlay={this.createTooltip('chatSettingsTooltip', 'Chat Settings')}>
          <span onClick={() => this.sidebarStore.openChatSettings()}>
            {' '}
            <FontAwesomeIcon icon={faCog} size={'lg'} color={'#D1CDC7'} className="addUserClick" />
          </span>
        </OverlayTrigger>
      );
    } else {
      return null;
    }
  }

  private createTooltip = (id: string, content: string) => <Tooltip id={id}>{content}</Tooltip>;

  render() {
    return (
      <>
        <this.SideNav open={this.sidebarStore.isChatDetailOpen} onCloseClick={this.sidebarStore.closeChatDetail} />
        <this.addMembersPage
          open={this.sidebarStore.isAddMembersOpen}
          onCloseClick={this.sidebarStore.closeSubChatDetail}
        />
        <this.postsPage open={this.sidebarStore.isPostsOpen} onCloseClick={this.sidebarStore.closeSubChatDetail} />
        <this.mediaPage open={this.sidebarStore.isMediaOpen} onCloseClick={this.sidebarStore.closeSubChatDetail} />
        <this.participantPage
          open={this.sidebarStore.isParticipantsOpen}
          onCloseClick={this.sidebarStore.closeSubChatDetail}
        />
        <this.followersPage
          open={this.sidebarStore.isFollowersOpen}
          onCloseClick={this.sidebarStore.closeSubChatDetail}
        />
        <this.activityLogPage
          open={this.sidebarStore.isActivityLogOpen}
          onCloseClick={this.sidebarStore.closeSubChatDetail}
        />
        <this.chatSettingPage
          open={this.sidebarStore.isChatSettingsOpen}
          onCloseClick={this.sidebarStore.closeSubChatDetail}
        />
        {this.state.showModal && this.showCreateChannel()}
        {this.state.channelIDToEditName !== undefined && this.showEditChannelName()}
      </>
    );
  }
}

export default ChatDetailPage;
