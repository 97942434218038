import React, { Component } from 'react';
import './css/NewChatComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Image, InputGroup, FormControl } from 'react-bootstrap';
import _ from 'lodash';
import swal from 'sweetalert';
import { inject, observer } from 'mobx-react';
import SearchedUserList from '../user/SearchedUserList';

interface State {
  search: string;
  candidateList: any[];
}

interface Props {
  rootStore?: any;
}

@inject('rootStore')
@observer
class InviteMembersToGroup extends Component<Props, State> {
  private rootStore: any;
  private chatStore: any;
  private authStore: any;
  private sidebarStore: any;
  private inTimeout: boolean = false;
  private searchedListRef: any;

  constructor(props: any) {
    super(props);
    this.state = {
      search: '',
      candidateList: [],
    };
    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
    this.sidebarStore = this.rootStore.sidebarStore;
    this.searchedListRef = React.createRef();
  }

  private onchange = (e: any) => {
    const name = e.target.name;
    this.setState({
      ...this.state,
      [name]: e.target.value,
    });

    if (!this.inTimeout && name === 'search') {
      this.inTimeout = true;
      setTimeout(() => {
        this.submitSearch();
        this.inTimeout = false;
      }, 800);
    }
  };

  private submitSearch = () => {
    const searchKeyword: String = this.state.search.toLowerCase();
    this.searchedListRef.current.submitSearch(searchKeyword);
  };

  public renderUserModal = (userData: string) => {
    this.chatStore.openUserModal(userData);
  };

  private addMembers = () => {
    swal({
      title: 'Inviting users to the group chat?',
      text: 'Are you sure?',
      buttons: ['Cancel', 'Ok'],
    })
      .then((isOkay) => {
        if (isOkay) {
          this.addMembersToGroup();
        }
        this.setState({
          candidateList: [],
          search: '',
        });
        this.sidebarStore.isAddMembersOpen = false;
      })
      .then(() => {
        this.authStore.clearSelectionOfUserList();
      });
  };

  private addUser = (user: any) => {
    const contains = this.state.candidateList.some((candidate) => {
      return user.uid === candidate.uid;
    });

    let selectedUserList = this.state.candidateList;
    if (contains) {
      selectedUserList = selectedUserList.filter((candidate) => {
        return user.uid !== candidate.uid;
      });
    } else {
      selectedUserList.push(user);
    }

    this.setState({ candidateList: selectedUserList });
  };

  private addMembersToGroup = async () => {
    const userIDList: string[] = this.state.candidateList.map((member: any) => member.uid);
    const roomID = this.chatStore.currRoomID;
    this.chatStore.addMembersToGroup(userIDList, roomID);
    this.authStore.clearSelectionOfUserList();
  };

  private renderSearchBar = () => {
    return (
      <InputGroup className="col-12 my-3">
        <InputGroup.Prepend>
          <InputGroup.Text style={{ background: 'white', color: 'gray', border: 'none' }}>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          placeholder="Search for a User"
          aria-label="User Search Bar"
          onChange={this.onchange}
          value={this.state.search}
          name="search"
          style={{ border: 'none' }}
        />
      </InputGroup>
    );
  };

  private renderGroupMemberIcon = (user: any) => {
    return (
      <span key={user.uid} className="memberListImage">
        <Image className="my-2 mr-2" width="40" height="40" src={user.userThumbnailURL} roundedCircle />
        <span
          className="text-secondary memberListDeleteBtn"
          onClick={() => {
            this.addUser(user);
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
      </span>
    );
  };

  private renderGroupMemberList = () => {
    return this.state.candidateList.length > 0 ? (
      <div className="text-truncate d-flex groupMemberList m-2">
        <div className="col-10" style={{ overflowX: 'scroll' }}>
          {this.state.candidateList.map((user: any) => this.renderGroupMemberIcon(user))}
        </div>
        <div className="col-2 p-0">
          <button className="goBtn" onClick={this.addMembers}>
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    ) : null;
  };

  render() {
    return (
      <>
        <div>
          <h5 style={{ textAlign: 'center' }}>Search Users</h5>
          {this.renderSearchBar()}
          {this.renderGroupMemberList()}
          <SearchedUserList
            onClickUser={this.addUser}
            ref={this.searchedListRef}
            roomID={this.chatStore.currRoomID}
            isHidden={false}
          />
        </div>
      </>
    );
  }
}

export default InviteMembersToGroup;
