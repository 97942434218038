import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import firebase from '../../../firebase/firebase';
import { history } from '../../../BaseRouter';

interface Props {
  isSignUp?: boolean;
  setIsSignUp?: (isSignUp: boolean) => void;
}

const EmailForm: React.FC<Props> = ({ isSignUp, setIsSignUp }: Props) => {
  const [password, setPassword] = useState<string>('');
  const [confirmEmail, setConfirmEmail] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [errorMsg, setErrMsg] = useState<string>('');

  useEffect(() => {
    setEmail('');
    setConfirmEmail('');
    setPassword('');
  }, [isSignUp]);

  const signInWithEmail = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(function () {
        history.push('/');
      })
      .catch(function (error: any) {
        setErrMsg(error.message);
      });
  };

  const signUpWithEmail = () => {
    if (email == confirmEmail) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(function (user: any | null) {
          if (user) {
            user.sendEmailVerification();
          }
        })
        .catch(function (error) {
          setErrMsg(error.message);
        });
    } else {
      setErrMsg('Email address does not match');
    }
  };

  return (
    <div className="text-white h-100 p-0 d-flex align-items-center justify-content-center col-12 container-fluid bgGray-1">
      <div className="col-11 col-md-6 m-auto p-0 shadow-lg auth__card bgGray-3">
        <header className="w-100 p-4 h5 text-center bgGray-3 auth__card-header">
          {isSignUp ? 'Sign up' : 'Log in'} via Email
        </header>
        <div className="w-100 p-4 d-flex flex-column align-items-center justify-content-center">
          {!!errorMsg && <p className="text-danger">*** {errorMsg}</p>}
          <Form className="col-sm-11 col-lg-9 p-0 mb-2">
            <Form.Group controlId="email">
              <Form.Control
                type="email"
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Form.Group>
            {isSignUp && (
              <Form.Group controlId="conmirmEmail">
                <Form.Control
                  type="email"
                  placeholder="Confirm Email address"
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  value={confirmEmail}
                />
              </Form.Group>
            )}
            <Form.Group controlId="password">
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </Form.Group>
          </Form>
          {isSignUp ? (
            <button
              onClick={() => signUpWithEmail()}
              className="btn col-sm-11 col-lg-9 d-block auth__submit-button mb-3"
            >
              Sign up
            </button>
          ) : (
            <>
              <button
                onClick={() => signInWithEmail()}
                className="btn col-sm-11 col-lg-9 d-block auth__submit-button mb-3"
              >
                Log in
              </button>
              <Link to="/password-reset" className="text-white mb-2">
                <u>Forgot password?</u>
              </Link>
            </>
          )}
          {/* <div className="d-flex flex-wrap align-items-center justify-content-center">
            <p className="d-inline-block m-0 mr-md-2">
              {isSignUp ? 'Already have an account?' : 'Don’t have an account?'}
            </p>
            <Button variant="link" className="text-white p-0 border-0" onClick={() => setIsSignUp!(!isSignUp)}>
              <u>{isSignUp ? 'Log in' : 'Sign up'} via Email</u>
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default inject(({ rootStore }) => ({
  isSignUp: rootStore.authStore.isEmailSignUp,
  setIsSignUp: rootStore.authStore.setIsEmailSignUp,
}))(observer(EmailForm));
