import React, { Component } from 'react';
import { Provider } from 'mobx-react';
import { configure, flow } from 'mobx';
import { Idle } from 'idlejs/dist';
import IdleTimer from 'react-idle-timer';
import { Modal, Button } from 'react-bootstrap';
import { faPrescriptionBottleAlt } from '@fortawesome/free-solid-svg-icons';
import { withRouter, RouteProps } from 'react-router-dom';
import ChatStore from './stores/ChatStore';
import { STAGING_BASELOGO_URL } from './utils/Constants';
import AuthStore from './stores/AuthStore';
import RootStore from './stores/RootStore';
import BaseRouter from './BaseRouter';
import './styles/styles.scss';

interface State {
  isShowDisconnection: boolean;
}

interface Props {}

class App extends Component<Props & RouteProps> {
  public state: State;

  private chatStore: any;

  private rootStore: RootStore;

  private authStore: any;

  private idleTimer: any;

  private onAction: any;

  private onActive: any;

  private onIdle: any;

  private entryUrl: any;

  constructor(props: Props) {
    super(props);
    this.rootStore = new RootStore();
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
    this.idleTimer = null;
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.onReconnectClick = this.onReconnectClick.bind(this);
    this.authStore.load();
    this.entryUrl = window.location.href;

    this.state = {
      isShowDisconnection: false,
    };
  }

  componentWillMount() {
    const invitedBy = this.getParameterByName('invitedBy');
    const roomID = this.getParameterByName('roomID');
    Object.assign(this.authStore.signUpWithInvitation, { invitedBy }, { roomID });
  }

  getParameterByName(name: any) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(this.entryUrl);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  _onActive(e: any) {
    if (!this.authStore.webSocket) {
      this.authStore.setShouldRestoreWebSocketConnection(true);
      this.authStore.initWebSocket();
      this.setState({ isShowDisconnection: false });
    }
  }

  _onIdle(e: any) {
    if (this.authStore.webSocket) {
      this.setState({ isShowDisconnection: true });
      this.authStore.setShouldRestoreWebSocketConnection(false);
      this.authStore.closeWebSocket();
    }
  }

  private onReconnectClick() {
    this.authStore.setShouldRestoreWebSocketConnection(true);
    this.authStore.initWebSocket();
    this.setState({ isShowDisconnection: false });
  }

  private getMobileOS(): string {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return 'unknown';
  }

  private isAndroidOrIPhoneUser(): boolean {
    const mobileOS = this.getMobileOS();
    return mobileOS === 'Android' || mobileOS === 'iOS';
  }

  private onLinePage = () => {
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 30}
          // timeout={1000 * 30}
        />
        {this.state.isShowDisconnection && (
          <Modal show={this.state.isShowDisconnection}>
            <Modal.Body>
              <div>Idle for long time.</div>
              <div>
                <button type="button" onClick={this.onReconnectClick}>
                  Click here to reconnect
                </button>
              </div>
            </Modal.Body>
          </Modal>
        )}
        <Provider rootStore={this.rootStore}>
          <BaseRouter />
        </Provider>
      </>
    );
  };

  private offLinePage = () => {
    return (
      <Modal show>
        <Modal.Body>
          <Button variant="danger" href="javascript:location.reload(true)">
            Network disconnection / Click to Refresh
          </Button>
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    // if (this.isAndroidOrIPhoneUser()) {
    //   alert('Base Web App is designed to work best with the chrome browser on a laptop or a desktop');
    // }
    if (navigator.onLine) {
      return this.onLinePage();
    }
    return this.offLinePage();
  }
}

export default App;
