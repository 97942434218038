import React, { Component } from 'react';
import { Image, Spinner } from 'react-bootstrap';
import { PRODUCTION_BASELOGO_URL } from '../../../utils/Constants';
import './LoadingPage.scss';

interface Props {}

class LoadingPage extends Component<Props> {
  render() {
    return (
      <div className="text-white h-100 p-0 d-flex align-items-center justify-content-center col-12 container-fluid bgGray-1">
        <div className="col-11 col-md-6 m-auto p-0 shadow-lg auth__card bgGray-3">
          <div className="col-12 p-4 text-center">
            <div className="text-center">
              <Image className="col-2 p-0 mb-3" src={PRODUCTION_BASELOGO_URL} roundedCircle />
              <h1>BASE</h1>
              <h4>Open Communications Platform for Students</h4>
            </div>
            <Spinner animation="border" style={{ padding: '10px' }} />
          </div>
        </div>
      </div>
    );
  }
}

export default LoadingPage;
