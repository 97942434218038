import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSquare, faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import { PRODUCTION_BASELOGO_URL } from '../../../utils/Constants';

interface Props {
  setIsEmailSignUp: (isSignUp: boolean) => void;
}

const AuthHomePage: React.FC<Props> = ({ setIsEmailSignUp }: Props) => {
  const [emailAuth, setEmailAuth] = useState<boolean>(false);

  return (
    <div className="text-white h-100 p-0 d-flex align-items-center justify-content-center col-12 container-fluid bgGray-1">
      <div className="col-11 col-md-6 m-auto p-0 shadow-lg auth__card bgGray-3">
        {emailAuth ? (
          <>
            <header className="w-100 p-4 h5 text-center bgGray-3 auth__card-header">Log in via Email</header>
            <div className="w-100 p-4 d-flex flex-column align-items-center justify-content-center">
              <Link
                to="/auth-email"
                className="btn col-12 col-md-8 mx-0 mb-3 border-secondary d-block auth__button"
                onClick={() => setIsEmailSignUp(false)}
              >
                Log in
              </Link>
              <Link
                to="/auth-email"
                className="btn col-12 col-md-8 m-0 border-secondary d-block auth__button"
                onClick={() => setIsEmailSignUp(true)}
              >
                Sign up
              </Link>
            </div>
          </>
        ) : (
          <>
            <header className="w-100 text-center p-4 bgGray-3 auth__card-header">
              <Image className="col-3 col-md-2 p-0 mb-3" src={PRODUCTION_BASELOGO_URL} roundedCircle />
              <h1>BASE</h1>
              <h6 className="mb-0">Open Communications Platform</h6>
            </header>
            <div className="w-100 p-4 d-flex flex-column align-items-center justify-content-center">
              <Link to="/auth-phone" className="btn col-12 col-md-10 mx-0 mb-3 border-secondary d-block auth__button">
                <div>
                  <div>
                    <FontAwesomeIcon icon={faPhoneSquare} size="2x" />
                  </div>
                  <div>Log in via Phone</div>
                </div>
              </Link>
              {/* <button
                className="btn col-sm-12 col-md-10 m-0 border-secondary d-block auth__button"
                onClick={() => setEmailAuth(true)}
              >
                <div>
                  <div>
                    <FontAwesomeIcon icon={faEnvelopeSquare} size="2x" />
                  </div>
                  <div>Log in via Email</div>
                </div>
              </button> */}
              <Link
                to="/auth-email"
                className="btn col-sm-12 col-md-10 m-0 border-secondary d-block auth__button"
                onClick={() => {
                  setEmailAuth(true);
                  setIsEmailSignUp(false);
                }}
              >
                <div>
                  <div>
                    <FontAwesomeIcon icon={faEnvelopeSquare} size="2x" />
                  </div>
                  <div>Log in via Email</div>
                </div>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default inject(({ rootStore }) => ({ setIsEmailSignUp: rootStore.authStore.setIsEmailSignUp }))(
  observer(AuthHomePage),
);
