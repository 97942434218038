// ========================= !!!!! Do not change below !!!!!!=============================
// ========================= Must ask Sean if you need to change =========================

export const RELEASE_MODE = Object.freeze({ STAGING: 'staging', PRODUCTION: 'production' });
export const CURRENT_RELEASE_MODE = RELEASE_MODE.PRODUCTION;

// STAGING LOCAL SETTING
export const STAGING_URL = 'sta.base.town';
export const STAGING_WEBSOCKET_URL = `wss://${STAGING_URL}/ws`;
export const STAGING_API_URL = `https://${STAGING_URL}/api/v1/web`;
export const PRODUCTION_URL = 'pro.base.town';
export const PRODUCTION_WEBSOCKET_URL = `wss://${PRODUCTION_URL}/ws`;
export const PRODUCTION_API_URL = `https://${PRODUCTION_URL}/api/v1/web`;

export const BASENAME_URL =
  'https://firebasestorage.googleapis.com/v0/b/base-6d44c.appspot.com/o/images%2Fbase_name.png?alt=media&token=dbbc391c-ecea-4ba1-aeeb-12b26a2f17db';
export const STAGING_BASELOGO_URL =
  'https://firebasestorage.googleapis.com/v0/b/base-6d44c.appspot.com/o/images%2Fstaging_icon.png?alt=media&token=212de241-54a6-478a-a960-14257c5d71ef';
export const STAGING_TRANSPARENT_LOGO_URL =
  'https://firebasestorage.googleapis.com/v0/b/base-6d44c.appspot.com/o/images%2Fbase_logo_transparent.png?alt=media&token=3b038262-8ca2-4059-8470-eb596080be07';
export const PRODUCTION_BASELOGO_URL =
  'https://firebasestorage.googleapis.com/v0/b/netwoko-staging.appspot.com/o/images%2Fbase_logo.png?alt=media&token=482c0884-50a9-48c6-8378-78ab554e6862';
export const PRODUCTION_TRANSPARENT_LOGO_URL =
  'https://firebasestorage.googleapis.com/v0/b/netwoko-staging.appspot.com/o/images%2Fbase_logo_transparent.png?alt=media&token=f02581e1-0e97-4f26-96b6-cb6d7ad55ebe';
export const GROUPTCHAT_THUMBNAIL_LOGO_URL =
  'https://firebasestorage.googleapis.com/v0/b/base-6d44c.appspot.com/o/images%2Fgroup_icon.png?alt=media&token=45f8ecc5-039a-4e41-9023-403a1c7b3428';

export const OTHER_SCHOOL_ID = 'school::uShZBfm9pAEsQIjGPJR0';

export const MEMBER_STATUS = Object.freeze({
  MEMBER: 'member',
  FOLLOWER: 'follower',
  LEAVER: 'leaver',
});
export enum ChatListType {
  Explore = 'explore',
  Participating = 'participating',
  Following = 'following',
  Other = 'other',
  Search = 'Search',
}

export const ERROR_LOG = Object.freeze({
  CREATE_CHAT: 'create_chat',
  SEND_MESSAGE: 'send_message',
  SIGNUP_UPLOAD_PIC: 'signup_upload_pic',
  SIGNOUT_IN_PRODUCTION: 'signout_in_production',
});

export const MEMBER_LEVEL = Object.freeze({
  LEADER: 'leader',
  ADMIN: 'admin',
  NORMAL: 'normal',
});

export const ROOMTYPE = Object.freeze({
  PERSONAL: 'personal',
  GROUP: 'groupChat',
});

export const WEBSOCKET_COMMAND_TYPE = Object.freeze({
  VERIFY_USER: 'get.user.auth',
  SEND_MESSAGE: 'post.chat.message',
  LOAD_CHATLIST: 'get.user.chats',
  LOAD_SCHOOLLIST: 'get.schools',
  LOAD_MAJORLIST: 'get.majors',
  LOAD_RANKING: 'get.ranking',
  LOAD_POSTCATLIST: 'get.postcats',
  LOAD_EXPLORE_CHATLIST: 'get.chat.explore.v3',
  LOAD_USERLIST: 'get.users',
  INVITE_TOGROUP: 'post.chat.invite',
  GET_USERINFO: 'get.user.info',
  UPDATE_USERINFO: 'put.user.info',
  LEAVE_CHATROOM: 'post.chat.leave',
  CREATE_USER: 'post.user.info',
  FOLLOW_CHAT: 'put.chat.follow',
  UNFOLLOW_CHAT: 'put.chat.unfollow',
  UPDATE_CHATINFO: 'put.chat.info',
  GET_CHATINFO: 'get.chat.info',
  GET_STARREDMSG: 'get.chat.message.starred',
  STAR_MESSAGE: 'put.chat.message.star',
  PROMOTE_MEMBER: 'put.chat.promote',
  DEMOTE_MEMBER: 'put.chat.demote',
  LOAD_CHATMESSAGES: 'get.chat.messages',
  GENERATE_MESSAGEID: 'get.chat.message.id',
  REPORT_MESSAGE: 'put.chat.message.report',
  GET_POSTMAP: 'get.chat.posts',
  GET_MEDIA: 'get.chat.media',
  GET_DOCMAP: 'get.chat.docs',
  CREATE_ADMINCHATROOM: 'post.chat.admin',
  SEND_ERRORLOG: 'post.log.error',
  GET_LATESTCHATCONTENTS: 'get.chat.contents',
  GET_ACTIVITYLOG: 'get.chat.activitylog',
  SET_SCHOOL_MANUAL: 'put.school.manual',
  UPDATE_READMSG: 'put.chat.message.read',
  LOAD_NUM_CURRENT_USER: 'get.num.current.user',
  CREATE_CHANNEL: 'post.chat.channel',
  V2_GET_USERINFO_WITH_CHAT_AND_STAT: 'get.user.info.all.v2',
  V3_LOAD_CHATLIST: 'get.user.chats.v3',
  V2_LOAD_STUDENT_LIST_SEARCH: 'get.user.student.by.search.v2',
  V2_LOAD_USER_LIST_W_FILTER: 'get.users.by.search.filter.v2',
  SEND_TEXT_DM: 'post.user.dm.text',
  GET_MOBILE_MESSGE_GROUPCHAT: 'post.chat.text',
  GET_MESSAGE_BY_ID: 'get.message.byid',
  V2_PREPARE_PERSONAL_CHATROOM: 'post.chat.prepare',
  GET_CHAT_STATS: 'get.chat.stats',
  LOAD_PARTICIPATING_CHATLIST: 'get.user.chats.participanting',
  LOAD_FOLLOWING_CHATLIST: 'get.user.chats.following',
  V2_GET_USERINFO: 'get.user.info.v2',
  GET_CHAT_PARTICIPANTLIST_MENTION: 'get.chat.participants.mention',
  GET_CHAT_PARTICIPANTLIST: 'get.chat.participants',
  GET_CHAT_FOLLOWERLIST: 'get.chat.followers',
  GET_USER_BASICINFO: 'get.user.info.basic',
  GET_MESSAGE_READER_LIST: 'get.message.readers',
  V3_CREATE_GROUP_CHAT: 'post.chat.group.v3',
  GET_SCHOOL_STATS: 'get.school.stats',
  V2_UPDATE_READMSG: 'put.chat.message.read.v2',
  GET_MAJORS_BY_KEYWORD: 'get.majors.keyword',
  GET_SCHOOLS_BY_KEYWORD: 'get.schools.keyword',
  GET_ROOM_IDS_BY_TOPIC: 'get.room.ids.by.topic',
  SEARCH_CHAT_LIST: 'get.search.chat.list',
  GET_TOP_USERS_INFO: 'get.top.users.info',
  GET_ACTIVE_GROUP_USER_RANKING: 'get.active.group.user.ranking',
  UPDATE_CHANNEL_NAME: 'put.channel.name',
  GET_TOTAL_USERS: 'get.total.users',
  GET_INVITATION_CODES: 'get.invitation.codes.user',
  CREATE_INVITATION_CODE: 'post.invitation.code',
});
