import * as React from 'react';

interface Props {
  children: React.Component;
  entityKey?: string;
  offsetKey?: string;
  contentState?: any;
}

export const MentionTag = (props: Props) => {
  return (
    <span className="mention" data-offset-key={props.offsetKey}>
      {props.children}
    </span>
  );
};

export const HashtagSpan = (props: Props) => {
  return (
    <span className="hashtag" data-offset-key={props.offsetKey}>
      <b>{props.children}</b>
    </span>
  );
};

export const LinkTag = (props: Props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return <a href={url}>{props.children}</a>;
};
