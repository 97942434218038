import React from 'react';
import { Col, Row, Image, FormControl, Dropdown } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';

import DropdownButton from 'react-bootstrap/DropdownButton';
import _ from 'lodash';
import { toJS } from 'mobx';
import { MEMBER_STATUS } from '../../utils/Constants';
import UserModal from '../user/UserModal';

interface Props {
  rootStore?: any;
}

interface State {
  image: string;
  searchText: any;
  memberSelected: any;
  changeLevel: any;
  modalData: any;
  userModal: boolean;
}
interface InjectedProps extends Props {
  rootStore: any;
}

@inject('rootStore')
@observer
export default class ChatMemberList extends React.Component<Props, State> {
  private rootStore: any;

  private chatStore: any;

  private authStore: any;

  sidebarStore: any;

  public state: State;

  constructor(props: any) {
    super(props);
    this.state = {
      image: 'images/base_logo.png',
      searchText: '',
      memberSelected: null,
      changeLevel: false,
      modalData: {},
      userModal: false,
    };
    this.rootStore = this.injected.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.authStore = this.rootStore.authStore;
    this.sidebarStore = this.rootStore.sidebarStore;
  }

  private get injected() {
    return this.props as InjectedProps;
  }

  private showAdminPanel = (uid: any) => {
    const userID = this.authStore.selfUser.uid;
    const roomID = this.chatStore.currRoomID;
    const memberStatus = this.chatStore.getMemberStatus(roomID, userID);
    const level = this.chatStore.getUserLevel(roomID, userID);
    const status = ['admin', 'leader'];
    if (memberStatus !== MEMBER_STATUS.MEMBER) {
      return null;
    }
    return (
      <div>
        {status.includes(level) ? (
          <DropdownButton
            id="dropdown-settings-link"
            variant="light"
            size="sm"
            className="mx-0 dropDown px-0"
            title=""
            onClick={() => this.setState({ memberSelected: uid })}
          >
            {this.getAccessOption().map((item, index) => (
              <>
                <Dropdown.Item
                  className=""
                  style={{
                    fontSize: 16,
                    color: '#949494',
                  }}
                  onClick={() => {
                    if (item !== '' && confirm('Are you sure?')) {
                      this.onMemeberLevelPress(index);
                      this.sidebarStore.openParticipants();
                    }
                  }}
                >
                  {item}
                </Dropdown.Item>
                <Dropdown.Divider style={{ borderColor: '#949494' }} />
              </>
            ))}
          </DropdownButton>
        ) : null}
      </div>
    );
  };

  private getAccessOption = () => {
    const userID = this.state.memberSelected;
    const roomID = this.chatStore.currRoomID;
    const level = this.chatStore.getUserLevel(roomID, userID);
    const myLevel = this.chatStore.getUserLevel(roomID, this.authStore.selfUser.uid);
    const isAdminRoom = roomID.startsWith('admin::');
    if (isAdminRoom) {
      return [];
    }
    if (userID === this.authStore.selfUser.uid) {
      return [];
    }
    switch (myLevel) {
      case 'leader':
        if (level === 'admin') {
          return ['Make Leader', 'Make Normal', 'Remove'];
        }
        if (level === 'normal') {
          return ['Make Admin', 'Remove'];
        }
        return [];

      case 'admin':
        if (level === 'admin') {
          return ['Make Normal'];
        }
        if (level === 'normal') {
          return ['Make Admin', 'Remove'];
        }
        return [];

      case 'normal':
        return [];
      default:
        return [];
    }
  };

  private onMemeberLevelPress = (buttonIndex: any) => {
    const { memberSelected } = this.state;
    const userID = this.authStore.selfUser.uid;
    const roomID = this.chatStore.currRoomID;
    const selectedMemberLevel = this.chatStore.getUserLevel(roomID, memberSelected);
    const myLevel = this.chatStore.getUserLevel(roomID, userID);
    const isAdminRoom = roomID.startsWith('admin::');
    if (isAdminRoom) {
      return;
    }
    if (memberSelected === this.authStore.selfUser.uid) {
      return;
    }
    switch (myLevel) {
      case 'leader':
        if (selectedMemberLevel === 'leader') {
        } else if (selectedMemberLevel === 'admin') {
          if (buttonIndex === 0) {
            this.chatStore.promoteMember(roomID, memberSelected, 'leader');
          } else if (buttonIndex === 1) {
            this.chatStore.downgradeMember(roomID, memberSelected);
          } else if (buttonIndex === 2) {
            this.chatStore.exitChatRoom(roomID, memberSelected, 'byLeader');
          } else if (buttonIndex === 3) {
          }
        } else if (selectedMemberLevel === 'normal') {
          if (buttonIndex === 0) {
            this.chatStore.promoteMember(roomID, memberSelected, 'admin');
          } else if (buttonIndex === 1) {
            this.chatStore.exitChatRoom(roomID, memberSelected, 'byLeader');
          } else if (buttonIndex === 2) {
          }
        }
        break;
      case 'admin':
        if (selectedMemberLevel === 'leader') {
        } else if (selectedMemberLevel === 'admin') {
          if (buttonIndex === 0) {
            this.chatStore.downgradeMember(roomID, memberSelected);
          } else if (buttonIndex === 1) {
          }
        } else if (selectedMemberLevel === 'normal') {
          if (buttonIndex === 0) {
            this.chatStore.promoteMember(roomID, memberSelected, 'admin');
          } else if (buttonIndex === 1) {
            this.chatStore.exitChatRoom(roomID, memberSelected, 'byAdmin');
          } else if (buttonIndex === 2) {
          }
        }
        break;
      case 'normal':
        break;
      default:
        break;
    }
  };

  public renderUserModal = (userData: string) => {
    this.chatStore.isModalTrue = true;
    this.chatStore.getUserModalData(userData);
  };

  private renderChatMemberList = () => {
    const { participantList } = this.sidebarStore;
    if (participantList.length > 0) {
      return this.state.searchText.length > 0
        ? participantList
            .filter((item: any) =>
              (item.firstName + item.lastName).toUpperCase().includes(this.state.searchText.toUpperCase()),
            )
            .map((participant: any) => {
              const { uid, userThumbnailURL, firstName, lastName, schoolName } = participant;
              return (
                <div style={{ borderBottom: '1px solid #5D7F9D50' }} key={uid}>
                  <Row className="py-3 col-12 d-flex align-items-center justify-content-between">
                    <Col xs="2" className="pr-0 ">
                      <Image
                        src={userThumbnailURL}
                        className="mr-1 bs-1"
                        width="60"
                        height="60"
                        roundedCircle
                        onClick={() => this.renderUserModal(uid)}
                      />
                    </Col>
                    <Col xs="9" className="d-flex justify-content-center flex-column">
                      <p style={{ fontWeight: 'bold', marginBottom: '.25rem' }}>
                        {firstName} {lastName}
                      </p>
                      <p style={{ marginBottom: '.25rem' }}>{schoolName} </p>
                    </Col>
                    <div style={{ width: '100%', textAlign: 'right' }}>{this.showAdminPanel(uid)}</div>
                  </Row>
                </div>
              );
            })
        : participantList.map((participant: any) => {
            const { uid, userThumbnailURL, firstName, lastName, schoolName } = participant;
            const roomID = this.chatStore.currRoomID;
            const level = this.chatStore.getUserLevel(roomID, uid);
            return (
              <div style={{ borderBottom: '1px solid #303A40' }} key={uid}>
                <Row className="py-3 col-12 d-flex align-items-center justify-content-between">
                  <Col xs="2" className="pr-0 ">
                    <Image
                      src={userThumbnailURL}
                      className="mr-1 bs-1"
                      width="60"
                      height="60"
                      roundedCircle
                      onClick={() => {
                        this.renderUserModal(uid);
                      }}
                    />
                  </Col>
                  <Col xs="9" className="d-flex justify-content-center flex-column">
                    <p style={{ fontWeight: 'bold', marginBottom: '.25rem' }}>
                      {firstName} {lastName}
                      {level === 'leader' && '(Leader)'}
                      {level === 'admin' && '(Admin)'}
                    </p>
                    <p style={{ marginBottom: '.25rem' }}>{schoolName}</p>
                  </Col>
                  <div style={{ width: '100%', textAlign: 'right' }}>{this.showAdminPanel(uid)}</div>
                </Row>
              </div>
            );
          });
    }
  };

  render() {
    return (
      <div className="col-12">
        <Col style={{ color: '#D1CDC7' }}>
          <div className="pt-2 pb-3" style={{ position: 'sticky', top: '54px', zIndex: 2, backgroundColor: '#282b2c' }}>
            <FormControl
              style={{ backgroundColor: '#181A1B', borderColor: '#3C4144', color: '#b5afa6' }}
              onChange={(e: any) => this.setState({ searchText: e.target.value })}
              placeholder="Search..."
              aria-label="ChatMemberList Search Bar"
            />
          </div>
          {this.renderChatMemberList()}
        </Col>
        <UserModal />
      </div>
    );
  }
}

const userList = [
  {
    userID: 'ssf3dfs4234',
    userName: 'Amanda Manda',
    school: 'Capilano University',
    major: 'Computer Science',
    image: 'images/base_logo.png',
  },
  {
    userID: 'ssfwwe34234',
    userName: 'Matt Smart',
    school: 'University of Toronto',
    major: 'Computer Science',
    image: 'images/base_logo.png',
  },
  {
    userID: '34t5ssf34234',
    userName: 'David Albert',
    school: 'Langara College',
    major: 'International Studies',
    image: 'images/base_logo.png',
  },
  {
    userID: '4534ssf34234',
    userName: 'Monica Geller',
    school: 'University of British Columbia',
    major: 'Psychology',
    image: 'images/base_logo.png',
  },
  {
    userID: '45345ssf34234',
    userName: 'Chandler Bing',
    school: 'University of Victoria',
    major: 'Information Technology',
    image: 'images/base_logo.png',
  },
  {
    userID: '3453ssf34234',
    userName: 'Ross Geller',
    school: 'BCIT',
    major: 'Computer Science',
    image: 'images/base_logo.png',
  },
];

const userChatList = [
  {
    roomID: 'gsfdgfgfdgdfgdfgdfgf',
    lastMessage: 'aha hhaha hahha',
    members: 'Marketing 123',
    image: 'images/base_logo.png',
  },
  {
    roomID: 'gsfd909034sgdfgf',
    lastMessage: 'lo loof gdf gdf yhi siog hgih sidu hdsjfsj',
    members: 'Group for User D, User E',
    image: 'images/base_logo.png',
  },
  {
    roomID: 'gsfdgf8099fdsnkfgf',
    lastMessage: 'ahah stuis isuer siufs aodifhsds a',
    members: 'Club of User D, User E',
    image: 'images/base_logo.png',
  },
  {
    roomID: 'gsf0898fgdffgf',
    lastMessage: 'ahha',
    members: 'User C, User D, User E',
    image: 'images/base_logo.png',
  },
  {
    roomID: 'gsfkghg2gf',
    lastMessage: 'aha hha3 4ha hahh a',
    members: 'User D, User E',
    image: 'images/base_logo.png',
  },
  {
    roomID: 'gscngnrdfgdfgf',
    lastMessage: 'ahah vbch ahahahh a',
    members: 'VDV',
    image: 'images/base_logo.png',
  },
  {
    roomID: 'gsfdgfgfcnhny',
    lastMessage: 'a hah haha hah ha',
    members: 'VSE',
    image: 'images/base_logo.png',
  },
  {
    roomID: 'gswergf',
    lastMessage: 'aha h hahaha hh a',
    members: 'User E',
    image: 'images/base_logo.png',
  },
  {
    roomID: 'gsfdgfgfrttfgf',
    lastMessage: 'a hoi poia sp  odf opisp  sfsah haha h ah ha',
    members: 'User A, User B, User C',
    image: 'images/base_logo.png',
  },
  {
    roomID: 'gsfdgfgdfgffgfdgdfgdfgdfgf',
    lastMessage: 'a 90 80 rgd fva',
    members: 'User B',
    image: 'images/base_logo.png',
  },
  {
    roomID: 'gsfdgfgfdfdgdff',
    lastMessage: 'a hah9 09  023  4sfs fh a',
    members: 'User A',
    image: 'images/base_logo.png',
  },
  {
    roomID: 'gsfdg',
    lastMessage: 'ahf dd ahha haha 00 099k lv ldv fdhha',
    members: 'User A, User B',
    image: 'images/base_logo.png',
  },
];
