import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import firebase from '../../../firebase/firebase';

const PasswordReset: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [errorMsg, setErrMsg] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setSuccess(true);
      })
      .catch((error: any) => {
        setErrMsg(error.message);
      });
  };

  return (
    <div className="text-white h-100 p-0 d-flex align-items-center justify-content-center col-12 container-fluid bgGray-1">
      <div className="col-11 col-md-6 m-auto p-0 shadow-lg auth__card bgGray-3">
        <header className="w-100 p-4 h5 text-center bgGray-3 auth__card-header">Password Reset</header>
        <div className="w-100 p-4 d-flex flex-column align-items-center justify-content-center">
          {!!errorMsg && <p className="text-danger">*** {errorMsg}</p>}
          {!!success && <p className="text-success">A link to reset your password has been sent to {email}</p>}
          <Form className="col-sm-11 col-lg-9 p-0 mb-4">
            <Form.Group controlId="email" className="mb-0">
              <Form.Control
                type="email"
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Form.Group>
          </Form>
          <Button
            onClick={handleSubmit}
            className="col-sm-11 col-lg-9 d-block auth__submit-button"
            style={{ backgroundColor: '#5D7E9D' }}
          >
            Send reset link
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
