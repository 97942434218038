import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChartBar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

interface State {}

interface Props {
  rootStore?: any;
  openChatList: any;
  closeChatPage: any;
}

@inject('rootStore')
@observer
class ChatLandingPage extends Component<Props, State> {
  state: State;
  rootStore: any;
  chatStore: any;
  sidebarStore: any;

  constructor(props: any) {
    super(props);
    this.state = {};
    this.rootStore = this.props.rootStore;
    this.chatStore = this.rootStore.chatStore;
    this.sidebarStore = this.rootStore.sidebarStore;
  }

  componentDidMount() {
    this.props.closeChatPage();
  }

  componentDidUpdate() {
    this.props.closeChatPage();
  }

  componentWillUnmount() {
    this.chatStore.showChatLandingPage = false;
  }

  private truncateTopic = (topic: any) => {
    if (topic.length > 6) {
      topic = topic.substring(0, 6);
      topic += '...';
    }
    return <span className="mr-1">{topic}</span>;
  };

  private renderDetail = () => {
    const roomInfo = this.chatStore.getCurrentChatRoom();
    const topic = roomInfo.topic || 'No topic';

    return (
      <Row className="h-100 mx-auto col-12">
        <Col className="d-flex align-items-center col-9 chatroom-title">
          <Link to="/home">
            <Button variant="link" className="backbtn-space" onClick={this.props.openChatList}>
              <FontAwesomeIcon className="backbtn-icon" icon={faArrowLeft} />
            </Button>
          </Link>
          <Alert className="bgGray-4 colorWhite" style={{ borderRadius: '10px', padding: '8px 8px', marginBottom: 0 }}>
            <span className="mr-1">
              {window.matchMedia('(min-width: 768px)').matches ? topic : this.truncateTopic(topic)}
            </span>
          </Alert>
        </Col>
        <Col className="d-flex justify-content-end align-items-center col-3 icon-style">
          <Button
            onClick={this.sidebarStore.openChatStats}
            variant="link"
            style={{ textDecoration: 'none' }}
            className="btn-space-left"
          >
            <FontAwesomeIcon className="backbtn-icon" icon={faChartBar} />
          </Button>
          <Button onClick={this.sidebarStore.openChatDetail} variant="link" style={{ textDecoration: 'none' }}>
            <FontAwesomeIcon className="backbtn-icon" icon={faInfoCircle} />
          </Button>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div id="mainDiv" className="bg-dark h-100 w-100" style={{ overflow: 'hidden' }}>
        <div className="detailDiv" style={{ height: 54, position: 'static', top: 0 }}>
          {this.renderDetail()}
        </div>
        <div className="bgBlack-1 w-100 h-100" style={{ display: 'block' }}>
          <div
            className="text-white-50 h-100"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <h1>Choose a channel</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatLandingPage;
